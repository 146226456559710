import { equalP } from 'shared/lib/array/array';
import { ref, watch } from 'vue';

export default function useLoader(
  incRootItems,
  initRenderedItems,
  incRenderedItems,
  goalFetcher,
  viewParamsService,
  debounce,
  selectedCycles,
  searchTerm,
  disabledGoalIds,
  baseFilter,
) {
  const renderedItems = ref(initRenderedItems);
  const totalRootItems = ref(0);
  const loadedRooItems = ref(incRootItems);

  // fetchLoading represents any call to getGoals
  const fetchLoading = ref(false);
  // viewChangedLoading represents a call to getGoals that is triggered by a change of filters, sorters, search (view related elements)
  const viewChangedLoading = ref(false);
  // listLoading represents the call to getGoals that are expected to change the collection
  const listLoading = ref(false);
  const retrieveGoals = ({ toggleViewChangedLoading = true, toggleListLoading = true } = { toggleViewChangedLoading: true, toggleListLoading: true }) => {
    if (toggleViewChangedLoading) {
      viewChangedLoading.value = true;
    }
    if (toggleListLoading) {
      listLoading.value = true;
    }
    fetchLoading.value = true;
    return goalFetcher.getGoals(
      {
        searchTerm: searchTerm.value,
        selectedCycles: selectedCycles.value,
        disabledGoalIds: disabledGoalIds.value,
      },
      baseFilter.value,
      {
        page: 1,
        itemsPerPage: loadedRooItems.value,
      },
    ).then((response) => {
      totalRootItems.value = response.count[0].count;
    }).finally(() => {
      fetchLoading.value = false;
      listLoading.value = false;
      viewChangedLoading.value = false;
    });
  };

  const viewChanged = (wait) => {
    debounce(retrieveGoals, wait, 'retrieveGoals');
  };
  const collectionRefresh = (wait) => {
    debounce(() => retrieveGoals({ toggleViewChangedLoading: false, toggleListLoading: false }), wait, 'retrieveGoals');
  };

  const loadMore = () => {
    renderedItems.value += incRenderedItems;
    if (totalRootItems.value <= loadedRooItems.value) {
      return;
    }

    loadedRooItems.value += incRootItems;
    retrieveGoals({ toggleViewChangedLoading: false });
  };

  watch(viewParamsService.applyFilterOnFirstLevelOnly, (val, oldVal) => {
    if (val === oldVal) {
      return;
    }
    viewChanged(1000);
  });
  watch(viewParamsService.compareTo, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    collectionRefresh(1000);
  });
  watch(viewParamsService.gqlFilterObject, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    viewChanged(1000);
  });
  watch(selectedCycles, (val, oldVal) => {
    if (equalP({ a: val, b: oldVal })) {
      return;
    }
    viewChanged(1000);
  });
  watch(searchTerm, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    viewChanged(1000);
  });
  watch(viewParamsService.order, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    viewChanged(1000);
  });

  return {
    fetchLoading,
    listLoading,
    viewChangedLoading,
    totalRootItems,
    retrieveGoals,
    loadMore,
    renderedItems,
  };
}

import useBulkMutate from '@/nebula/bulk-mutate';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { DateTime } from 'luxon';
import { printableChildren } from '@/api/query/nebula/printable';
import { printable as printableConfig, report as reportConfig } from 'shared/api/query/configs.json';
import { ref } from 'vue';
import { reportChildren } from '@/api/query/nebula/report';

export default function useReport() {
  const repo = useRepo(reportConfig.model);
  const bulkMutator = useBulkMutate();

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const createLoading = ref(false);
  const createSingle = (data) => {
    createLoading.value = true;
    const payload = [{
      alias: 'createPrintable',
      nodes: [
        { ...data.printable, rid: 1 },
      ],
      model: printableConfig.model,
      attributes: printableChildren,
    },
    {
      alias: 'createReport',
      nodes: [{
        creator: { uid: loggedInUser.value.uid },
        account: { uid: loggedInUserAccount.value.uid },
        ...data,
        printable: { rid: 1 },
      }],
      model: reportConfig.model,
      attributes: reportChildren,
    },
    ];

    return bulkMutator.bulkMutate(payload).finally(() => {
      createLoading.value = false;
    });
  };

  const activate = (entity) => repo.updateSingle({ uid: entity.uid, activatedAt: DateTime.local().toISO() });
  const pause = (entity) => repo.updateSingle({ uid: entity.uid, activatedAt: null });
  const toggleSubscribe = (entity) => {
    if (entity.recipients.includes(loggedInUser.value.email)) {
      return repo.updateSingle({ uid: entity.uid, recipients: entity.recipients.filter((recipient) => recipient !== loggedInUser.value.email) });
    }
    return repo.updateSingle({ uid: entity.uid, recipients: [...entity.recipients, loggedInUser.value.email] });
  };

  return {
    ...repo,
    createSingle,
    createLoading,
    activate,
    pause,
    toggleSubscribe,
  };
}

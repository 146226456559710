<template>
  <settings-switch-item
    :title="notification.title"
    :sub-title="notification.subTitle"
    :value="!value"
    :loading="updateLoading"
    @click="toggle"
  />
</template>

<script>
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useNotificationSettings from '@/composables/logged-in-user/notification-settings';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'NotificationTypeItem',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    notification: {
      type: Object,
      required: true,
    },
    notificationSetting: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { updateSingle, updateLoading } = useNotificationSettings();
    return { updateSingle, updateLoading };
  },
  components: { SettingsSwitchItem },
  methods: {
    toggle() {
      this.updateSingle({
        ...this.notificationSetting,
        [this.notification.key]: !this.value,
      })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>

import { RRule } from 'rrule';
import { englishStrings } from '@/composables/rrule-translations/english';
import { germanStrings } from '@/composables/rrule-translations/german';
import { useI18n } from 'vue-i18n';

export default function useRRuleTranslations() {
  const { t, locale } = useI18n();

  const rruleToText = (rule, { includeTime = false } = { includeTime: false }) => {
    const str = getString(rule, locale.value);
    if (includeTime) {
      const formatter = new Intl.DateTimeFormat(locale.value, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: locale.value === 'en',
      });
      return `${str} ${t('general.at')} ${getTime(rule, formatter)}`;
    }

    return str;
  };

  const getTime = (rule, formatter) => {
    const time = new Date(0, 0, 0, rule.options.byhour, rule.options.byminute);
    return formatter.format(time);
  };

  const getString = (rule) => {
    const cleanedRule = new RRule({
      freq: rule.origOptions.freq,
      bysetpos: rule.origOptions.bysetpos,
      byweekday: rule.origOptions.byweekday,
      count: rule.origOptions.count,
      interval: rule.origOptions.interval,
      until: rule.origOptions.until,
    });

    if (locale.value === 'de') {
      const german = germanStrings(
        rule.options.freq,
        rule.options.byweekday,
        rule.options.interval,
        rule.options.bysetpos,
      );
      return cleanedRule.toText((id) => german[id] || id, german);
    }

    const english = englishStrings(
      rule.options.freq,
      rule.options.byweekday,
      rule.options.interval,
      rule.options.bysetpos,
    );
    return cleanedRule.toText((id) => english[id] || id, english);
  };

  return { rruleToText };
}

<template>
  <page-layout
    :class="['scheduled-reports']"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown"
            :title-suggestion="$t('dashboards.reports')"
          />
          <m-dropdown
            v-else
            v-model:value="showFurtherActions"
            :title="$t('general.actions')"
          >
            <m-btn
              icon="ellipsis"
              hide-border
              fab
              small
            />
            <template #overlay>
              <m-card
                no-padding
                list
              >
                <favorite-button
                  :title-suggestion="$t('dashboards.reports')"
                />
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :title="$t('dashboards.reports')"
          disabled
          size="small"
        />
        <list-header class="_list-header">
          <template #right>
            <list-string-filter
              :placeholder="$t('general.typeToSearch')"
              class="_search"
              small
              @input="search = $event"
            />
            <m-btn
              color="primary"
              small
              @click="$refs.reportsTable.createReport()"
            >
              {{ $t('general.new') }}
            </m-btn>
          </template>
        </list-header>
      </m-content>
      <m-content
        padding-x="layout"
        class="_content"
      >
        <reports-table
          ref="reportsTable"
          :search="search"
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script setup>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ReportsTable from '@/components/report/ReportsTable.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import { buildIcon } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { routeName } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const breadcrumbs = computed(() => [
  {
    title: t('navigation.dashboards'),
    icons: [{ value: buildIcon('AppstoreOutlined') }],
    disabled: false,
    to: { name: routeName.dashboards },
  },
  {
    title: t('dashboards.reports'),
    disabled: true,
  },
]);

const showFurtherActions = ref(false);
const search = ref('');
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.scheduled-reports {
  ._header {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  &:deep(._list-header) {
    margin-bottom: .6rem;

  ._search {
    margin-right: .4rem;
    }
  }

  ._content {
    display: inline-table;
  }
}

</style>

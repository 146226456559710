<template>
  <page-layout
    class="plannings"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
      >
        <template #actions>
          <div
            class="_actions"
          >
            <template
              v-if="!$store.state.breakpoint.smAndDown"
            >
              <favorite-button
                :title-suggestion="$t('navigation.plannings')"
              />
              <m-tooltip
                placement="bottom"
              >
                <template #title>
                  {{ $t('planningList.helpText') }}
                </template>
                <m-btn
                  icon="question-circle"
                  :href="$t('planningList.helpLink')"
                  target="_blank"
                  hide-border
                  fab
                  small
                />
              </m-tooltip>
            </template>
            <m-dropdown
              v-else
              v-model:value="showFurtherActions"
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
                small
              />
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <favorite-button
                    :title-suggestion="$t('navigation.plannings')"
                  />
                  <a
                    :href="$t('planningList.helpLink')"
                    target="_blank"
                  >
                    <m-card-item
                      hide-border
                      small
                      icon="question-circle"
                    >
                      {{ $t('planningList.helpText') }}
                    </m-card-item>
                  </a>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :key="title"
          :title="title"
          disabled
          size="small"
        />
      </m-content>
      <full-screen-spinner v-if="firstLoadingSvc.loading.value && ids.length === 0" />
      <template v-else>
        <m-content
          padding-x="layout"
          class="_content-header"
        >
          <list-header>
            <template #left>
              <list-tab
                v-for="s in statusList"
                :key="s.value"
                :active="status === s.value"
                @click="status = s.value"
              >
                <item-title :title="s.text" />
              </list-tab>
            </template>
            <template #right>
              <list-string-filter
                small
                class="_btn"
                @input="val => searchTerm = val"
              />
              <m-btn
                color="primary"
                small
                class="_btn _create"
                @click="showPlanningForm = true"
              >
                {{ $t('general.new') }}
              </m-btn>
            </template>
          </list-header>
          <m-dialog
            v-model:value="showPlanningForm"
            :title="$t('planningForm.createTitle')"
            hide-footer
          >
            <planning-form @cancel="showPlanningForm = false" />
          </m-dialog>
        </m-content>
        <planning-list
          :ids="ids"
          @new="showPlanningForm = true"
        />
      </template>
    </scroll-container>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import ListTab from '@/components/list/ListTab.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import PlanningForm from '@/components/goal/plannings/PlanningForm.vue';
import PlanningList from '@/components/goal/plannings/PlanningList.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useDebounce from '@/composables/debounce';
import useFirstLoading from '@/composables/first-loading';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePlannings from '@/composables/planning/plannings';
import { planningStatus } from 'shared/constants.json';

export default {
  name: 'Plannings',
  components: {
    FullScreenSpinner,
    ItemTitle,
    ListTab,
    PageTopBar,
    ScrollContainer,
    EditablePageHeader,
    PageLayout,
    ListStringFilter,
    FavoriteButton,
    PlanningList,
    ListHeader,
    PlanningForm,
  },
  data() {
    const statusList = [
      {
        text: this.$t('plannings.open'),
        value: planningStatus.open,
      },
      {
        text: this.$t('plannings.closed'),
        value: planningStatus.closed,
      },
      {
        text: this.$t('plannings.all'),
        value: 'all',
      },
    ];
    return {
      showFurtherActions: false,
      searchTerm: '',
      showPlanningForm: false,
      statusList,
      status: statusList[0].value,
    };
  },
  setup() {
    const firstLoadingSvc = useFirstLoading();
    const { plannings, fetchPlannings, fetchPlanningsLoading } = usePlannings();
    const { debounce } = useDebounce({ onBeforeUnmountCancel: true });
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      firstLoadingSvc,
      plannings,
      fetchPlannings,
      fetchPlanningsLoading,
      loggedInUserAccount,
      debounce,
    };
  },
  computed: {
    ids() {
      return this.plannings.filter((p) => {
        if (this.filter.status === '') {
          return true;
        }

        return p.status === this.filter.status;
      }).filter((p) => {
        if (this.searchTerm === '') {
          return true;
        }

        return p.title.toLowerCase().includes(this.searchTerm.toLowerCase());
      }).map((p) => p.uid);
    },
    title() {
      return this.$t('planningList.pageTitle');
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.plannings'),
          disabled: true,
        },
      ];
    },
    filter() {
      const mapStatus = (status) => {
        if (status === 'all') {
          return '';
        }

        return status;
      };
      return {
        status: mapStatus(this.status),
        searchTerm: this.searchTerm,
      };
    },
  },
  methods: {
    fetch() {
      this.fetchPlannings(this.filter).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  watch: {
    searchTerm() {
      this.debounce(this.fetch, 500);
    },
    status() {
      this.fetch();
    },
    fetchPlanningsLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.firstLoadingSvc.finishLoading();
      }
    },
  },
  created() {
    this.firstLoadingSvc.startLoading();
    this.fetch();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.plannings {
  ._actions {
    display: flex;
  }

  ._content-header {
    margin-bottom: .6rem;

    ._btn {
      margin-left: .8rem;
    }
  }
}
</style>

import useSelectedViews from '@/composables/saved-views/selected-views';
import {
  GOAL_FEED_VIEW,
  GOAL_INSIGHTS_HEALTH_VIEW,
  GOAL_INSIGHTS_PERFORMANCE_VIEW,
  GOAL_INSIGHT_VIEW,
  GOAL_VIEW,
  OPTION_ID,
  PAGE_ID,
  UPDATE_VIEW,
  USER_VIEW,
  VIEW,
  VIEW_ID,
} from '@/route-params';
import {
  routeName,
  viewApplication,
} from 'shared/constants.json';
import { useRoute } from 'vue-router';

export default function useNavigator() {
  const { getSelectedViewViewIdForApplication } = useSelectedViews();
  const route = useRoute();

  const mapRouteToViewApplication = {
    [`${routeName.home}`]: viewApplication.goalHome,
    [`${routeName.home}_${GOAL_VIEW}`]: viewApplication.goalHome,
    [`${routeName.home}_${GOAL_FEED_VIEW}`]: viewApplication.updateFeedHome,
    [`${routeName.home}_${UPDATE_VIEW}`]: viewApplication.updateHome,

    [`${routeName.spaceDetails}_${GOAL_VIEW}`]: viewApplication.goalSpace,
    [`${routeName.spaceDetails}_${GOAL_FEED_VIEW}`]: viewApplication.updateFeedSpace,
    [`${routeName.spaceDetails}_${UPDATE_VIEW}`]: viewApplication.updateSpace,
    [`${routeName.spaceDetails}_${GOAL_INSIGHT_VIEW}`]: viewApplication.insightSpace,
    [`${routeName.spaceDetails}_${USER_VIEW}`]: viewApplication.memberSpace,

    [`${routeName.goalsExplorer}`]: viewApplication.goal,
    [`${routeName.goalUpdateFeed}`]: viewApplication.updateFeed,
    [`${routeName.planningList}`]: null,

    [`${routeName.updatesExplorer}`]: viewApplication.update,
    [`${routeName.updateSchedules}`]: null,
    [`${routeName.updateTemplates}`]: null,

    [`${routeName.goalInsights}_${GOAL_INSIGHTS_PERFORMANCE_VIEW}`]: viewApplication.insight,
    [`${routeName.goalInsights}_${GOAL_INSIGHTS_HEALTH_VIEW}`]: viewApplication.insightHealth,

    [`${routeName.dashboardDetails}`]: null,
  };

  const isActive = (to, whitelist = [OPTION_ID, PAGE_ID, VIEW, VIEW_ID]) => {
    if (to.params !== undefined) {
      const keys = Object.keys(to.params).filter((k) => (whitelist).includes(k));
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        if (route.params[k] !== to.params[k]) {
          return false;
        }
      }
    }
    if (to.query !== undefined) {
      const keys = Object.keys(to.query).filter((k) => (whitelist).includes(k));
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        if (route.query[k] !== to.query[k]) {
          return false;
        }
      }
    }
    return route.name === to.name;
  };

  const createLink = ({
    emoji = undefined,
    title,
    toName,
    toView = undefined,
    toViewId = undefined,
    toSpace = undefined,
    toGridPage = undefined,
    tag = undefined,
    planTag = null,
  }) => {
    const to = { name: toName };
    let id = `${toName}`;

    if (toSpace !== undefined && toSpace !== null) {
      id = `${id}_${toSpace}`;
      to.params = { ...to.params, [OPTION_ID]: `${toSpace}` };
    }
    if (toGridPage !== undefined && toGridPage !== null) {
      id = `${id}_${toGridPage}`;
      to.params = { ...to.params, [PAGE_ID]: `${toGridPage}` };
    }

    if (toView !== undefined && toView !== null) {
      id = `${id}_${toView}`;
      to.query = { ...to.query, [VIEW]: toView };
    }

    if (toViewId !== undefined && toViewId !== null) {
      to.query = { ...to.query, [VIEW_ID]: `${toViewId}` };
    }

    return {
      id,
      emoji,
      title,
      to,
      tag,
      planTag,
      active: isActive(to),
    };
  };

  const linkToRoute = (title, toName, toView = undefined, toSpace = undefined, toGridPage = undefined) => {
    let routeKey = `${toName}`;

    if (toView !== undefined) {
      routeKey = `${routeKey}_${toView}`;
    }

    let toViewId;
    const application = mapRouteToViewApplication[routeKey];
    if (application !== undefined && application !== null) {
      const input = { application };
      if (toSpace !== undefined) {
        input.space = { uid: toSpace };
      }
      if (toGridPage !== undefined) {
        input.gridPage = { uid: toGridPage };
      }
      toViewId = getSelectedViewViewIdForApplication(input).value;
    }

    const link = createLink({ title, toName, toView, toViewId, toSpace, toGridPage });
    link.active = isActive(link.to, [OPTION_ID, PAGE_ID, VIEW]);

    return link;
  };

  const linkToView = (view, application = null, space = null) => ({
    ...route,
    query: {
      ...route.query,
      [VIEW]: view,
      viewId: getSelectedViewViewIdForApplication({ application, space }).value,
    },
  });

  return { linkToRoute, linkToView };
}

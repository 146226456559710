<template>
  <div :class="['goals-list-header']">
    <document-list-header
      ref="documentListHeader"
      :slots="slots"
      :allowed-view-types="allowedViewTypes"
      :filter-props="filterProps"
      :show-create-view="showCreateView"
      :show-views-selector="showViewsSelector"
      :sort-options="sortOptions"
      :show-saved-view-info="showSavedViewInfo"
      has-dynamic-date-filters
      :read-only="readOnly"
      :show-apply-filter-on-first-level-only="showApplyFilterOnFirstLevelOnly"
      :selected-goal-cycles="selectedGoalCycles"
      :is-cycle-locked-on-view="isCycleLockedOnView"
      :change-selected-goal-cycles="changeSelectedGoalCycles"
      :lock-cycle-on-view="lockCycleOnView"
      :data-loading="dataLoading"
      @string-filter-changed="$emit('string-filter-changed', $event)"
      @expand-next-level="$emit('expand-next-level', $event)"
      @expand-all="$emit('expand-all', $event)"
      @collapse-next-level="$emit('collapse-next-level', $event)"
      @collapse-all="$emit('collapse-all', $event)"
    >
      <template #create>
        <m-tooltip>
          <template
            v-if="foreignSpaceReferenceRestricted"
            #title
          >
            {{ $t('foreignSpaceReference.insufficientPermissions') }}
          </template>
          <div>
            <m-btn
              data-cy="create-goal-btn"
              :loading="createLoading"
              color="primary"
              class="_btn -create"
              small
              :disabled="foreignSpaceReferenceRestricted"
              @click="$emit('create')"
            >
              {{ $t('general.new') }}
            </m-btn>
          </div>
        </m-tooltip>
      </template>
    </document-list-header>
  </div>
</template>

<script>
import DocumentListHeader from '@/components/list/DocumentListHeader.vue';
import useAccess from '@/composables/access/access';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalsSorting from '@/composables/goal/sort';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, propertyType, viewType } from 'shared/constants.json';
import { inject } from 'vue';
import { referencesForeignSpace } from '@/lib/goal/properties';

export default {
  name: 'GoalsListHeader',
  props: {
    selectedGoalCycles: {
      type: Array,
      default: () => [],
    },
    isCycleLockedOnView: {
      type: Boolean,
      default: false,
    },
    changeSelectedGoalCycles: {
      type: Function,
      default: () => {},
    },
    lockCycleOnView: {
      type: Function,
      default: () => {
      },
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    showSavedViewInfo: {
      type: Boolean,
      default: false,
    },
    filterProps: {
      type: Array,
      required: true,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [
        viewType.cascade,
        viewType.list,
        viewType.atlas,
      ],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showApplyFilterOnFirstLevelOnly: {
      type: Boolean,
      default: false,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: () => [],
      validator(slots) {
        return slots.every((slot) => [SLOTS.LOCKED, SLOTS.CYCLE_SELECTOR, SLOTS.STRING_FILTER, SLOTS.FILTER,
          SLOTS.PROPERTIES, SLOTS.GROUPING, SLOTS.SORTER, SLOTS.COMPARE_TO, SLOTS.PROGRESS_DISPLAY_OPTION, SLOTS.CARD_WIDTH, SLOTS.WRAP_CELLS, SLOTS.SHOW_PARENTS, SLOTS.EXPAND_BUTTONS, SLOTS.CREATE].includes(slot.name));
      },
    },
  },
  emits: ['string-filter-changed', 'create', 'expand-next-level', 'expand-all', 'collapse-next-level', 'collapse-all'],
  components: { DocumentListHeader },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    const { goalCycles } = useGoalCycle();
    const { properties: goalProperties } = useGoalProperty();
    const userSvc = useUsers();
    const { sortOptions } = useGoalsSorting(goalProperties, userSvc);
    const viewSvc = inject(VIEWS_SERVICE);
    return {
      userHasRight,
      loggedInUser,
      goalCycles,
      sortOptions,
      currentView: viewSvc.currentView,
    };
  },
  data() {
    return { viewType };
  },
  computed: {
    foreignSpaceReferenceRestricted() {
      if (this.userHasRight([accessGroupFlag.foreignEntityReference])) {
        return false;
      }

      if (this.currentView.space === null || this.currentView.space === undefined) {
        return false;
      }

      return referencesForeignSpace({ propertyValues: [{ property: { type: propertyType.space }, spaces: [this.currentView.space] }], user: this.loggedInUser });
    },
  },
  methods: {
    openMenu() {
      this.$refs.documentListHeader.openMenu();
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>

<template>
  <m-content
    padding
    class="_content"
  >
    <m-form-item
      v-if="!hideDashboardSelector"
      :label="$t('reportEditor.dashboard')"
    >
      <m-select
        v-model:value="selectedDashboard"
        full-width
        :items="dashboards"
        :has-error="selectedDashboard === null"
        match-trigger-width
      />
    </m-form-item>
    <m-form-item>
      <r-rule-picker
        v-model:value="schedule"
        full-width
        match-trigger-width
        hide-error
      />
    </m-form-item>
    <m-form-item :label="$t('reportEditor.recipients')">
      <div class="_recipients-input">
        <m-input
          v-model:value="email"
          full-width
          class="_input"
          :has-error="inputError !== ''"
          @focus="emailFocused = true"
          @blur="emailFocused = false"
          @enter="addRecipient"
        />
        <m-btn
          :disabled="recipients.length > maxRecipients"
          color="primary"
          @click="addRecipient"
        >
          {{ $t("reportEditor.addRecipient") }}
        </m-btn>
      </div>
      <div class="_error-message">
        <template v-if="inputError !== ''">
          {{ inputError }}
        </template>
      </div>
      <div class="_recipients">
        <m-tag
          v-for="recipient in recipients"
          :key="recipient"
          :title="recipient"
          closeable
          @close="removeRecipient(recipient)"
        />
      </div>
    </m-form-item>
    <m-form-item :label="$t('reportEditor.subject')">
      <m-input
        v-model:value="subject"
        full-width
      />
    </m-form-item>
    <m-form-item
      :label="$t('reportEditor.message')"
      class="_message"
    >
      <m-textarea
        v-model:value="message"
        full-width
        :rows="3"
      />
    </m-form-item>
  </m-content>
  <m-content
    class="_footer"
    padding
  >
    <m-btn
      color="secondary"
      class="_cancel"
      @click="cancel"
    >
      {{ $t("general.cancel") }}
    </m-btn>
    <m-btn
      color="primary"
      :loading="
        reportService.updateLoading.value || reportService.createLoading.value
      "
      :disabled="
        recipients.length === 0 ||
          selectedDashboard === null ||
          selectedDashboard === undefined
      "
      @click="save"
    >
      <template v-if="entity.uid === 0">
        {{ $t("general.create") }}
      </template>
      <template v-else>
        {{ $t("general.save") }}
      </template>
    </m-btn>
  </m-content>
</template>

<script setup>
import RRulePicker from '@/components/RRulePicker.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGridPage from '@/composables/grid-page/grid-page';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useReport from '@/composables/report/report';
import useSnackBar from '@/composables/snackbar';
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { emailRegex } from 'shared/lib/email-regex';
import { isEqual } from 'lodash';
import { printablePage, productPlan } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const reportService = useReport();
const snackbar = useSnackBar();

const props = defineProps({
  entity: {
    type: Object,
    required: true,
  },
  hideDashboardSelector: {
    type: Boolean,
    default: false,
  },
});

const { loggedInUser } = useLoggedInUser();

const emit = defineEmits(['cancel', 'close', 'dirty']);

const emailFocused = ref(false);

const gridPageService = useGridPage();
const dashboards = computed(() => gridPageService.gridPages.value.map((gridPage) => ({
  text: gridPage.title,
  value: gridPage.uid,
})));

const selectedDashboard = ref(null);

const schedule = ref(props.entity.schedule);
const email = ref('');
const recipients = ref([...props.entity.recipients]);

const isValidEmail = computed(() => {
  const rg = new RegExp(emailRegex());
  return rg.test(email.value);
});

const { accountSettings } = useAccountSettings();

const alreadyAdded = computed(() => recipients.value.includes(email.value));
const isTrial = computed(() => accountSettings.value.planId === productPlan.trial);

const maxRecipients = computed(() => {
  if (isTrial.value) {
    return 1;
  }

  return 50;
});

const errorMessage = computed(() => {
  if (!isValidEmail.value) {
    return t('reportEditor.invalidEmail');
  }
  if (alreadyAdded.value) {
    return t('reportEditor.alreadyAdded');
  }
  if (recipients.value.length >= maxRecipients.value) {
    if (isTrial.value) {
      return t('reportEditor.tooManyRecipientsTrial');
    }
    return t('reportEditor.tooManyRecipients');
  }
  return '';
});

const submitPressed = ref(false);
const inputError = computed(() => {
  if (!submitPressed.value && (email.value === '' || emailFocused.value)) {
    return '';
  }
  return errorMessage.value;
});

const addRecipient = () => {
  submitPressed.value = true;
  if (!isValidEmail.value || alreadyAdded.value || recipients.value.length >= maxRecipients.value) {
    return;
  }
  recipients.value = [...recipients.value, email.value];
  email.value = '';
};

watch(email, () => {
  submitPressed.value = false;
});

const subject = ref('');
const message = ref('');

const removeRecipient = (email) => {
  recipients.value = recipients.value.filter((r) => r !== email);
};

const cancel = () => {
  emit('cancel');
};

watch(selectedDashboard, (newVal) => {
  const dashboard = gridPageService.selectSingleGridPage(newVal);
  if (dashboard === undefined) {
    return;
  }
  if (props.entity.printable?.gridPage?.uid !== newVal) {
    emit('dirty');
  }
  subject.value = dashboard.title;
});

watch(schedule, (newVal) => {
  if (props.entity.schedule !== newVal) {
    emit('dirty');
  }
});

watch(recipients, (newVal) => {
  if (!isEqual(props.entity.recipients, newVal)) {
    emit('dirty');
  }
});

watch(message, (newVal) => {
  if (props.entity.message !== newVal) {
    emit('dirty');
  }
});

watch(subject, (newVal) => {
  if (props.entity.subject !== newVal) {
    emit('dirty');
  }
});

watch(props.entity, (newVal) => {
  if (isEqual(props.entity, newVal)) {
    return;
  }
  setValues();
});

const setValues = () => {
  selectedDashboard.value = props.entity.printable?.gridPage?.uid;
  schedule.value = props.entity.schedule;
  recipients.value = [...props.entity.recipients];
  if (props.entity.subject !== undefined) {
    subject.value = props.entity.subject;
  }
  if (props.entity.message !== undefined) {
    message.value = props.entity.message;
  }
};

const save = () => {
  if (props.entity.uid === 0) {
    return reportService
      .createSingle({
        activatedAt: DateTime.local().toISO(),
        schedule: schedule.value,
        recipients: recipients.value,
        subject: subject.value,
        message: message.value,
        printable: {
          creator: { uid: loggedInUser.value.uid },
          gridPage: { uid: selectedDashboard.value },
          page: printablePage.dashboard,
          localStorage: JSON.stringify(localStorage),
          width: 1200,
        },
      })
      .then(() => {
        emit('close', false);
        snackbar.success(t('success.created'));
      })
      .catch(() => {
        snackbar.error(t('error.default'));
      });
  }
  return reportService
    .updateSingle({
      ...props.entity,
      schedule: schedule.value,
      recipients: recipients.value,
      subject: subject.value,
      message: message.value,
      printable: {
        ...props.entity.printable,
        gridPage: { uid: selectedDashboard.value },
        page: printablePage.dashboard,
      },
    })
    .then(() => {
      emit('close', false);
      snackbar.success(t('success.updated'));
    })
    .catch(() => {
      snackbar.error(t('error.default'));
    });
};

setValues();
</script>

<style scoped lang="scss">
._content {
  ._recipients-input {
    display: flex;
    align-items: center;
  }

  ._input {
    margin-right: 1rem;
  }

  ._recipients {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
  }

  ._error-message {
    height: 1.6rem;
    color: $error-color;
  }

  ._message {
    margin-bottom: 0;
  }
}

._footer {
  display: flex;
  justify-content: flex-end;

  ._cancel {
    margin-right: 1rem;
  }
}
</style>

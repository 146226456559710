<template>
  <div class="select-menu">
    <m-card
      v-if="showMenu === 'menu'"
      :level="1"
      no-padding
      horizontal-layout
    >
      <div class="_select-menu">
        <goals-context-menu
          ref="contextMenu"
          :goal-ids="goalIds"
          :can-create="canCreate"
          show-expand-buttons
          @expand-all="handleExpandAll"
          @collapse-all="handleCollapseAll"
        />
        <m-card-item
          class="_card-item"
          @click="$emit('hide')"
        >
          <div class="_selected-text">
            {{ goalIds.length }} {{ $t('goalsContextMenu.selected') }}
          </div>
        </m-card-item>
        <m-card-item
          class="_card-item"
          @click="$emit('expand-and-select-all')"
        >
          {{ $t('goalsContextMenu.selectWithSubgoals') }}
        </m-card-item>
        <edit-goal-property-item
          class="_card-item"
          :disabled="editableGoals.length === 0"
          :tooltip="editableGoalsTooltip"
          :show-icon="false"
          @edit-property="editProperty"
        />

        <m-card-item
          v-if="canCreate"
          class="_card-item"
          icon="copy"
          :loading="duplicateLoading"
          @click="handleDuplicate"
        >
          {{ $t('general.duplicate') }}
        </m-card-item>
        <m-card-item
          class="_card-item"
          :disabled="editableGoals.length === 0"
          :tooltip="editableGoalsTooltip"
          :loading="deleteLoading"
          fab
          icon="delete"
          @click="handleDelete"
        />
        <m-card-item
          fab
          icon="ellipsis"
          @click="handleMore"
        />
      </div>
    </m-card>
    <template
      v-if="showMenu === 'prop-editor'"
    >
      <m-card
        padding-xs
      >
        <property-editor
          :prop="prop"
          :goals="toEdit"
          :success-message="successMessage"
          :goal-base-filter="goalBaseFilter"
          @close="hideMenu"
          @edited="$emit('prop-edited', prop)"
        />
      </m-card>
    </template>
  </div>
</template>

<script>
import EditGoalPropertyItem from '@/components/goal/EditGoalPropertyItem.vue';
import GoalsContextMenu from '@/components/goal/GoalsContextMenu.vue';
import PropertyEditor from '@/components/goal/PropertyEditor.vue';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useDuplicateGoal from '@/composables/goal/duplicate-goal';
import useGoalPickerFilter from '@/composables/goal/goal-picker-filter';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { accessPolicyType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'SelectMenu',
  props: {
    goalIds: {
      type: Array,
      required: true,
    },
    showExpandButtons: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['expand-and-select-all', 'hide', 'prop-edited', 'access-edited', 'expand-all', 'collapse-all', 'goals-deleted', 'goals-duplicated'],
  setup() {
    const { userLang } = useLoggedInUser();
    const { goalPickerFilter } = useGoalPickerFilter();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    const { duplicate, prepareDuplicate, duplicateLoading } = useDuplicateGoal();
    const { goalSettings } = useGoalSettings();
    const { selectMultiple } = useGoals();
    return {
      goalBaseFilter: goalPickerFilter,
      duplicate,
      prepareDuplicate,
      duplicateLoading,
      goalSettings,
      selectMultiple,
      deleteLoading,
      showDeleteGoalModal,
      userLang,
    };
  },
  components: {
    GoalsContextMenu,
    EditGoalPropertyItem,
    PropertyEditor,
  },
  data() {
    return {
      showMenu: 'menu',
      prop: null,
      toEdit: [],
      successMessage: '',
    };
  },
  computed: {
    selectedGoals() {
      return this.selectMultiple(this.goalIds);
    },
    editableGoals() {
      return this.selectedGoals.filter((g) => [accessPolicyType.write, accessPolicyType.full].includes(g.accessRight));
    },
    canEditAllGoals() {
      return this.editableGoals.length === this.selectedGoals.length;
    },
    editableGoalsTooltip() {
      if (this.editableGoals.length > 0 && !this.canEditAllGoals) {
        return this.$t('goalsContextMenu.deleteHint', { title: this.goalSettings.featureNamePlural });
      }

      if (this.editableGoals.length > 0) {
        return '';
      }

      return this.$t('goalsContextMenu.cannotDeleteMultiple', { title: this.goalSettings.featureNamePlural });
    },
  },
  methods: {
    handleDelete() {
      this.showDeleteGoalModal({ goals: this.editableGoals }).then(() => {
        this.onDelete();
      });
    },
    handleDuplicate() {
      this.duplicate(this.selectedGoals).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.duplicated') });
        this.$emit('goals-duplicated');
        this.onDuplicate();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleExpandAll() {
      this.$emit('expand-all');
      this.hideMenu();
    },
    handleCollapseAll() {
      this.$emit('collapse-all');
      this.hideMenu();
    },
    hideMenu() {
      this.$emit('hide');
    },
    onDelete() {
      this.$emit('goals-deleted');
      this.hideMenu();
    },
    onDuplicate() {
      this.$emit('hide');
    },
    reset() {
      this.showMenu = 'menu';
    },
    editProperty({ prop }) {
      this.prop = prop;
      this.toEdit = this.editableGoals;
      this.showMenu = 'prop-editor';
      this.successMessage = this.$t('success.updated');
    },
    handleMore(event) {
      this.$refs.contextMenu.show(event);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
._selected-text {
  color: $primary-color;
}

._select-menu {
  display: flex;
}

._card-item {
  border-right: 1px solid $border-color;;
}
</style>

import { MODIFIED_AT, UID } from 'shared/api/query/constants';
import { printableChildren } from '@/api/query/nebula/printable';
import { printable as printableConfig, report as reportConfig, user as userConfig } from 'shared/api/query/configs.json';

export const reportChildren = [
  { attr: UID },
  { attr: MODIFIED_AT, default: null },
  { attr: reportConfig.edges.activatedAt, default: null },
  { attr: reportConfig.edges.cancelledAt, default: null },
  { attr: reportConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }] },
  { attr: reportConfig.edges.schedule },
  { attr: reportConfig.edges.subject, default: '' },
  { attr: reportConfig.edges.message, default: '' },
  { attr: reportConfig.edges.recipients, default: [] },
  {
    attr: reportConfig.edges.printable,
    model: printableConfig.model,
    children: printableChildren,
  },
];

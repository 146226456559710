<template>
  <scroll-container class="temp-goal-list">
    <div
      :ref="(el) => viewHeaderPortalTarget = el"
      class="_view-header-portal-target _padded-container"
    />
    <div
      ref="wrapperHeader"
      class="goals-list-wrapper-header"
    >
      <m-content
        :padding-x="6"
      >
        <goals-list-header
          ref="header"
          :slots="slots"
          :selected-goal-cycles="selectedCycles"
          :change-selected-goal-cycles="changeSelectedGoalCycles"
          :filter-props="defaultProps"
          :data-loading="dataLoading"
        />
      </m-content>
    </div>
    <div
      class="_table"
    >
      <div class="_header-portal-target">
        <div :ref="(el) => headerPortalTarget = el" />
      </div>
      <r-goals-cascade-table
        :header-portal-target="headerPortalTarget"
        :view-header-portal-target="viewHeaderPortalTarget"
        :header-width="headerWidth"
        :selected-cycles="selectedCycles"
        :base-filter="publishedAtFilter"
        :goal-fetcher-options="goalFetcherOptions"
        :item-class="selectorClass"
        select-area-class="temp-goal-list"
        selectable
        show-errors
        :expand-local-storage-key-maker="expandLocalStorageKeyMaker"
        @selected-goals-updated="(val) => selectedGoalIds = val"
        @data-loading="dataLoading = true"
        @data-loaded="dataLoading = false"
        @selection-right-click="handleSelectionRightClick"
        @show-menu="handleShowMenuClick"
      />
      <goals-context-menu
        ref="contextMenu"
        :goal-ids="selectedGoalIds"
        show-expand-buttons
      />
    </div>
  </scroll-container>
</template>

<script>
import GoalsContextMenu from '@/components/goal/GoalsContextMenu.vue';
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useInMemoryGoalCycle from '@/composables/goal-cycle/in-memory-goal-cycle';
import useInMemoryViews from '@/composables/saved-views/in-memory-views';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewCascadeExpandKeyMaker from '@/composables/local-storage/view-cascade-expand-key-maker';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { computed, provide, ref } from 'vue';
import { copy } from 'shared/lib/copy';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { guid } from 'shared/lib/uuid';
import { useI18n } from 'vue-i18n';

export default {
  name: 'TempGoalList',
  props: {
    id: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ScrollContainer,
    GoalsListHeader,
    GoalsContextMenu,
  },
  data() {
    return {
      headerWidth: 0,
      dataLoading: false,
      selectorClass: 'goal-item',
      slots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.PROPERTIES }, { name: SLOTS.WRAP_CELLS }, { name: SLOTS.SHOW_PARENTS }],
      headerPortalTarget: null,
      viewHeaderPortalTarget: null,
    };
  },
  setup(props) {
    const { publishedAtFilter } = usePublishedAtFilter();
    const { composedSelectedCycles: persistedSelectedCycles } = usePersistedGoalCycle();
    const { composedSelectedCycles: selectedCycles, changeSelectedGoalCycles } = useInMemoryGoalCycle();
    changeSelectedGoalCycles(persistedSelectedCycles.value);

    const { loggedInUser } = useLoggedInUser();

    const { goalCycles } = useGoalCycle();
    const { t } = useI18n();
    const { properties: goalProperties } = useGoalProperty();

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: loggedInUser.value.lang,
    }));

    const defaultView = {
      isTemp: true,
      params: {
        filter: copy(props.filter),
        order: [],
        props: defaultProps.value,
        applyFilterOnFirstLevelOnly: false,
        key: 'key',
      },
    };

    const application = computed(() => {
      if (props.id === '') {
        return guid();
      }

      return props.id.replace(/[^a-zA-Z 0-9]/g, '').replace(' ', '_');
    });

    const inMemoryViewsSvc = useInMemoryViews({
      defaultProps,
      defaultView,
      application: `temp_goal_list_${application.value}`,
    });

    provide(VIEWS_SERVICE, inMemoryViewsSvc);

    const expandLocalStorageKeyMaker = useViewCascadeExpandKeyMaker(inMemoryViewsSvc.currentView);

    const goalFetcherOptions = {
      includeChildren: true,
      includeParents: true,
      includeGrandparents: true,
    };

    const selectedGoalIds = ref([]);

    return {
      selectedCycles,
      changeSelectedGoalCycles,
      publishedAtFilter,
      goalFetcherOptions,
      defaultProps,
      currentView: inMemoryViewsSvc.currentView,
      expandLocalStorageKeyMaker,
      selectedGoalIds,
    };
  },
  methods: {
    handleSelectionRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
    setHeaderWidth() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.headerWidth = this.$refs.header.$el.clientWidth;
        });
      });
    },
    handleShowMenuClick() {
      this.$refs.wrapperHeader.scrollIntoView({ behavior: 'instant' });
      setTimeout(() => {
        this.$refs.header.openMenu();
      }, 100);
    },
  },
  mounted() {
    window.addEventListener('resize', this.setHeaderWidth);
    this.setHeaderWidth();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setHeaderWidth);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import 'shared/assets/scss/padding';

  .temp-goal-list {
    position: relative;
    float: left;
    width: 100%;
    min-height: inherit;
    max-height: inherit;
    overflow: auto;

    .goals-list-wrapper-header {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 2;
      margin: .6rem 0 .6rem 0;

      @include layoutPaddingX();
    }

    ._table {
      display: inline-table;
      min-width: 100%;

      ._header-portal-target {
        position: sticky;
        top: 0;
        z-index: 2;
      }

      @include layoutPaddingX();
    }

    ._view-header-portal-target {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 3;

      @include layoutPaddingX();
    }
  }
</style>

<template>
  <div class="access-editor">
    <div class="_item">
      <access-policy
        :value="accessPolicy"
        button-color="default"
        :access-types="accessTypes"
        suppress-confirm
        @update:value="updateAccessPolicy"
      />
    </div>
    <m-content padding-xs>
      <div class="_actions">
        <div class="_left">
          {{ $t('accessEditor.hint') }}
        </div>
        <div class="_right">
          <m-btn
            class="_btn"
            hide-border
            small
            @click="$emit('close')"
          >
            {{ $t('general.cancel') }}
          </m-btn>
          <m-btn
            color="primary"
            class="_btn"
            small
            :loading="loading"
            @click="save"
          >
            {{ $t('general.save') }}
          </m-btn>
        </div>
      </div>
    </m-content>
  </div>
</template>

<script>
import AccessPolicy from '@/components/AccessPolicy.vue';
import { accessPolicyCommonScopes, copyAccessPolicy } from '@/lib/access-policy';
import { accessPolicyType } from 'shared/constants.json';
import { copy } from 'shared/lib/copy';
import { guid } from 'shared/lib/uuid';

export default {
  name: 'AccessEditor',
  props: {
    items: {
      type: Array,
      required: true,
    },
    defaultAccessPolicy: {
      type: Object,
      required: true,
    },
    updateEntitiesFn: {
      type: Function,
      default: () => new Promise((resolve) => { resolve(); }),
    },
    successMessage: {
      type: String,
      default: '',
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  emits: ['close', 'edited', 'input'],
  components: { AccessPolicy },
  data() {
    return { accessPolicy: null, loading: false };
  },
  methods: {
    updateAccessPolicy(val) {
      this.accessPolicy = val;
      this.$emit('input', val);
    },
    save() {
      this.loading = true;

      const entities = this.items.map((i) => ({
        uid: i.uid,
        accessPolicy: {
          uid: i.accessPolicy.uid,
          ...this.accessPolicy,
        },
      }));

      this.updateEntitiesFn(entities).then(() => {
        this.$showSnackbar({ color: 'success', message: this.successMessage });
        this.$emit('edited');
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }).finally(() => {
        this.loading = false;
      });
    },
    setKeys(accessPolicy) {
      if (!Array.isArray(accessPolicy.scopes) || accessPolicy.scopes.length === 0) {
        return accessPolicy;
      }

      accessPolicy.scopes = accessPolicy.scopes.map((scope) => ({
        ...scope,
        key: guid(),
      }));

      return accessPolicy;
    },
  },
  created() {
    if (this.items.length === 1) {
      this.accessPolicy = this.setKeys(copyAccessPolicy(this.items[0].accessPolicy));
      return;
    }
    const accessPolicyScopes = this.items.map((item) => copyAccessPolicy(item.accessPolicy).scopes);
    const commonScopes = accessPolicyCommonScopes(accessPolicyScopes);
    if (commonScopes.length === 0) {
      this.accessPolicy = this.setKeys(copy(this.defaultAccessPolicy));
      return;
    }

    const accessPolicy = {
      ...copyAccessPolicy(this.items[0].accessPolicy),
      scopes: commonScopes,
    };
    this.accessPolicy = this.setKeys(accessPolicy);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-editor {
    ._actions {
      display: flex;
      align-items: center;

      ._left {
        color: $font-color-secondary;
      }

      ._right {
        display: flex;
        margin-left: auto;

        ._btn {
          &:not(:first-of-type) {
            margin-left: .6rem;
          }
        }
      }
    }
  }
</style>

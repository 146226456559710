<template>
  <div
    ref="viewPort"
    class="atlas-wrapper"
    :style="wrapperStyle"
  >
    <full-screen-spinner
      v-if="firstLoadingSvc.loading.value && network.length === 0"
      :height="150"
    />
    <template v-else>
      <atlas-content
        :network="network"
        :props="viewProps"
        :grouping="currentView.params.grouping"
        :groups="groups"
        :load-more-loading="dataLoading"
        @get-children="expandGoal"
        @load-more="loadMore"
      />
    </template>
  </div>
</template>

<script>
import AtlasContent from '@/components/goal/atlas/AtlasContent.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useCascadeExpand from '@/composables/goal/cascade/expand';
import useCascadeViewParams from '@/composables/goal/cascade/view-params';
import useCustomFuncCtx from '@/composables/custom-func/custom-func-ctx';
import useDebounce from '@/composables/debounce';
import useExpand from '@/composables/expand';
import useFirstLoading from '@/composables/first-loading';
import useGoalFetcher from '@/composables/goal/fetcher';
import useGoalFilter from '@/composables/goal/cascade/goal-filter';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTree from '@/composables/goal/cascade/goal-tree';
import useGoalTypes from '@/composables/property/goal-type';
import useGoals from '@/composables/goal/goals';
import useGoalsSorting from '@/composables/goal/sort';
import useLoadExpanded from '@/composables/goal/cascade/load-expanded';
import useLoader from '@/composables/goal/cascade/loader';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import useViewTreeExpandKeyMaker from '@/composables/local-storage/view-tree-expand-key-maker';
import { EVENTS, VIEWS_SERVICE } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { computed, inject, ref, toRef } from 'vue';
import { createViewId } from '@/lib/saved-view/saved-view';
import { normalizeTree } from '@/lib/goal/normalize-tree';
import { optionColor, propertyType } from 'shared/constants.json';
import { rgbaToHex } from 'shared/lib/color';
import { visifyGoals } from '@/lib/goal/visify-goals';

export default {
  name: 'AtlasWrapper',
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    selectedCycles: {
      type: Array,
      default: () => [],
    },
    defaultFilter: {
      type: Object,
      default: () => null,
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    goalFetcherOptions: {
      type: Object,
      default: () => undefined,
    },
  },
  setup(props) {
    const viewPort = ref(null);
    const { properties: goalProperties } = useGoalProperty();
    const { goalTypes } = useGoalTypes();
    const { goalSettings } = useGoalSettings();
    const { userLang } = useLoggedInUser();

    const viewsService = inject(VIEWS_SERVICE);
    const firstLoadingSvc = useFirstLoading({ currentView: viewsService.currentView });

    const { debounce } = useDebounce();

    const { loggedInUserAccount } = useLoggedInUserAccount();

    const { spaces } = useSpaces();

    const viewParamsService = useCascadeViewParams(
      viewsService,
      props.defaultFilter,
      loggedInUserAccount,
      toRef(props, 'searchTerm'),
      toRef(props, 'selectedCycles'),
    );

    const customFuncCtxAlias = computed(() => `${createViewId(viewsService.currentView.value)}_compareTo`);
    const customFuncCtx = useCustomFuncCtx(customFuncCtxAlias);
    const goalFetcher = useGoalFetcher(goalProperties, viewParamsService, customFuncCtx, props.goalFetcherOptions);
    const goalFetcherWithFirstLoading = {
      getGoals: (...args) => {
        firstLoadingSvc.startLoading();
        return goalFetcher.getGoals(...args);
      },
    };

    const goalsSvc = useGoals();

    const goalFilter = useGoalFilter(
      toRef(props, 'baseFilter'),
      viewParamsService,
    );

    const userSvc = useUsers();
    const { sort } = useGoalsSorting(goalProperties, userSvc);

    const rootItemsPerPage = 10;
    const initialAmountOfItems = 30;
    const loadMoreAmount = 20;
    const { fetchLoading, listLoading, retrieveGoals, loadMore } = useLoader(
      rootItemsPerPage,
      initialAmountOfItems,
      loadMoreAmount,
      goalFetcherWithFirstLoading,
      viewParamsService,
      debounce,
      toRef(props, 'selectedCycles'),
      toRef(props, 'searchTerm'),
      ref([]),
      toRef(props, 'baseFilter'),
    );

    const localStorageSvc = useLocalStorage(localStorage, useViewTreeExpandKeyMaker(viewsService.currentView));
    const identifier = { getId: (o) => o.index };
    const expandSvc = useExpand(identifier, localStorageSvc.data);
    const cascadeExpand = useCascadeExpand(expandSvc, localStorageSvc.data);

    const childrenKey = '_children';
    const goalTreeService = useGoalTree(
      viewsService,
      viewParamsService,
      goalFilter,
      sort,
      goalsSvc,
      goalsSvc.entityList,
      cascadeExpand,
      childrenKey,
    );

    const normalizedTree = computed(() => normalizeTree(childrenKey)(goalTreeService.goalTree.value));
    const visibleProps = computed(() => viewParamsService.props.value.filter((p) => !p.hideInProps));

    const network = computed(() => visifyGoals(
      normalizedTree.value,
      loggedInUserAccount.value,
      viewsService.currentView.value.params.grouping,
      goalSettings.value,
      visibleProps.value,
      spaces.value,
      userLang.value,
      goalTypes.value,
      goalsSvc,
    ));

    const { showAsLoading: goalsLoading } = useLoadExpanded(
      goalsSvc,
      normalizedTree,
      cascadeExpand,
      goalFetcher,
    );

    return {
      firstLoadingSvc,
      goalProperties,

      currentView: viewsService.currentView,
      initCurrentView: viewsService.initCurrentView,
      viewProps: visibleProps,

      goalsSvc,

      viewPort,

      network,
      retrieveGoals,
      loadMore,
      toggleExpand: (uid) => cascadeExpand.toggleExpand(normalizedTree.value.find((e) => e.entity.uid === uid)),

      expandGoal: (uid) => cascadeExpand.expandItem(normalizedTree.value.find((e) => e.entity.uid === uid)),
      goalsLoading,
      fetchLoading,
      listLoading,
    };
  },
  components: { FullScreenSpinner, AtlasContent },
  data() {
    return { boundingClientRect: { top: 0, left: 0, width: 0, height: 0 } };
  },
  computed: {
    contentHeight() {
      return `calc(100vh - ${this.boundingClientRect.top + 1}px)`;
    },
    dataLoading() {
      return this.fetchLoading || this.goalsLoading.length > 0;
    },
    wrapperStyle() {
      return {
        height: this.contentHeight,
        overflow: 'hidden',
      };
    },
    groups() {
      return {
        ...this.goalProperties.find((property) => property.type === propertyType.status).options.reduce((res, cur) => ({
          ...res,
          [Number(cur.uid).toString()]: {
            color: this.propertyOptionToGroup(cur),
            borderWidth: 1,
          },
        }), {
          0: {
            color: {
              background: rgbaToHex(this.$colors.grey.lighten3),
              border: rgbaToHex(this.$colors.grey.lighten3),
              highlight: {
                background: rgbaToHex(this.$colors.grey.lighten2),
                border: rgbaToHex(this.$colors.grey.lighten2),
              },
              hover: {
                background: rgbaToHex(this.$colors.grey.lighten2),
                border: rgbaToHex(this.$colors.grey.lighten2),
              },
            },
            borderWidth: 1,
          },
        }),
        company: {
          color: {
            background: this.$colors.shades.white,
            border: rgbaToHex(this.$colors.grey.lighten2),
            highlight: {
              background: this.$colors.shades.white,
              border: rgbaToHex(this.$colors.grey.lighten1),
            },
            hover: {
              background: this.$colors.shades.white,
              border: rgbaToHex(this.$colors.grey.lighten1),
            },
          },
          borderWidth: 2,
        },
        [optionColor.green]: {
          color: {
            background: this.$colors.green.lighten2,
            border: this.$colors.green.lighten2,
            highlight: {
              background: this.$colors.green.lighten1,
              border: this.$colors.green.lighten1,
            },
            hover: {
              background: this.$colors.green.base,
              border: this.$colors.green.base,
            },
          },
          borderWidth: 1,
        },
        [optionColor.yellow]: {
          color: {
            background: this.$colors.yellow.lighten2,
            border: this.$colors.yellow.lighten2,
            highlight: {
              background: this.$colors.yellow.lighten1,
              border: this.$colors.yellow.lighten1,
            },
            hover: {
              background: this.$colors.yellow.base,
              border: this.$colors.yellow.base,
            },
          },
          borderWidth: 1,
        },
        [optionColor.red]: {
          color: {
            background: this.$colors.red.lighten2,
            border: this.$colors.red.lighten2,
            highlight: {
              background: this.$colors.red.lighten1,
              border: this.$colors.red.lighten1,
            },
            hover: {
              background: this.$colors.red.base,
              border: this.$colors.red.base,
            },
          },
          borderWidth: 1,
        },
        [optionColor.grey]: {
          color: {
            background: rgbaToHex(this.$colors.grey.lighten2),
            border: rgbaToHex(this.$colors.grey.lighten2),
            highlight: {
              background: rgbaToHex(this.$colors.grey.lighten1),
              border: rgbaToHex(this.$colors.grey.lighten1),
            },
            hover: {
              background: rgbaToHex(this.$colors.grey.base),
              border: rgbaToHex(this.$colors.grey.base),
            },
          },
          borderWidth: 1,
        },
      };
    },
  },
  methods: {
    propertyOptionToGroup(propertyOption) {
      const colorTranslator = (color) => {
        if (color.startsWith('#')) {
          return color;
        }

        return rgbaToHex(color);
      };

      const buildTranslation = (color) => ({
        background: colorTranslator(color.lighten2),
        border: colorTranslator(color.lighten2),
        highlight: {
          background: colorTranslator(color.lighten1),
          border: colorTranslator(color.lighten1),
        },
        hover: {
          background: colorTranslator(color.lighten1),
          border: colorTranslator(color.lighten1),
        },
      });

      if (this.$colors[propertyOption.color] !== undefined) {
        return buildTranslation(this.$colors[propertyOption.color]);
      }

      return buildTranslation(this.$colors.grey);
    },
  },
  emits: ['data-loaded', 'data-loading'],
  watch: {
    dataLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.$emit('data-loaded');
      }
      if (oldVal === false && newVal === true) {
        this.$emit('data-loading');
      }
    },
    listLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.firstLoadingSvc.finishLoading();
      }
    },
  },
  mounted() {
    this.boundingClientRect = this.viewPort.getBoundingClientRect();
    this.initCurrentView();
    this.retrieveGoals();
  },
  created() {
    EventBus.$emit(EVENTS.VIEW.ATLAS_VIEW_VISITED, { view: this.currentView });
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .atlas-wrapper {
    ._partially-loading {
      position: fixed;
      right: 0;
      bottom: 2rem;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      animation: enter .1s ease-in-out;

      @keyframes enter {
        0% {
          bottom: -2rem;
        }

        100% {
          bottom: 2rem;
        }
      }

      ._inner {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ._icon {
        margin-right: .6rem;
      }
    }
  }
</style>

<template>
  <div class="form-send-summary">
    <detail-item
      :value="surveyType"
      :label="$t('formSendSummary.surveyType')"
      inline
    />
    <m-divider />
    <detail-item
      :value="`${form.orderItems.length} ${$t('formSendSummary.questions')}`"
      :label="$t('formSendSummary.numberOfQuestions')"
      inline
    />
    <template v-if="surveyProcess.pulseMode">
      <m-divider />
      <detail-item
        :value="`${surveyProcess.questionsPerPulse} ${$t('formSendSummary.questions')}`"
        :label="$t('formSendSummary.questionsPerPulse')"
        inline
      />
    </template>
    <m-divider />
    <detail-item
      :value="surveyProcess.form.privacyThreshold"
      :label="$t('formSendSummary.privacyThreshold')"
      inline
    />
    <m-divider />
    <detail-item
      :label="$t('formSendSummary.startDate')"
      inline
      :show-error="!isValidStartDate"
      :error-text="$t('formSendSummary.startDateNotValid')"
    >
      <template
        v-if="isValidStartDate"
        #value
      >
        {{ startDate }}
      </template>
      <template
        v-else
        #value
      >
        <router-link :to="sendOptionsPath">
          {{ startDate }}
        </router-link>
      </template>
    </detail-item>
    <m-divider />
    <detail-item
      :value="frequency"
      :label="$t('formSendSummary.frequency')"
      inline
    />
    <m-divider />
    <detail-item
      :value="firstOccurence"
      :label="$t('formSendSummary.firstOccurence')"
      inline
    />
    <m-divider />
    <detail-item
      :label="$t('formSendSummary.reminders')"
      inline
    >
      <template #value>
        <div>
          <span v-if="surveyProcess.reminderCount === 0">{{ $t('formSendSummary.noReminders') }}</span>
          <div
            v-for="i in surveyProcess.reminderCount"
            :key="i"
            class="_reminder"
          >
            <span>{{ i }}. {{ $t('formSendSummary.reminder') }}</span>
            <time-difference
              :duration="i * surveyProcess.remindAfter"
            />
          </div>
        </div>
      </template>
    </detail-item>
    <m-divider />
    <detail-item
      :label="$t('formSendSummary.participants')"
      inline
    >
      <template #value>
        <div>
          <m-link
            underline-on-hover
            @click="showUserList"
          >
            {{ userList.length }}
          </m-link>
        </div>
      </template>
    </detail-item>
    <m-drawer
      :value="userListDrawer"
      :title="$t('formSendSummary.userList')"
      @close="userListDrawer = false"
    >
      <m-content padding>
        <m-card-item
          v-for="user in userList"
          :key="user.uid"
          no-hover
          :clickable="false"
        >
          <user-display :user="user" />
        </m-card-item>
      </m-content>
    </m-drawer>
    <m-divider />
    <detail-item
      v-if="distributionChannel !== null"
      :label="$t('formSendSummary.initialEmail')"
      inline
    >
      <template #value>
        <div>
          <m-link
            underline-on-hover
            @click="previewVisible = true"
          >
            {{ $t('formSendSummary.previewEmail') }}
          </m-link>
          <m-dialog
            v-model:value="previewVisible"
            :title="$t('formSendChannels.previewEmailTitle')"
            no-padding
            hide-footer
          >
            <m-content
              padding
              :style="contentStyle"
            >
              <detail-item
                :label="`${$t('formSendChannels.subject')}:`"
                :value="getMultilangValue(distributionChannel.mailProperties.subject)"
              />
            </m-content>
            <email-preview :body="getMultilangValue(distributionChannel.mailProperties.body)">
              <template #actions>
                <div
                  class="_actions"
                >
                  <m-btn
                    color="primary"
                  >
                    {{ $t('emailPreview.takeSurvey') }}
                  </m-btn>
                </div>
              </template>
            </email-preview>
          </m-dialog>
        </div>
      </template>
    </detail-item>
  </div>
</template>

<script>
import DetailItem from '@/components/DetailItem.vue';
import EmailPreview from '@/components/EmailPreview.vue';
import TimeDifference from '@/components/TimeDifference.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRRuleTranslations from '@/composables/rrule-translations/translations';
import { DateTime } from 'luxon';
import { FORM_SEND_OPTIONS } from '@/route-names';
import { RRule, rrulestr } from 'rrule';
import { distributionChannelType } from 'shared/constants.json';
import { hasValidStartDate } from '@/lib/rrule';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'FormSendSummary',
  props: {
    surveyProcess: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  components: {
    DetailItem,
    EmailPreview,
    TimeDifference,
    UserDisplay,
  },
  setup() {
    const { userLang } = useLoggedInUser();
    const { rruleToText } = useRRuleTranslations();
    return { userLang, rruleToText };
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 5,
      page: 1,
      userListDrawer: false,
      previewVisible: false,
    };
  },
  computed: {
    userList() {
      return this.surveyProcess.participants;
    },
    contentStyle() {
      return { 'border-bottom': `1px solid ${this.$colors.grey.lighten3}` };
    },
    surveyType() {
      if (this.surveyProcess.pulseMode) {
        return this.$t('formSendSummary.pulseSurvey');
      }
      return this.$t('formSendSummary.standardSurvey');
    },
    isValidStartDate() {
      return this.processIsActive || hasValidStartDate(this.surveyProcess.schedule);
    },
    startDate() {
      const rule = rrulestr(this.surveyProcess.schedule);
      const d = DateTime.fromJSDate(rule.options.dtstart, { zone: rule.options.tzid });
      return DateTime.local(d.year, d.month, d.day, this.hoursFromOrigOptions(rule.origOptions), this.minutesFromOrigOptions(rule.origOptions)).toLocaleString(DateTime.DATETIME_FULL);
    },
    processIsActive() {
      return this.surveyProcess.activatedAt !== null;
    },
    firstOccurence() {
      const rule = RRule.fromString(this.surveyProcess.schedule);
      const d = DateTime.fromJSDate(rule.all((_, len) => len < 2)[0]);
      return DateTime.local(d.year, d.month, d.day, this.hoursFromOrigOptions(rule.origOptions), this.minutesFromOrigOptions(rule.origOptions)).toLocaleString(DateTime.DATETIME_FULL);
    },
    frequency() {
      const rule = RRule.fromString(this.surveyProcess.schedule);
      if (rule.options.count === 1) {
        return this.$t('formSendSummary.once');
      }
      return this.rruleToText(rule);
    },
    distributionChannel() {
      const mailChannel = this.surveyProcess.distributionChannels.filter((c) => c.type === distributionChannelType.mail);
      if (mailChannel.length !== 1) {
        return null;
      }

      return mailChannel[0];
    },
    sendOptionsPath() {
      return { name: FORM_SEND_OPTIONS, params: { formId: this.form.uid } };
    },
  },
  methods: {
    minutesFromOrigOptions(origOptions) {
      if (typeof origOptions.byminute === 'undefined') {
        return 0;
      }
      return origOptions.byminute;
    },
    hoursFromOrigOptions(origOptions) {
      if (typeof origOptions.byhour === 'undefined') {
        return 0;
      }
      return origOptions.byhour;
    },
    getMultilangValue(val) {
      return textByLang(val, this.userLang);
    },
    showUserList() {
      this.userListDrawer = true;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .form-send-summary {
    ._reminder {
      text-align: right;
    }
  }
</style>

<template>
  <div class="export-button">
    <m-card-item
      hide-border
      small
      icon="download"
      @click="exportCSV"
    >
      {{ $t('exportButton.buttonText') }}
    </m-card-item>
  </div>
  <teleport to="#overlay">
    <overlay-spinner
      v-if="loading"
      :text="$t('exportButton.exportingCSV')"
    />
  </teleport>
</template>

<script setup>
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import useSnackBar from '@/composables/snackbar';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { doCsvExport } from '@/api';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  selectedView: {
    type: Object,
    required: true,
  },
});

const snackBar = useSnackBar();
const { t } = useI18n();

const loading = ref(false);
const exportCSV = () => {
  loading.value = true;
  let filter = null;

  if (props.selectedView !== null && props.selectedView.params !== null) {
    filter = props.selectedView.params.filter;
  }
  const handler = new GoalFilterHandler();
  const filterObject = handler.Translate(filter);

  return doCsvExport({ filter: filterObject }).then((response) => {
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'mooncamp_export.csv'; // Fallback filename
    if (contentDisposition && contentDisposition.includes('filename=')) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match[1]) {
        filename = match[1];
      }
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }).catch(logCatch((e) => {
    if (e.status === 429) {
      snackBar.error(t('exportButton.tooManyRequests'));
      return;
    }
    snackBar.error();
  })).finally(() => {
    loading.value = false;
  });
};
</script>

<template>
  <div class="select-all-checkbox">
    <div
      :class="['_checkbox', selectionState !== SELECTION_STATE_NONE_SELECTED ? '-visible': '']"
    >
      <m-checkbox
        :value="selectionState !== SELECTION_STATE_NONE_SELECTED"
        :icon="selectIcon"
        small
        @mousedown.stop="$emit('select-all-clicked')"
      />
    </div>
  </div>
</template>

<script setup>
import {
  SELECTION_STATE_ALL_SELECTED,
  SELECTION_STATE_NONE_SELECTED,
  SELECTION_STATE_PARTIALLY_SELECTED,
} from '@/composables/select';
import { computed } from 'vue';

const props = defineProps({
  selectionState: {
    type: String,
    default: '',
  },
});

defineEmits(['select-all-clicked']);

const selectIcon = computed(() => {
  if (props.selectionState === SELECTION_STATE_ALL_SELECTED) {
    return 'check-mark';
  }
  if (props.selectionState === SELECTION_STATE_PARTIALLY_SELECTED) {
    return 'minus';
  }
  return '';
});
</script>

<style scoped lang="scss">
.select-all-checkbox {
  background-color: white;

  ._checkbox {
    opacity: 0;
    transition: opacity 0.1s ease;

    &:hover {
      opacity: 1;
    }

    &.-visible {
      opacity: 1;
    }
  }
}
</style>

<template>
  <svg
    focusable="false"
    data-icon="play-circle"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    viewBox="64 64 896 896"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm144.1 454.9L437.7 677.8a8.02 8.02 0 01-12.7-6.5V353.7a8 8 0 0112.7-6.5L656.1 506a7.9 7.9 0 010 12.9z" />
  </svg>
</template>

<script>
export default { name: 'PlayCircle' };
</script>

<style scoped lang="scss" type="text/scss">

</style>

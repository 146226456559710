<template>
  <not-found-page
    v-if="!loading && typeof schedule.uid === 'undefined'"
    :to="{ name: routeName.updateSchedules }"
    :title="$t('notFoundPage.title', { type: $t('notFoundPage.checkInSchedule')})"
    :anchor-text="$t('notFoundPage.buttonText', { types: $t('navigation.updateSchedules')})"
  />
  <page-layout
    v-else
    :class="['schedule-detail-page', modal ? '-modal' : '', schedule.isDirty ? '-dirty' : '']"
    :loading="loading"
    :modal="modal"
  >
    <template
      #topBar
    >
      <schedule-detail-top
        :notification="schedule"
        :modal="modal"
        :can-edit="canEdit"
        @recreated="$emit('recreated', $event)"
      />
    </template>
    <scroll-container>
      <m-content
        boxed-small
        padding-x="layout"
        class="_content"
      >
        <div class="_title">
          <editable-page-header
            :key="pageHeaderKey"
            size="small"
            :title="title"
            :icon="icon"
            :show-icon="!isEmptyIcon(icon) || emojiAdded"
            :auto-focus="schedule.title === ''"
            :placeholder="$t('general.untitled')"
            :disabled="!canEdit"
            show-icon-on-top
            class="_title"
            @change-icon="changeIcon"
            @change-title="changeTitle"
          >
            <template #actions>
              <div class="_header-actions">
                <m-btn
                  v-if="isEmptyIcon(icon) && !emojiAdded && canEdit"
                  hide-border
                  class="_item"
                  icon="smile"
                  light
                  small
                  @click="addEmoji"
                >
                  {{ $t('page.addEmoji') }}
                </m-btn>
              </div>
            </template>
          </editable-page-header>
        </div>
        <div class="_properties">
          <property-label
            :label="$t('schedulesTable.status')"
            icon="poweroff"
            :class="['_prop', !canEdit ? '-disabled' : '']"
          >
            <m-dropdown
              block
              match-trigger-width
              placement="onTopLeft"
              :title="$t('schedulesTable.status')"
              :disabled="!canEdit"
            >
              <div class="_value">
                <div class="_status">
                  <m-tag
                    :color="getStatusType(schedule)"
                    class="_tag"
                    :title="getStatus(schedule)"
                  />
                </div>
              </div>
              <template #overlay>
                <m-card
                  class="_overlay"
                  no-padding
                >
                  <div class="_top">
                    <div
                      class="_status"
                      :style="{ display: 'inline-flex' }"
                    >
                      <m-tag
                        :color="getStatusType(schedule)"
                        class="_tag"
                        :title="getStatus(schedule)"
                      />
                    </div>
                  </div>
                  <div
                    class="_list"
                    :style="{ padding: '.4rem 0' }"
                  >
                    <m-card-item
                      v-for="s in status"
                      :key="s.value"
                      class="_status"
                      @click="changeStatus(s.value)"
                    >
                      <m-tag
                        :color="s.type"
                        class="_tag"
                        :title="s.title"
                      />
                    </m-card-item>
                  </div>
                </m-card>
              </template>
            </m-dropdown>
          </property-label>
          <property-label
            :label="$t('schedulesTable.schedule')"
            icon="calendar"
            :class="['_prop']"
          >
            <m-dropdown
              placement="onTopLeft"
              :title="$t('schedulesTable.schedule')"
              match-trigger-width
              block
            >
              <div class="_value -rrule">
                {{ rruleText }}
              </div>
              <template #overlay>
                <m-card
                  class="_overlay -schedule"
                  no-padding
                >
                  <div class="_top">
                    {{ rruleText }}
                  </div>
                  <r-rule-picker
                    :value="schedule.schedule"
                    class="_overlay-content"
                    hide-error
                    :disabled="!canEdit"
                    @input="changeSchedule"
                  />
                  <m-content
                    padding-xs
                    :class="['_hint', notificationUnsure ? '-warning' : '']"
                  >
                    <template v-if="nextNotification === null">
                      {{ $t('scheduleDetailPage.noSendOut') }}
                    </template>
                    <template v-else>
                      <m-icon
                        v-if="notificationUnsure"
                        type="warning"
                        :color="$colors.yellow.darken1"
                        class="_icon"
                      />
                      <span>
                        {{
                          $t('scheduleDetailPage.nextSendOut', { date: nextNotification.toLocaleString(DateTime.DATETIME_MED) })
                        }}
                        <template v-if="notificationUnsure">
                          {{ $t('scheduleDetailPage.sendOutHint') }}
                        </template>
                      </span>
                    </template>
                  </m-content>
                </m-card>
              </template>
            </m-dropdown>
          </property-label>
          <property-label
            :label="$t('schedulesTable.participants')"
            icon="team"
            :class="['_prop', !canEdit ? '-disabled' : '']"
          >
            <m-dropdown
              v-model:value="showRecipients"
              block
              placement="onTopLeft"
              :title="$t('schedulesTable.participants')"
              :disabled="!canEdit"
            >
              <div class="_value">
                {{ `${recipientsCount} ${$t('userScopeTreeUserField.persons', recipientsCount)}` }}
              </div>
              <template #overlay>
                <m-card
                  no-padding
                  class="_overlay"
                >
                  <user-scope-tree-user-field
                    :class="['_overlay-content -recipients', $store.state.breakpoint.mdAndUp ? '-not-mobile' : '']"
                    :value="schedule.recipients"
                    :disabled="!canEdit"
                    @input="updateRecipients"
                    @close="showRecipients = false"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </property-label>
          <property-label
            :label="$t('scheduleDetailPage.reminders')"
            icon="bell"
            :class="['_prop', !canEdit ? '-disabled' : '']"
          >
            <m-dropdown
              block
              match-trigger-width
              placement="onTopLeft"
              :title="$t('scheduleDetailPage.reminders')"
              :disabled="!canEdit"
            >
              <div class="_value">
                <div
                  v-if="filteredReminders.length === 0"
                  class="_empty"
                >
                  {{ $t('scheduleDetailPage.noReminders') }}
                </div>
                <reminder-item
                  v-for="(item, index) in filteredReminders"
                  :key="index"
                  :reminder="item"
                  disabled
                  component="div"
                />
              </div>
              <template #overlay>
                <m-card
                  no-padding
                  list
                  placement="onTopLeft"
                  class="_overlay"
                >
                  <reminder-list
                    :reminders="filteredReminders"
                    :disabled="!canEdit"
                    @create="addReminder"
                    @update="updateReminder"
                    @delete="deleteReminder"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </property-label>
          <property-label
            :label="$t('schedulesTable.template')"
            icon="read"
            :class="['_prop', !canEdit ? '-disabled' : '']"
          >
            <m-dropdown
              block
              match-trigger-width
              placement="onTopLeft"
              :title="$t('schedulesTable.template')"
              :disabled="!canEdit"
            >
              <div class="_value">
                <template v-if="schedule.updateTemplate !== null">
                  <item-title
                    :title="schedule.updateTemplate.title"
                    :icons="[{ value: buildIconFromEntity(schedule.updateTemplate) }]"
                  />
                </template>
                <div
                  v-else
                  class="_empty"
                >
                  {{ $t('updateTemplateSelector.noTemplateSelected') }}
                </div>
              </div>
              <template #overlay>
                <m-card

                  class="_overlay"
                  no-padding
                >
                  <div class="_top">
                    <template v-if="schedule.updateTemplate !== null">
                      <item-title
                        :title="schedule.updateTemplate.title"
                        :icons="[{ value: buildIconFromEntity(schedule.updateTemplate) }]"
                      />
                    </template>
                    <div
                      v-else
                      class="_is-empty"
                    >
                      {{ $t('updateTemplateSelector.noTemplateSelected') }}
                    </div>
                  </div>
                  <template-list
                    :style="{ padding: '.4rem 0' }"
                    @close="changeTemplate"
                    @delete-template="deleteTemplate"
                    @select="changeTemplate"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </property-label>
        </div>
        <m-divider />
        <div class="_participation-rate">
          <div class="_title">
            {{ $t('scheduleDetailPage.participationRate') }}
          </div>
          <participation-chart :chart-data="chartData" />
        </div>
        <div class="_participants-list">
          <participants-table
            v-if="Object.keys(schedule).length > 0"
            :participation-list="schedule.updateParticipationList"
            :occurrences="occurrences"
          />
        </div>
      </m-content>
    </scroll-container>
    <m-content
      v-if="schedule.isDirty && canEdit"
      padding-x="layout"
      boxed-small
      class="_footer"
    >
      <div class="_footer-inner">
        <m-btn
          class="_btn"
          hide-border
          @click="cancel"
        >
          {{ $t('general.cancel') }}
        </m-btn>
        <m-btn
          class="_btn"
          color="primary"
          :loading="updateLoading"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-btn>
      </div>
    </m-content>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import NotFoundPage from '@/components/NotFoundPage.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import ParticipantsTable from '@/components/update-schedules/ParticipantsTable.vue';
import ParticipationChart from '@/components/update-schedules/ParticipationChart.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import RRulePicker from '@/components/RRulePicker.vue';
import ReminderItem from '@/components/update-schedules/ReminderItem.vue';
import ReminderList from '@/components/update-schedules/ReminderList.vue';
import ScheduleDetailTop from '@/components/update-schedules/ScheduleDetailTop.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import TemplateList from '@/components/updates/TemplateList.vue';
import UserScopeTreeUserField from '@/components/UserScopeTreeUserField.vue';
import useDebounce from '@/composables/debounce';
import useRRuleTranslations from '@/composables/rrule-translations/translations';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import { DateTime } from 'luxon';
import { accessPolicyType, customFunc, routeName } from 'shared/constants.json';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { copy } from 'shared/lib/copy';
import { getStatus, getStatusType } from '@/lib/notification/notification';
import { isEqual } from 'lodash-es';
import { logCatch } from '@/lib/logger/logger';
import { notificationChildren } from '@/api/query/nebula/notification';
import { rrulestr } from 'rrule';

export default {
  name: 'ScheduleDetailPage',
  props: {
    scheduleId: {
      type: Number,
      required: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['recreated'],
  components: {
    ItemTitle,
    PageLayout,
    NotFoundPage,
    ScrollContainer,
    TemplateList,
    UserScopeTreeUserField,
    ParticipationChart,
    EditablePageHeader,
    ScheduleDetailTop,
    PropertyLabel,
    ReminderItem,
    ParticipantsTable,
    RRulePicker,
    ReminderList,
  },
  setup() {
    const { debounce } = useDebounce();
    const { updateSchedule, updateLoading, updateSingle, selectSingle } = useUpdateSchedules();
    const { rruleToText } = useRRuleTranslations();

    return { debounce, updateSchedule, updateSingle, selectSingle, updateLoading, rruleToText };
  },
  data() {
    return {
      loading: true,
      emojiAdded: false,
      showRecipients: false,
      pageHeaderKey: 0,
      recipientsCount: 0,
      recipients: null,
      initialValue: null,
      reminders: [],
      title: '',
      routeName,
      DateTime,
    };
  },
  computed: {
    icon() {
      return buildIconFromEntity(this.schedule);
    },
    filteredReminders() {
      return this.reminders.filter((r) => r.deletedAt === undefined);
    },
    notificationUnsure() {
      if (this.nextNotification === null || !this.schedule.isDirty) {
        return false;
      }
      const d = this.nextNotification.toJSDate();
      const n = new Date();
      return ((d - n) / (60 * 1000)) < 20;
    },
    nextNotification() {
      const rule = rrulestr(this.schedule.schedule);
      const now = (new Date());
      now.setMinutes(-now.getTimezoneOffset());
      const next = rule.after(now);
      if (next === null) {
        return null;
      }

      return DateTime.fromJSDate(next)
        .toUTC()
        .setZone('local', { keepLocalTime: true });
    },
    status() {
      return [
        {
          title: this.$t('notificationItem.paused'),
          value: 'paused',
          type: 'warning',
        },
        {
          title: this.$t('propertyConstants.active'),
          value: 'active',
          type: 'success',
        },
      ];
    },
    canEdit() {
      return [accessPolicyType.write, accessPolicyType.full].includes(this.schedule.accessRight);
    },
    numberOfRecipients() {
      if (typeof this.schedule.updateParticipationList === 'undefined') {
        return 0;
      }
      return this.schedule.updateParticipationList[0].numberOfRecipients;
    },
    chartData() {
      return this.schedule[customFunc.updateParticipationList].map((p) => ({
        x: Date.parse(p.time),
        y: Math.round((p.numberOfParticipants / p.numberOfRecipients) * 100),
        participation: this.amount(p),
      }));
    },
    rruleText() {
      try {
        const rule = rrulestr(this.schedule.schedule);
        return this.rruleToText(rule, { includeTime: true });
      } catch (e) {
        return '';
      }
    },
    schedule() {
      const schedule = this.selectSingle(this.scheduleId);
      if (schedule === undefined) {
        return {};
      }

      return schedule;
    },
    timeSeries() {
      const now = DateTime.local();
      return this.occurrences.reduce((res, next) => {
        const n = DateTime.fromISO(next.value);
        if (n.diff(now) > 0) {
          return res;
        }

        res.push(n.toISO());
        return res;
      }, [now.toISO()]);
    },
    occurrences() {
      try {
        const rule = rrulestr(this.schedule.schedule);
        let next = rule.after(DateTime.local().toJSDate());
        if (next === null) {
          next = DateTime.local().toJSDate();
        }

        const occurrences = rule.between(rule.options.dtstart, next, true)
          .slice(-13).map((t) => DateTime.fromJSDate(t)
            .toUTC()
            .setZone('local', { keepLocalTime: true }));

        if (occurrences.length === 1) {
          const n = rule.after(occurrences[0].plus({ days: 1 }).toJSDate(), false);
          if (n !== null) {
            return occurrences.map((t) => ({
              text: `${t.toLocaleString(DateTime.DATETIME_MED)} - ${DateTime.fromJSDate(n).minus({ minute: 1 }).toLocaleString(DateTime.DATETIME_MED)}`,
              value: t.toISO({ suppressMilliseconds: true }),
            }));
          }
          return occurrences.map((t) => ({
            text: `${t.toLocaleString(DateTime.DATETIME_MED)}`,
            value: t.toISO({ suppressMilliseconds: true }),
          }));
        }

        const filtered = occurrences.filter((_, index) => typeof occurrences[index + 1] !== 'undefined').slice(-12);

        const m = filtered.map((t, i) => ({
          text: `${t.toLocaleString(DateTime.DATETIME_MED)} - ${occurrences[i + 1].minus({ minute: 1 }).toLocaleString(DateTime.DATETIME_MED)}`,
          value: occurrences[i + 1].minus({ minutes: 1 }).toISO({ suppressMilliseconds: true }),
        }));

        m.reverse();
        return m;
      } catch (e) {
        return [];
      }
    },
  },
  methods: {
    buildIconFromEntity,
    isEmptyIcon,
    addEmoji() {
      this.emojiAdded = true;
    },
    amount(participation) {
      if (participation.numberOfRecipients === 0) {
        return '0/0';
      }
      return `${participation.numberOfParticipants}/${participation.numberOfRecipients}`;
    },
    cancel() {
      this.updateSingle(
        this.initialValue,
        { commitToRemote: false },
      );
      this.title = this.initialValue.title;
      this.pageHeaderKey += 1;
      this.reminders = copy(this.initialValue.reminders);
      this.recipients = copy(this.initialValue.recipients);
      this.recipientsCount = this.numberOfRecipients;
    },
    addReminder(reminder) {
      this.reminders.push(reminder);
      this.updateSingle({ uid: this.schedule.uid, isDirty: true }, { commitToRemote: false });
    },
    updateReminder(reminder, index) {
      this.reminders.splice(index, 1, reminder);
      this.updateSingle({ uid: this.schedule.uid, isDirty: true }, { commitToRemote: false });
    },
    deleteReminder(index) {
      if (Number.isNaN(this.reminders[index].uid)) {
        this.reminders.splice(index, 1);
        this.updateSingle({ uid: this.schedule.uid, isDirty: true }, { commitToRemote: false });
        return;
      }

      this.reminders.splice(index, 1, {
        ...this.reminders[index],
        deletedAt: DateTime.local().toISO(),
      });
      this.updateSingle({ uid: this.schedule.uid, isDirty: true }, { commitToRemote: false });
    },
    deleteTemplate(template) {
      if (this.schedule.updateTemplate.uid !== template.uid) {
        return;
      }
      this.store({ key: 'updateTemplate', value: null });
    },
    store({ key, value }) {
      this.updateSingle(
        { ...this.schedule, [key]: value, isDirty: true },
        { commitToRemote: false },
      );
    },
    changeStatus(status) {
      switch (status) {
        case 'paused':
          this.store({ key: 'activatedAt', value: null });
          break;
        case 'active':
          this.store({ key: 'activatedAt', value: DateTime.local().toISO() });
          break;
        default:
      }
    },
    changeTemplate(template) {
      this.store({ key: 'updateTemplate', value: template });
    },
    changeIcon(icon) {
      this.store({ key: 'icon', value: icon.value });
    },
    changeTitle(val) {
      this.title = val;
      const update = () => {
        this.store({ key: 'title', value: val });
      };

      this.debounce(update, 500);
    },
    changeSchedule(val) {
      this.store({ key: 'schedule', value: val });
    },
    updateRecipients({ userScopeTree, count }) {
      this.recipientsCount = count;
      if (!this.canEdit || isEqual(userScopeTree, this.recipients)) {
        return;
      }

      this.recipients = userScopeTree;
      this.updateSingle({ uid: this.schedule.uid, isDirty: true }, { commitToRemote: false });
    },
    getStatusType({ activatedAt, cancelledAt }) {
      return getStatusType({ activatedAt, cancelledAt, $t: (key) => this.$t(key) });
    },
    getStatus({ activatedAt, cancelledAt }) {
      return getStatus({ activatedAt, cancelledAt, $t: (key) => this.$t(key) });
    },
    get() {
      const scheduleAvailable = typeof this.schedule.schedule !== 'undefined';

      this.loading = true;
      this.selectSingle(
        this.scheduleId,
        { commitToRemote: true, attributes: notificationChildren(this.timeSeries) },
      ).then(() => {
        if (typeof this.schedule.uid === 'undefined') {
          return;
        }

        this.reminders = copy(this.schedule.reminders);
        this.recipients = copy(this.schedule.recipients);
        this.recipientsCount = this.schedule.recipientsList.length;
        this.initialValue = copy(this.schedule);
        this.title = this.schedule.title;

        if (scheduleAvailable) {
          return;
        }

        this.get();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.loading = false;
      });
    },
    save() {
      this.updateSchedule({ ...this.schedule, reminders: this.reminders, recipients: this.recipients }, this.timeSeries).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  watch: {
    scheduleId: {
      handler() {
        this.get();
      },
      immediate: true,
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/padding";

  $value-padding: .4rem 1.1rem;

  .schedule-detail-page {
    position: relative;

    ._content {
      ._participation-rate {
        margin-top: 5rem;

        ._title {
          margin-bottom: 1.2rem;
          font-weight: $font-weight-bold;
        }
      }

      ._status {
        display: inline-flex;
      }

      ._participants-list {
        margin-top: 5rem;
        margin-bottom: 8rem;
      }

      ._properties {
        ._prop {
          ._value {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: $value-padding;
            border: 1px solid transparent;
            border-radius: $default-border-radius;

            ._selected-emoji {
              margin-right: .6rem;
            }

            ._empty {
              color: $font-color-tertiary;
            }
          }

          &:not(.-disabled) {
            ._value {
              cursor: pointer;

              &:hover {
                background-color: $hover-color;
              }
            }
          }
        }
      }
    }

    &.-modal {
      height: calc(100vh - 14.4rem);
    }

    $footer-height: 6.5rem;

    ._footer {
      /* this overrides the z-index of the m-select>m-focusable within the participants-table component */
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: $footer-height;
      background-color: white;
      border-top: 1px solid $border-color;
      animation: fade-in .5s ease-in-out;

      @include layoutPaddingX();

      @keyframes fade-in {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      ._footer-inner {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        ._btn {
          margin-left: .6rem;
        }
      }
    }
  }

  ._overlay {
    border: 1px solid $input-border-color;

    ._top {
      display: flex;
      align-items: center;
      padding: $value-padding;
      background-color: map_get($grey, 'lighten-4');
      border-bottom: 1px solid $input-border-color;
      border-top-left-radius: $default-border-radius;
      border-top-right-radius: $default-border-radius;

      ._is-empty {
        color: $font-color-tertiary;
      }
    }

    ._overlay-content {
      padding: $value-padding;

      &.-recipients {
        padding: 0;

        &.-not-mobile {
          max-width: 57rem;
        }
      }
    }

    &.-schedule {
      ._hint {
        &.-warning {
          color: $warning-color;

          ._icon {
            display: inline-block;
            margin-right: .4rem;
          }
        }
      }
    }
  }
</style>

<template>
  <full-screen-spinner v-if="firstLoadingSvc.loading.value && updateTemplates.length === 0" />
  <template v-else>
    <m-content
      padding-x="layout"
      class="_content-header"
    >
      <div class="_header">
        <div class="_left">
          {{ $t('updateTemplates.all') }}
        </div>
        <div class="_right">
          <m-btn
            v-if="canCreate"
            color="primary"
            small
            :loading="createLoading"
            @click="createTemplate"
          >
            {{ $t('general.new') }}
          </m-btn>
        </div>
      </div>
      <m-divider small />
    </m-content>
    <div class="update-templates">
      <m-content
        padding-x="layout"
      >
        <div class="_content">
          <template-item
            v-for="template in updateTemplates"
            :key="template.uid"
            :template="template"
            @edit="showEdit"
          />
          <div
            v-if="canCreate"
            class="_new-btn"
            @click="createTemplate"
          >
            <div class="_icon">
              <m-icon
                :type="createLoading ? 'loading' : 'plus'"
                :spin="createLoading"
              />
            </div>
            <div class="_text">
              {{ $t('general.new') }}
            </div>
          </div>
        </div>
        <m-dialog
          :value="showModal"
          :max-width="$modalSizes.xl"
          hide-footer
          no-padding
          keep-height
          hide-header
          top="7rem"
          @close="closeEdit"
        >
          <update-template-editor
            :id="selectedTemplateId"
            @close="closeEdit"
          />
        </m-dialog>
      </m-content>
    </div>
  </template>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import TemplateItem from '@/components/updates/TemplateItem.vue';
import UpdateTemplateEditor from '@/components/updates/UpdateTemplateEditor.vue';
import useFirstLoading from '@/composables/first-loading';
import useUpdateTemplates from '@/composables/update-templates/update-templates';

export default {
  name: 'UpdateTemplates',
  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FullScreenSpinner,
    TemplateItem,
    UpdateTemplateEditor,
  },
  setup() {
    const firstLoadingSvc = useFirstLoading();
    const updateTemplatesSvc = useUpdateTemplates();

    return {
      firstLoadingSvc,
      updateTemplates: updateTemplatesSvc.updateTemplates,
      fetchUpdateTemplates: updateTemplatesSvc.fetchUpdateTemplates,
      templatesLoading: updateTemplatesSvc.fetchUpdateTemplatesLoading,
      createUpdateTemplate: updateTemplatesSvc.createUpdateTemplate,
      createLoading: updateTemplatesSvc.createUpdateTemplateLoading,
    };
  },
  computed: {
    selectedTemplateId() {
      return parseInt(this.$route.query.templateId, 10);
    },
    showModal() {
      return typeof this.$route.query.templateId !== 'undefined';
    },
  },
  methods: {
    closeEdit() {
      const query = { ...this.$route.query };
      delete query.templateId;
      this.$router.push({ query });
    },
    showEdit(template) {
      this.$router.push({ query: { templateId: template.uid } });
    },
    createTemplate() {
      if (this.createLoading) {
        return;
      }

      this.createUpdateTemplate({
        title: '',
        icon: '',
        template: null,
      }).then((updateTemplate) => {
        this.showEdit(updateTemplate);
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  watch: {
    templatesLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.firstLoadingSvc.finishLoading();
      }
    },
  },
  created() {
    this.firstLoadingSvc.startLoading();
    this.fetchUpdateTemplates();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._content-header {
    position: sticky;
    left: 0;
  }

  .update-templates {
    ._content {
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
      grid-auto-rows: 1fr;
      gap: 1.6rem;
      padding-bottom: 5rem;

      ._new-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 26rem;
        color: $font-color-tertiary;
        cursor: pointer;
        border: 1px solid map_get($grey, 'lighten-3');
        border-radius: $default-border-radius;

        ._icon {
          margin-right: .4rem;
        }

        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }

  ._header {
    display: flex;

    ._left {
      display: flex;
      align-items: center;
      padding-left: 1.2rem;
      font-weight: $font-weight-semibold;
    }

    ._right {
      margin-left: auto;
    }
  }
</style>

import { UID } from 'shared/api/query/constants';
import {
  gridPageTileChart as gridPageTileChartConfig,
  gridPageTile as gridPageTileConfig,
} from 'shared/api/query/configs.json';

export const gridPageTileChartChildren = [
  { attr: UID },
  { attr: gridPageTileChartConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
];

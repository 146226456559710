<template>
  <div
    class="access-group-form"
  >
    <m-content
      padding
      class="_form-content"
    >
      <m-form-item
        :label="$t('accessGroupForm.groupNameLabel')"
      >
        <m-text-field
          v-if="isDefaultGroup"
          :value="$t('accessGroupForm.defaultGroupTitle')"
          disabled
        />
        <m-text-field
          v-else
          v-model:value="accessGroup.name"
          auto-focus
        />
      </m-form-item>
      <div class="_section">
        {{ $t('accessGroupForm.memberTitle') }}
      </div>
      <m-divider xs />
      <div class="_member-section">
        <m-alert
          v-if="isDefaultGroup"
          type="info"
          class="_default"
        >
          {{ $t('accessGroupForm.defaultGroupDescription') }}
        </m-alert>
        <access-policy-for-access-groups
          v-else
          v-model:value="accessGroup.accessPolicy"
        />
      </div>
      <div class="_section">
        {{ $t('accessGroupForm.accountTitle') }}
      </div>
      <m-divider xs />
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.accountWriteAccess"
          :label="$t('accessGroupForm.accountWriteAccess')"
          color="primary"
          :disabled="isAdminGroup"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.accessGroupWriteAccess"
          :label="$t('accessGroupForm.accessGroupWriteAccessLabel')"
          color="primary"
          :disabled="isAdminGroup"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.propertyWriteAccess"
          :label="$t('accessGroupForm.propertyWriteAccessLabel')"
          color="primary"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.spaceWriteAccess"
          :label="$t('accessGroupForm.spaceWriteAccessLabel')"
          color="primary"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.userWriteAccess"
          :label="$t('accessGroupForm.userWriteAccessLabel')"
          color="primary"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.publicSavedViewAccess"
          :label="$t('accessGroupForm.publicSavedViewAccessLabel')"
          color="primary"
        />
      </m-form-item>
      <template v-if="showForms">
        <div class="_section">
          {{ $t('accessGroupForm.formsTitle') }}
        </div>
        <m-divider xs />
        <m-form-item>
          <m-switch
            v-model:value="accessGroup.formWriteAccess"
            :label="$t('accessGroupForm.formWriteAccessLabel')"
            color="primary"
          />
        </m-form-item>
        <m-form-item>
          <m-switch
            v-model:value="accessGroup.formTemplateWriteAccess"
            :label="$t('accessGroupForm.formTemplateWriteAccessLabel')"
            color="primary"
          />
        </m-form-item>
      </template>
      <template v-if="showGoals">
        <div class="_section">
          {{ $t('accessGroupForm.goalsTitle') }}
        </div>
        <m-divider xs />
        <m-form-item>
          <m-switch
            v-model:value="accessGroup.goalCycleWriteAccess"
            :label="$t('accessGroupForm.goalCycleWriteAccessLabel')"
            color="primary"
          />
        </m-form-item>
        <m-form-item>
          <m-switch
            v-model:value="accessGroup.goalSuperAccess"
            :label="$t('accessGroupForm.goalSuperAccessLabel')"
            color="primary"
          />
        </m-form-item>
        <m-form-item>
          <m-switch
            v-model:value="accessGroup.createGoalAccess"
            :label="$t('accessGroupForm.createGoalAccessLabel')"
            color="primary"
          />
        </m-form-item>
        <m-tooltip>
          <template #title>
            <div>
              {{ $t('accessGroupForm.referenceForeignEntityLabel.tooltip') }}
            </div>
          </template>
          <div>
            <m-switch
              v-model:value="accessGroup.foreignEntityReferenceAccess"
              :label="$t('accessGroupForm.referenceForeignEntityLabel.label')"
              color="primary"
            />
          </div>
        </m-tooltip>
      </template>
      <template v-if="showUpdates">
        <div class="_section">
          {{ $t('accessGroupForm.updatesTitle') }}
        </div>
        <m-divider xs />
        <m-form-item>
          <m-switch
            v-model:value="accessGroup.updateAdminAccess"
            :label="$t('accessGroupForm.updateAdminAccess')"
            color="primary"
          />
        </m-form-item>
      </template>
      <template v-if="account.accountSettings[accountSettingsConfig.edges.usesInstantFeedback]">
        <div class="_section">
          {{ $t('navigation.instantFeedback') }}
        </div>
        <m-divider xs />
        <m-form-item>
          <m-switch
            v-model:value="accessGroup[accessGroupConfig.edges.instantFeedbackTemplateAccess]"
            :label="$t('accessGroupForm.instantFeedbackTemplateAccess')"
            color="primary"
          />
        </m-form-item>
      </template>
      <div class="_section">
        {{ $t('navigation.dashboard') }}
      </div>
      <m-divider xs />
      <m-form-item>
        <m-switch
          v-model:value="accessGroup.goalDashboardViewAccess"
          :label="$t('accessGroupForm.goalDashboardViewAccessLabel')"
          color="primary"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup[accessGroupConfig.edges.createDashboardAccess]"
          :label="createDashboardLabel"
          color="primary"
        />
      </m-form-item>
      <m-form-item>
        <m-switch
          v-model:value="accessGroup[accessGroupConfig.edges.dashboardSuperAccess]"
          :label="$t('accessGroupForm.dashboardSuperAccess')"
          color="primary"
        />
      </m-form-item>
    </m-content>
    <m-default-form-actions
      :loading="loading"
      :clickable="isValid"
      show-cancel
      has-border
      @submit="submit"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import AccessPolicyForAccessGroups from '@/components/AccessPolicyForAccessGroups.vue';
import useAccess from '@/composables/access/access';
import useAccessGroup from '@/composables/access/access-group';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import {
  accessGroup as accessGroupConfig,
  accountSettings as accountSettingsConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType, moduleFlag, routeName } from 'shared/constants.json';
import { copy } from 'shared/lib/copy';
import { experimentFlag } from 'shared/experiments.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'AccessGroupForm',
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['created', 'updated', 'cancel'],
  components: { AccessPolicyForAccessGroups },
  setup() {
    const { accountHasFeature } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const { updateSingle, createSingle } = useAccessGroup();
    return {
      accountHasFeature,
      account: loggedInUserAccount,
      loggedInUser,
      updateSingle,
      createSingle,
    };
  },
  data() {
    return {
      loading: false,
      accessGroup: null,
      accountSettingsConfig,
      accessGroupConfig,
      routeName,
    };
  },
  computed: {
    createDashboardLabel() {
      if (this.accountHasFeature([experimentFlag.scheduleReport])) {
        return this.$t('accessGroupForm.writeDashboardsAndReports');
      }
      return this.$t('accessGroupForm.createDashboardAccess');
    },
    showGoals() {
      return this.accountHasFeature([moduleFlag.goals]);
    },
    showUpdates() {
      return this.accountHasFeature([moduleFlag.updates]);
    },
    showForms() {
      return this.accountHasFeature([moduleFlag.surveys]);
    },
    isCreate() {
      return this.entity === null;
    },
    isDefaultGroup() {
      if (this.isCreate) {
        return false;
      }
      return this.entity.isDefaultGroup;
    },
    isAdminGroup() {
      if (this.isCreate) {
        return false;
      }
      return this.entity.isAdminGroup;
    },
    hasScopes() {
      return this.accessGroup.accessPolicy.scopes.filter((s) => typeof s.deletedAt === 'undefined').length > 0;
    },
    isValid() {
      return this.accessGroup.name !== '';
    },
  },
  methods: {
    mutate(entity) {
      this.loading = true;
      if (entity.uid === undefined) {
        this.createSingle(entity).then((accessGroup) => {
          this.$emit('created', { accessGroup });
          this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        })).finally(() => {
          this.loading = false;
        });
        return;
      }
      this.updateSingle(entity, { optimistic: false }).then((accessGroup) => {
        this.$emit('updated', { accessGroup });
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.loading = false;
      });
    },
    init() {
      if (this.entity !== null) {
        this.accessGroup = copy(this.entity);
        return;
      }

      this.accessGroup = {
        account: this.account,
        creator: this.loggedInUser,
        name: '',
        accessPolicy: {
          account: this.account,
          accountAccess: accessPolicyType.disabled,
          scopes: [],
        },
        accountWriteAccess: false,
        accessGroupWriteAccess: false,
        formWriteAccess: false,
        formTemplateWriteAccess: false,
        userWriteAccess: false,
        propertyWriteAccess: false,
        spaceWriteAccess: false,
        goalCycleWriteAccess: false,
      };
    },
    cancel() {
      this.init();
      this.$emit('cancel');
    },
    submit() {
      if (this.isAdminGroup && !this.hasScopes) {
        this.$confirm({
          title: this.$t('accessGroupForm.adminWarning'),
          okText: this.$t('general.okay'),
          maskClosable: true,
          okType: 'warning',
          hideCancel: true,
        });
        return;
      }
      this.mutate(this.accessGroup);
    },
  },
  watch: {
    entity() {
      this.init();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-group-form {
    ._form-content {
      height: calc(100vh - 13rem);
      overflow: auto;
    }

    .ant-row {
      margin-bottom: 1.2rem;
    }

    ._section {
      margin: 2rem 0 .8rem;
      font-size: $font-size-5;
      font-weight: $font-weight-semibold;
    }
  }

</style>

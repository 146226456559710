import intersection from 'lodash-es/intersection';
import { computed, ref, watch } from 'vue';
import { isEqual } from 'lodash-es';
import { shallowCopy } from 'shared/lib/copy';
import { unique } from 'shared/lib/array/array';

const manualSelectMode = 'manual-select';
const selectAllMode = 'select-all';

export const SELECTION_STATE_ALL_SELECTED = 'all_selected';
export const SELECTION_STATE_NONE_SELECTED = 'none_selected';
export const SELECTION_STATE_PARTIALLY_SELECTED = 'partially_selected';

export default function useSelect(list, repo, disabledIds = [], disabledCondition = () => true, unselectIfNotVisible = false, singleSelect = false, initValue = []) {
  const mode = ref('manual-select');
  const selectedIds = ref(shallowCopy(initValue));

  const isDisabled = (id) => disabledIds.includes(id) || disabledCondition(repo.selectSingle(id));

  const selectAll = (forceSelect = false) => {
    if (singleSelect) {
      return;
    }
    const res = unique(list.value)
      .filter((id) => !isDisabled(id));
    if (!forceSelect && isEqual(res, selectedIds.value)) {
      mode.value = manualSelectMode;
      selectedIds.value = [];
      return;
    }
    mode.value = selectAllMode;
    selectedIds.value = res;
  };

  const selectItem = (item, append = false) => {
    mode.value = manualSelectMode;
    if (isDisabled(item.uid)) {
      return;
    }

    const index = selectedIds.value.indexOf(item.uid);
    if (index !== -1) {
      selectedIds.value.splice(index, 1);
      return;
    }

    if (singleSelect || !append) {
      selectedIds.value = [item.uid];
      return;
    }

    const listIndex = list.value.find((id) => id === item.uid);
    const insertAt = selectedIds.value.findIndex((id) => {
      const i = list.value.find((g) => g.uid === id);
      return i > listIndex;
    });

    if (insertAt === -1) {
      selectedIds.value = [...selectedIds.value, item.uid];
      return;
    }

    selectedIds.value.splice(insertAt, 0, item.uid);
  };

  const unselectAll = () => {
    selectedIds.value = [];
    mode.value = manualSelectMode;
  };

  watch(selectedIds, (newVal, oldVal) => {
    if (newVal.length === 0) {
      mode.value = manualSelectMode;
    }
    if (isEqual(newVal, oldVal)) {
      return;
    }
    selectedIds.value = unique(newVal);
  });

  watch(list, () => {
    if (mode.value !== selectAllMode) {
      return;
    }

    const res = unique(list.value)
      .filter((id) => !isDisabled(id));
    if (unselectIfNotVisible) {
      if (isEqual(res, selectedIds.value)) {
        return;
      }

      selectedIds.value = res;
      return;
    }
    if (intersection(res, selectedIds.value).length === res.length) {
      return;
    }

    selectedIds.value = res;
  });

  const selectionState = computed(() => {
    if (selectedIds.value.length === 0) {
      return SELECTION_STATE_NONE_SELECTED;
    }

    if (mode.value === selectAllMode || intersection(list.value, selectedIds.value).length === list.value.length) {
      return SELECTION_STATE_ALL_SELECTED;
    }

    return SELECTION_STATE_PARTIALLY_SELECTED;
  });

  return {
    selectAll,
    selectedIds,
    selectItem,
    selectionState,
    unselectAll,
  };
}

<template>
  <div>
    <browser-not-supported v-if="browserNotSupported" />
    <div
      v-else
      :class="['app', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
    >
      <m-content
        v-if="appMode.includes(appModes.requiresMinorUpdate)"
        :padding-y="6"
        :padding-x="9"
        class="_version-note"
        @click="reload"
      >
        <div class="_icon">
          <m-icon type="start" />
        </div>
        <div class="_text">
          {{ $t('app.newVersion') }}
        </div>
      </m-content>
      <m-content
        v-if="appMode.includes(appModes.foreignLogin)"
        :padding-y="3"
        :padding-x="9"
        class="_foreign-login"
      >
        <div class="_icon">
          <m-icon type="warning" />
        </div>
        <div class="_text">
          {{ $t('app.foreignLogin') }}
        </div>
      </m-content>
      <full-screen-spinner v-if="appMode.includes(appModes.loading)" />
      <template v-if="appMode.includes(appModes.login)">
        <plain-layout>
          <login
            v-if="isBrowser"
            @session-established="initializeUser"
          />
          <m-s-login
            v-if="isMsTeams"
            @session-established="initializeUser"
          />
        </plain-layout>
      </template>
      <maintenance v-if="appMode.includes(appModes.maintenance)" />
      <template v-if="appMode.includes(appModes.app)">
        <plain-layout
          v-if="$route.meta.layout === plain"
          :class="['_plain', isMsTeams ? '-ms-teams' : '']"
        >
          <router-view />
        </plain-layout>
        <main-layout v-else-if="authenticatedRoute && isExpired">
          <info-page
            :heading="$t('app.accountExpired.heading')"
            :text="$t('app.accountExpired.explanation')"
          />
        </main-layout>
        <main-layout v-else-if="authenticatedRoute && isDeactivatedAccount">
          <info-page
            :heading="$t('app.accountInactive.heading')"
            :text="$t('app.accountInactive.explanation')"
          />
        </main-layout>
        <main-layout v-else-if="authenticatedRoute && !hasModuleFlags">
          <info-page
            :heading="$t('app.featureNotAvailable.heading')"
            :text="$t('app.featureNotAvailable.explanation')"
            :button-text="$t('app.featureNotAvailable.button')"
          />
        </main-layout>
        <main-layout v-else-if="authenticatedRoute && !hasAccess">
          <info-page
            :heading="$t('app.noAccess.heading')"
            :text="$t('app.noAccess.explanation')"
            :button-text="$t('app.noAccess.button')"
          />
        </main-layout>
        <main-layout v-else-if="$route.meta.layout === main">
          <router-view />
        </main-layout>
        <settings-layout v-else-if="$route.meta.layout === settings">
          <router-view />
        </settings-layout>
        <m-dialog
          :value="showTrialExpired && !trialExpiredDiscarded"
          hide-footer
          center
          max-width="40rem"
          @close="trialExpiredDiscarded = true"
        >
          <upgrade-form
            :title="$t('upgradeForm.subscriptionExpired')"
            expired
            :description="$t('upgradeForm.subscriptionExpiredText')"
            @close="trialExpiredDiscarded = true"
          />
        </m-dialog>
        <goal-details-dialog />
        <template v-if="authenticatedRoute">
          <support-form-dialog />
          <sales-form-dialog />
          <release-notes-dialog />
          <company-info-dialog />
          <product-survey-dialog
            v-if="false && loggedInUser.productSurveyStatus === null && !isTrial && $route.name !== onboarding && !foreignLogin"
            :user="loggedInUser"
          />
          <resource-center-drawer />
          <invite-users-dialog />
          <loom-video-dialog />
        </template>
        <m-dialog
          :value="appMode.includes(appModes.requiresMajorUpdate)"
          keep-open-on-mask-click
          hide-footer
          max-width="40rem"
          center
        >
          <div class="_version-update">
            <div class="_emoji">
              🌟
            </div>
            <div class="_text">
              {{ $t('app.newVersion') }}
            </div>
            <div class="_action">
              <m-btn
                color="primary"
                block
                @click="reload"
              >
                {{ $t('app.reload') }}
              </m-btn>
            </div>
          </div>
        </m-dialog>
      </template>
      <teleport to="#confirm">
        <m-confirm
          ref="confirm"
          :event-bus="EventBus"
        />
      </teleport>
      <teleport to="#snackbar">
        <m-snackbar
          :event-bus="EventBus"
        />
      </teleport>
      <m-feature-gate
        :event-bus="EventBus"
      />
      <slide-show />
    </div>
  </div>
</template>

<script>
import BrowserNotSupported from '@/components/BrowserNotSupported.vue';
import CompanyInfoDialog from '@/components/navigation/CompanyInfoDialog.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GoalDetailsDialog from '@/components/goal/GoalDetailsDialog.vue';
import InfoPage from '@/components/InfoPage.vue';
import InviteUsersDialog from '@/components/navigation/InviteUsersDialog.vue';
import Login from '@/views/Login.vue';
import LoomVideoDialog from '@/components/LoomVideoDialog.vue';
import MSLogin from '@/views/MSLogin.vue';
import MainLayout from '@/components/layout/MainLayout.vue';
import Maintenance from '@/components/Maintenance.vue';
import PlainLayout from '@/components/layout/PlainLayout.vue';
import ProductSurveyDialog from '@/components/navigation/ProductSurveyDialog.vue';
import ReleaseNotesDialog from '@/release-notes/ReleaseNotesDialog.vue';
import ResourceCenterDrawer from '@/components/navigation/ResourceCenterDrawer.vue';
import SalesFormDialog from '@/components/plans/SalesFormDialog.vue';
import SettingsLayout from '@/components/layout/SettingsLayout.vue';
import SlideShow from '@/components/onboarding/SlideShow.vue';
import SupportFormDialog from '@/components/plans/SupportFormDialog.vue';
import UpgradeForm from '@/components/plans/UpgradeForm.vue';
import useAccess from '@/composables/access/access';
import useAppMode from '@/composables/app-mode';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useLogin from '@/composables/user/login';
import useMSTeams from '@/composables/msteams';
import usePageVisits from '@/composables/page-visits';
import { DateTime } from 'luxon';
import { EventBus } from '@/lib/event-bus';
import { FORM_DETAILS } from '@/route-names';
import { MAIN_LAYOUT, PLAIN, SETTINGS_LAYOUT } from '@/lib/layout';
import { accountStatus, productPlan, routeName } from 'shared/constants.json';
import { APP_MODES as appModes } from '@/lib/constants';
import { changeLocale } from '@/lang';
import { mapActions, mapState } from 'vuex';
import { nextTick } from 'vue';

export default {
  name: 'App',
  components: {
    GoalDetailsDialog,
    SlideShow,
    LoomVideoDialog,
    InviteUsersDialog,
    ProductSurveyDialog,
    CompanyInfoDialog,
    SalesFormDialog,
    SupportFormDialog,
    ReleaseNotesDialog,
    ResourceCenterDrawer,
    UpgradeForm,
    InfoPage,
    SettingsLayout,
    FullScreenSpinner,
    MainLayout,
    PlainLayout,
    BrowserNotSupported,
    Login,
    MSLogin,
    Maintenance,
  },
  setup() {
    const { accountHasFeature, userHasRight } = useAccess();
    const pageVisitsService = usePageVisits();
    const { initializeUser, loading } = useLogin(pageVisitsService);
    const { attemptLogin, initializeMSTeams, isBrowser, isMsTeams } = useMSTeams();
    const { appMode } = useAppMode(loading);
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      createPageVisit: pageVisitsService.createPageVisit,
      accountHasFeature,
      userHasRight,
      initializeUser,
      loading,
      loggedInUser,
      account: loggedInUserAccount,
      userLang,

      isBrowser,
      isMsTeams,
      attemptLogin,
      initializeMSTeams,

      appMode,
      appModes,
    };
  },
  data() {
    return {
      plain: PLAIN,
      main: MAIN_LAYOUT,
      settings: SETTINGS_LAYOUT,
      EventBus,
      msTeamsContext: null,
      trialExpiredDiscarded: false,
      onboarding: routeName.onboarding,
    };
  },
  computed: {
    ...mapState({
      foreignLogin: (state) => state.foreignLogin,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    browserNotSupported() {
      return typeof [].includes === 'undefined';
    },
    isExpired() {
      if (this.account.accountSettings.expiresAt === null) {
        return false;
      }
      return DateTime.fromISO(this.account.accountSettings.expiresAt).diff(DateTime.local()).milliseconds < 0;
    },
    showTrialExpired() {
      if (this.account === null || this.account.accountSettings === null) {
        return false;
      }
      return this.account.accountSettings.planId === productPlan.trial && this.isExpired;
    },
    isTrial() {
      return this.account.accountSettings.planId === productPlan.trial;
    },
    isDeactivatedAccount() {
      return this.account.accountSettings.status === accountStatus.paused;
    },
    hasAccess() {
      return this.hasAccessGroupFlags && this.hasModuleFlags;
    },
    hasAccessGroupFlags() {
      if (typeof this.$route.meta.accessGroupFlags === 'undefined') {
        return true;
      }
      return this.userHasRight(this.$route.meta.accessGroupFlags);
    },
    hasModuleFlags() {
      if (typeof this.$route.meta.flags === 'undefined') {
        return true;
      }
      return this.accountHasFeature(this.$route.meta.flags);
    },
    authenticatedRoute() {
      return !this.$route.meta.noAuth;
    },
    publicRoute() {
      return !!this.$route.meta.public;
    },
  },
  methods: {
    ...mapActions({ logoutRoutine: 'logout' }),
    reload() {
      window.location.reload();
    },
    logout({
      cleanup = (() => {
      }),
    } = {
      cleanup: (() => {
      }),
    }) {
      this.$store.commit('USER_NOT_LOGGED_IN');
      nextTick(() => {
        this.logoutRoutine().then(() => {
          cleanup();
        });
      });
    },
  },
  watch: {
    appMode(val) {
      if (this.publicRoute && (val.includes(appModes.requiresMajorUpdate) || val.includes(appModes.requiresMinorUpdate))) {
        window.location.href = `${window.location.pathname}?refresh=${new Date().getTime()}`;
      }
    },
    loggedInUser(val) {
      if (val !== null) {
        changeLocale(this.userLang, this);
      }
    },
    $route(val) {
      if (this.loggedInUser === null || typeof this.loggedInUser.uid === 'undefined' || this.foreignLogin) {
        return;
      }

      if (!this.isLoggedIn) {
        return;
      }

      if (this.loading) {
        return;
      }

      this.createPageVisit({
        user: { uid: this.loggedInUser.uid },
        routeName: val.name,
        params: val.params,
        query: val.query,
      });
    },
  },
  created() {
    EventBus.$on('logout', (args) => {
      this.logout(args);
    });

    if (this.$route.name === FORM_DETAILS) {
      this.loading = false;
      return;
    }

    if ([routeName.teamsAuth, routeName.publishedView, routeName.oauthRedirect].includes(this.$route.name)) {
      this.loading = false;
      return;
    }

    const userInitializeResponse = this.initializeUser();
    const initializeTeamResponse = this.initializeMSTeams().catch(() => {});

    userInitializeResponse.then((user) => {
      if (user !== null) {
        return;
      }

      initializeTeamResponse.then((context) => {
        if (context === undefined || context === null) {
          return;
        }

        this.attemptLogin(context).then(() => {
          this.initializeUser();
        });
      });
    });
  },
};
</script>

<style lang="scss" type="text/scss">
  @import "assets/scss/index";

  #app {
    height: 100%;

    ._version-note {
      position: fixed;
      bottom: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      color: white;
      cursor: pointer;
      background-color: $primary-color;

      ._icon {
        margin-right: 1rem;
        margin-bottom: .1rem;
      }
    }

    ._foreign-login {
      position: fixed;
      bottom: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      color: map_get($yellow, 'darken-4');
      background-color: $warning-color;

      ._icon {
        margin-right: 1rem;
        margin-bottom: .1rem;
      }
    }

    ._plain {
      &.-ms-teams {
        .scroll-container {
          @media (min-width: $screen-size-md) {
            overflow-x: hidden;
          }
        }
      }

    }
  }

  ._version-update {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ._emoji {
      margin-bottom: 1rem;
      font-size: 7rem;
    }

    ._text {
      margin-bottom: 2rem;
      font-size: $font-size-6;
      text-align: center;
    }

    ._action {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
</style>

<template>
  <page-layout
    :class="['updates-list-others', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <template v-if="!$store.state.breakpoint.smAndDown">
            <favorite-button
              :title-suggestion="title"
            />
          </template>
          <m-dropdown
            v-else
            :title="$t('general.actions')"
          >
            <m-btn
              fab
              icon="ellipsis"
              hide-border
            />
            <template #overlay>
              <m-card
                list
                no-padding
              >
                <favorite-button
                  :title-suggestion="title"
                />
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :key="title"
          :title="title"
          disabled
          size="small"
        />
      </m-content>
      <update-schedules
        v-if="$route.name === updateSchedules"
      />
      <update-templates
        v-if="$route.name === updateTemplates"
        :can-create="hasSetupAccess"
      />
    </scroll-container>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import UpdateSchedules from '@/components/update-schedules/UpdateSchedules.vue';
import UpdateTemplates from '@/components/updates/UpdateTemplates.vue';
import useAccess from '@/composables/access/access';
import { accessGroupFlag, routeName } from 'shared/constants.json';
import { buildIcon } from 'shared/lib/icon';

export default {
  name: 'UpdatesList',
  components: {
    ScrollContainer,
    PageTopBar,
    EditablePageHeader,
    PageLayout,
    FavoriteButton,
    UpdateSchedules,
    UpdateTemplates,
  },
  setup() {
    const { userHasRight } = useAccess();

    return { userHasRight };
  },
  data() {
    return {
      updateSchedules: routeName.updateSchedules,
      updateTemplates: routeName.updateTemplates,
    };
  },
  computed: {
    hasSetupAccess() {
      return this.userHasRight([accessGroupFlag.updateAdminAccess]);
    },
    title() {
      return this.breadcrumbs[this.breadcrumbs.length - 1].title;
    },
    breadcrumbs() {
      const currentItem = () => {
        if (this.$route.name === routeName.updateSchedules) {
          return {
            title: this.$t('updatesList.schedules'),
            disabled: true,
          };
        }
        if (this.$route.name === routeName.updateTemplates) {
          return {
            title: this.$t('updatesList.templates'),
            disabled: true,
          };
        }
        return null;
      };
      return [
        {
          title: this.$t('navigation.updatesExplorer'),
          disabled: true,
          icons: [{ value: buildIcon('History_dark_grey') }],
        },
        currentItem(),
      ];
    },
  },
  methods: { buildIcon },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .updates-list-others {
    min-height: 100vh;
    overflow: auto;

    ._layout {
      position: sticky;
      left: 0;
    }

    ._tabs-wrapper {
      position: sticky;
      left: 0;
      margin: 4rem 0 3rem;
    }

    &.-mobile {
      width: 100%;
      max-width: 100vw;
      height: 100vh;
      overflow: auto;

      ._layout {
        position: sticky;
        top: 0;
        z-index: 9;
        background-color: white;
      }
    }
  }
</style>

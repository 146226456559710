<template>
  <div class="add-to-grid-editor">
    <div class="_label">
      {{ $t('addToGridEditor.selectPage') }}
    </div>
    <div class="_item">
      <m-select
        v-model:value="gridPage"
        :items="editablePages"
        value-key="uid"
        item-text="title"
        return-object
        clearable
        show-search
        hide-selected-values
        full-width
        match-trigger-width
        auto-focus
      />
    </div>
    <div class="_actions">
      <m-btn
        class="_btn"
        hide-border
        small
        @click="$emit('close')"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="primary"
        class="_btn"
        small
        :disabled="loading || !isValid"
        :loading="loading"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import useGridPage from '@/composables/grid-page/grid-page';
import useSnackBar from '@/composables/snackbar';
import useViewNavigator from '@/composables/saved-views/navigator';
import { accessPolicyType, routeName } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'AddToGridEditor',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'edited'],
  setup() {
    const snackbar = useSnackBar();
    const { linkToRoute } = useViewNavigator();
    const { gridPages, addSingleGoalTiles } = useGridPage();
    return {
      snackbar,
      linkToRoute,
      gridPages,
      addSingleGoalTiles,
    };
  },
  data() {
    return {
      loading: false,
      gridPage: null,
    };
  },
  computed: {
    editablePages() {
      return this.gridPages.filter((e) => [accessPolicyType.full, accessPolicyType.write].includes(e.accessRight)).map((e) => ({ ...e, icon: buildIconFromEntity(e) }));
    },
    isValid() {
      return this.gridPage !== null;
    },
  },
  methods: {
    save() {
      if (!this.isValid) {
        return;
      }

      this.loading = true;
      const { $router, linkToRoute } = this;

      const singleGoalTiles = this.items.map((goal) => ({ goal: { uid: goal.uid } }));
      const resp = this.addSingleGoalTiles(singleGoalTiles, this.gridPage);
      resp.then(() => {
        this.snackbar.success(this.$t('success.added'), {
          action: {
            title: this.$t('addToGridEditor.successAction'),
            handler: () => {
              $router.push(linkToRoute('', routeName.dashboardDetails, undefined, undefined, this.gridPage.uid).to);
            },
          },
        });
        this.$emit('close');
        this.$emit('edited');
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.add-to-grid-editor {
  min-width: 25rem;

  ._label {
    margin-bottom: .2rem;
    font-size: $font-size-4;
    color: $font-color-secondary;
  }

  ._item {
    margin-bottom: 1rem;
  }

  ._actions {
    display: flex;
    justify-content: flex-end;

    ._btn {
      margin-left: .4rem;
    }
  }
}
</style>

/* eslint-disable max-len */
export default {
  error: {
    default: 'Es ist ein Fehler aufgetreten',
    unauthorized: 'Dir fehlen Zugriffsrechte um diese Seite zu laden',
    unauthorizedAction: 'Dir fehlen Zugriffsrechte um diese Aktion durchzuführen',
    userExists: 'User already exists',
    sessionExpired: 'Your session has expired. Please login again.',
    emailNotRegistered: 'Diese E-Mail ist nicht registriert.',
    emailNotMatching: 'Die Email stimmt nicht überein.',
    duringCopying: 'Fehler während dem Kopieren',
    emailAlreadyExists: 'Diese E-Mail ist bereits in einem anderen Account registriert. Um die Person hinzuzufügen, muss sie aus dem anderen Account entfernt werden. Unser Kundenservice kann dich hierbei unterstützen.',
    invalidDateFormat: 'Datum ist nicht valide',
    invalidDateRange: 'Zeitraum ist nicht valide',
    dateOutOfRange: 'Zeitpunkt liegt außerhalb des erlaubten Zeitraums',
    notAnImage: 'Es sind nur Bilder erlaubt.',
    pdfExport: 'PDF-Export ist fehlgeschlagen. Bitte versuche es erneut.',
  },
  success: {
    created: 'Erfolgreich erstellt',
    updated: 'Erfolgreich geändert',
    added: 'Erfolgreich hinzugefügt',
    deleted: 'Erfolgreich gelöscht',
    archived: 'Erfolgreich archiviert',
    restored: 'Erfolgreich wiederhergestellt',
    movedToTrash: 'In den Papierkorb verschoben',
    saved: 'Erfolgreich gespeichert',
    duplicated: 'Erfolgreich dupliziert',
    copied: 'Erfolgreich kopiert',
    sent: 'Erfolgreich gesendet',
  },
  general: {
    none: 'Keine',
    cancel: 'Abbrechen',
    okay: 'Okay',
    archive: 'Archivieren',
    delete: 'Löschen',
    deletePermanently: 'Endgültig löschen',
    restore: 'Wiederherstellen',
    search: 'Suchen',
    typeToSearch: 'Suchbegriff...',
    add: 'Hinzufügen',
    create: 'Erstellen',
    update: 'Update',
    save: 'Speichern',
    saved: 'Gespeichert',
    submit: 'Speichern',
    invite: 'Einladen',
    send: 'Senden',
    edit: 'Bearbeiten',
    apply: 'Anwenden',
    view: 'Ansehen',
    back: 'Zurück',
    close: 'Schließen',
    empty: 'Leer',
    reset: 'Zurücksetzen',
    email: 'E-Mail',
    workMail: 'E-Mail',
    password: 'Passwort',
    and: 'und',
    or: 'oder',
    retry: 'Erneut versuchen',
    settings: 'Einstellungen',
    change: 'Ändern',
    preview: 'Vorschau',
    status: 'Status',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    share: 'Teilen',
    public: 'Öffentlich',
    private: 'Privat',
    custom: 'Benutzerdefiniert',
    loadMore: 'Mehr laden',
    Settings: 'Einstellungen',
    yesDelete: 'Ja, löschen',
    yesRemove: 'Ja, entfernen',
    yesUpdate: 'Ja, speichern',
    yesDisconnect: 'Ja, trennen',
    yesDiscard: 'Ja, verwerfen',
    new: 'Neu',
    show: 'Anzeigen',
    remove: 'Entfernen',
    feed: 'Feed',
    list: 'Liste',
    createdAt: 'Erstellt am',
    creator: 'Erstellt von',
    title: 'Titel',
    actions: 'Aktionen',
    all: 'Alle',
    duplicate: 'Duplizieren',
    moreActions: 'Weitere Aktionen',
    download: 'Herunterladen',
    notFound: 'Nicht gefunden',
    deletePrompt: 'Bist du sicher?',
    emptyList: 'Keine Elemente vorhanden',
    noEntries: 'Keine Einträge',
    open: 'Öffnen',
    hide: 'Verbergen',
    select: 'Auswählen',
    discardChanges: 'Änderungen verwerfen',
    skip: 'Überspringen',
    discardEditPrompt: 'Möchtest du die Änderungen verwerfen?',
    untitled: 'Unbenannt',
    loading: 'lädt',
    value: 'Wert',
    copy: 'Kopie',
    learnMore: 'Mehr erfahren',
    supportEmail: 'support{\'@\'}mooncamp.com',
    websiteBaseUrl: 'https://mooncamp.com/de',
    helpCenterBaseUrl: 'https://help.mooncamp.com/de',
    default: 'Standard',
    saveAsPDF: 'Als PDF speichern',
    exporting: 'Seite wird exportiert...',
    at: 'um',
  },
  validation: {
    invalidEmail: 'Dies ist keine valide E-Mail',
    required: 'Dies ist ein Pflichtfeld',
    notEmpty: 'Der Wert darf nicht leer sein',
    minLengthEightChars: 'Minimum 8 Zeichen',
    textTooLong: 'Dieser Text ist zu lang',
    oneUserNeeded: 'Bitte wähle mindestens einen Nutzer aus',
    biggerThanZero: 'Muss größer als 0 sein',
    lessThan50Chars: 'Muss weniger als 50 Zeichen sein',
  },
  submitArea: { send: 'Antworten senden' },
  successView: {
    goToHomescreen: 'Zur App',
    thankYou: 'Vielen Dank für dein Feedback!',
  },
  form: {
    noItems: 'Keine Fragen vorhanden',
    continue: 'Weiter',
    commentLabel: 'Kommentar (optional)',
    required: 'Eine Antwort ist erforderlich.',
  },
  welcomeScreen: { start: 'Umfrage starten' },
  formFooter: {
    from: 'von',
    answered: 'beantwortet',
  },
  user: {
    bot: {
      firstName: 'Mooncamp',
      lastName: 'Bot',
      name: '@:{\'user.bot.firstName\'} @:{\'user.bot.lastName\'}',
    },
    deleted: {
      firstName: 'Gelöschter',
      lastName: 'Benutzer',
      name: '@:{\'user.deleted.firstName\'} @:{\'user.deleted.lastName\'}',
    },
  },
  userPicker: {
    placeholder: 'Name suchen',
    noData: 'Keine Mitarbeiter gefunden',
    description: 'Wähle eine Person',
  },
  page: { addEmoji: 'Icon hinzufügen' },
  mSelect: {
    pickAnItem: 'Wähle ein Element aus',
    searchPlaceholder: 'Suche nach einer Option...',
    noItems: 'Keine Elemente verfügbar',
    placeholder: 'Leer',
    title: 'Element wählen',
    create: 'Erstelle',
    more: 'weiterer | weitere',
  },
  timeZonePicker: {
    timeZone: 'Zeitzone',
    placeholder: 'Suche nach einer Zeitzone...',
    prompt: 'Wähle eine Zeitzone',
  },
  mDatePicker: {
    title: 'Wähle ein Datum',
    timePicker: 'Zeit berücksichtigen',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    empty: 'Leer',
    month: {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember',
    },
    monthShort: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mär',
      4: 'Apr',
      5: 'Mai',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Okt',
      11: 'Nov',
      12: 'Dez',
    },
    weekdays: {
      1: 'Montag',
      2: 'Dienstag',
      3: 'Mittwoch',
      4: 'Donnerstag',
      5: 'Freitag',
      6: 'Samstag',
      7: 'Sonntag',
    },
  },
  mUrlField: { openLink: 'Link öffnen', copyLink: 'Link kopieren' },
  mTable: {
    search: '{title} eingeben...',
    noData: 'Keine Daten verfügbar',
    count: 'Anzahl',
  },
  actions: {
    drag: 'Ziehe',
    toRealign: ', um zu verschieben',
    click: 'Klicke',
    toSeeActions: ', um Aktionen anzuzeigen',
  },
};

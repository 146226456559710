import { DateTime } from 'luxon';
import {
  accessGroup as accessGroupConfig,
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  account as accountConfig,
  accountSettings as accountSettingsConfig,
  appFeedback as appFeedbackConfig,
  appIntegration as appIntegrationConfig,
  asanaQuery as asanaQueryConfig,
  comment as commentConfig,
  companyInfo as companyInfoConfig,
  customerContract as customerContractConfig,
  dataSource as dataSourceConfig,
  favorite as favoriteConfig,
  feedEntry as feedEntryConfig,
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  goalCycle as goalCycleConfig,
  goalSettings as goalSettingsConfig,
  goalSubscription as goalSubscriptionConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileIframe as gridPageTileIframeConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
  hubspotQuery as hubspotQueryConfig,
  invitation as invitationConfig,
  jiraQuery as jiraQueryConfig,
  mSTeamsConfiguration as mSTeamsConfigurationConfig,
  msPlannerQuery as msPlannerQueryConfig,
  msPowerBiQuery as msPowerBiQueryConfig,
  notification as notificationConfig,
  notificationSetting as notificationSettingConfig,
  oauthCodeGrantClient as oauthCodeGrantClientConfig,
  personalAppSettings as personalAppSettingsConfig,
  pin as pinConfig,
  planning as planningConfig,
  printable as printableConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  propertyValue as propertyValueConfig,
  reaction as reactionConfig,
  report as reportConfig,
  resourceSettings as resourceSettingsConfig,
  salesforceQuery as salesforceQueryConfig,
  saml as samlConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  space as spaceConfig,
  spreadsheetCell as spreadsheetCellConfig,
  update as updateConfig,
  updateTemplate as updateTemplateConfig,
  user as userConfig,
  userProvisioning as userProvisioningConfig,
  userProvisioningMapping as userProvisioningMappingConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
  viewCollection as viewCollectionConfig,
} from 'shared/api/query/configs.json';
import { accessGroupFullChildren } from '@/api/query/nebula/access-group';
import {
  accessPolicyChildren,
  accessPolicyScopeChildren,
  userScopeChildren,
  userScopeTreeChildren,
} from '@/api/query/nebula/access-policy';
import {
  children as accountChildren, oauthCodeGrantClientChildren,
  samlChildren,
  userProvisioningChildren,
  userProvisioningMappingChildren,
} from '@/api/query/nebula/account';
import { children as accountSettingsChildren } from '@/api/query/nebula/account-settings';
import { appFeedbackChildren } from '@/api/query/nebula/app-feedback';
import { appIntegrationChildren } from '@/api/query/nebula/app-integration';
import { asanaQueryChildren } from '@/api/query/nebula/asana-query';
import { commentEdges } from '@/api/query/nebula/comment';
import { children as companyInfoChildren } from '@/api/query/nebula/company-info';
import { customerContractEdges } from '@/api/query/nebula/customer-contract';
import { dataSourceChildren } from '@/api/query/nebula/data-source';
import { children as favoriteChildren } from '@/api/query/nebula/favorite';
import { feedEntryChildren } from '@/api/query/nebula/feed-entry';
import { goalActivityChildren } from '@/api/query/nebula/goal-activity';
import { goalCycleChildren } from '@/api/query/nebula/goal-cycle';
import { goalDetailChildren } from '@/api/query/nebula/goal';
import { children as goalSettingsChildren } from '@/api/query/nebula/goal-settings';
import { goalSubscriptionChildren } from '@/api/query/nebula/goal-subscription';
import { gridPageChildren } from '@/api/query/nebula/grid-page';
import { gridPageRowChildren } from '@/api/query/nebula/grid-page-row';
import { gridPageTileChildren } from '@/api/query/nebula/grid-page-tile';
import { gridPageTileIframeChildren } from '@/api/query/nebula/grid-page-tile-iframe';
import { gridPageTileSingleGoalChildren } from '@/api/query/nebula/grid-page-tile-single-goal';
import { gridPageTileTextChildren } from '@/api/query/nebula/grid-page-tile-text';
import { hubspotQueryChildren } from '@/api/query/nebula/hubspot-query';
import { invitationEdges } from '@/api/query/nebula/inviation';
import { jiraQueryChildren } from '@/api/query/nebula/jira-query';
import { mSTeamsConfigurationChildren } from '@/api/query/nebula/ms-teams-configuration';
import { msPlannerQueryChildren } from '@/api/query/nebula/ms-planner-query';
import { msPowerBiQueryChildren } from '@/api/query/nebula/ms-powerbi-query';
import { notificationChildren } from '@/api/query/nebula/notification';
import { notificationSettingEdges } from '@/api/query/nebula/notification-setting';
import { personalAppSettingsEdges } from '@/api/query/nebula/personal-app-settings';
import { pinEdges } from '@/api/query/nebula/pin';
import { planningChildren } from '@/api/query/nebula/planning';
import { printableChildren } from '@/api/query/nebula/printable';
import { propertyEdges } from '@/api/query/nebula/property';
import { propertyOptionEdges } from '@/api/query/nebula/property-option';
import { propertySettingsEdges } from '@/api/query/nebula/property-settings';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { reactionEdges } from '@/api/query/nebula/reaction';
import { reportChildren } from '@/api/query/nebula/report';
import { children as resourceSettingsChildren } from '@/api/query/nebula/resource-settings';
import { salesforceQueryChildren } from '@/api/query/nebula/salesforce-query';
import { savedViewChildren } from '@/api/query/nebula/saved-view';
import { selectedViewChildren } from '@/api/query/nebula/selected-view';
import { spaceEdges } from '@/api/query/nebula/space';
import { spreadsheetCellChildren } from '@/api/query/nebula/sheets-cell';
import { updateChildren } from '@/api/query/nebula/update';
import { updateTemplateChildren } from '@/api/query/nebula/update-template';
import { userEdges } from '@/api/query/nebula/user';
import { viewCollectionChildren } from '@/api/query/nebula/view-collection';

export default {
  [accessGroupConfig.model]: accessGroupFullChildren,
  [appFeedbackConfig.model]: appFeedbackChildren,
  [accountConfig.model]: accountChildren,
  [commentConfig.model]: commentEdges,
  [reactionConfig.model]: reactionEdges,
  [favoriteConfig.model]: favoriteChildren,
  [printableConfig.model]: printableChildren,
  [propertyConfig.model]: propertyEdges,
  [propertyOptionConfig.model]: propertyOptionEdges,
  [spaceConfig.model]: spaceEdges,
  [feedEntryConfig.model]: feedEntryChildren,
  [gridPageConfig.model]: gridPageChildren,
  [gridPageRowConfig.model]: gridPageRowChildren,
  [gridPageTileConfig.model]: gridPageTileChildren,
  [gridPageTileSingleGoalConfig.model]: gridPageTileSingleGoalChildren,
  [gridPageTileTextConfig.model]: gridPageTileTextChildren,
  [gridPageTileIframeConfig.model]: gridPageTileIframeChildren,
  [goalCycleConfig.model]: goalCycleChildren,
  [notificationSettingConfig.model]: notificationSettingEdges,
  [propertySettingsConfig.model]: propertySettingsEdges,
  [pinConfig.model]: pinEdges,
  [userConfig.model]: userEdges,
  [invitationConfig.model]: invitationEdges,
  [propertyValueConfig.model]: propertyValueEdges,
  [personalAppSettingsConfig.model]: personalAppSettingsEdges,
  [accessPolicyConfig.model]: accessPolicyChildren,
  [accessPolicyScopeConfig.model]: accessPolicyScopeChildren,
  [userScopeTreeConfig.model]: userScopeTreeChildren,
  [userScopeConfig.model]: userScopeChildren,
  [accountSettingsConfig.model]: accountSettingsChildren,
  [resourceSettingsConfig.model]: resourceSettingsChildren,
  [goalSettingsConfig.model]: goalSettingsChildren,
  [companyInfoConfig.model]: companyInfoChildren,
  [customerContractConfig.model]: customerContractEdges,
  [appIntegrationConfig.model]: appIntegrationChildren,
  [samlConfig.model]: samlChildren,
  [userProvisioningConfig.model]: userProvisioningChildren,
  [userProvisioningMappingConfig.model]: userProvisioningMappingChildren,
  [oauthCodeGrantClientConfig.model]: oauthCodeGrantClientChildren,
  [updateConfig.model]: updateChildren(),
  [goalActivityConfig.model]: goalActivityChildren,
  [savedViewConfig.model]: savedViewChildren,
  [selectedViewConfig.model]: selectedViewChildren,
  [dataSourceConfig.model]: dataSourceChildren,
  [mSTeamsConfigurationConfig.model]: mSTeamsConfigurationChildren,
  [goalConfig.model]: goalDetailChildren({}),
  [goalSubscriptionConfig.model]: goalSubscriptionChildren,
  [jiraQueryConfig.model]: jiraQueryChildren,
  [hubspotQueryConfig.model]: hubspotQueryChildren,
  [salesforceQueryConfig.model]: salesforceQueryChildren,
  [msPowerBiQueryConfig.model]: msPowerBiQueryChildren,
  [msPlannerQueryConfig.model]: msPlannerQueryChildren,
  [asanaQueryConfig.model]: asanaQueryChildren,
  [spreadsheetCellConfig.model]: spreadsheetCellChildren,
  [planningConfig.model]: planningChildren,
  [notificationConfig.model]: notificationChildren([DateTime.local().toISO()]),
  [updateTemplateConfig.model]: updateTemplateChildren,
  [viewCollectionConfig.model]: viewCollectionChildren,
  [reportConfig.model]: reportChildren,
};

<template>
  <div :class="['user-list']">
    <div class="_header">
      <users-header
        :style="{ paddingBottom: '.6rem' }"
        :slots="headerSlots"
        :show-create-view="showCreateView"
        :show-views-selector="showViewsSelector"
        :read-only="readOnly"
        :filter-props="defaultProps"
        :data-loading="usersLoading"
      />
      <m-divider none />
    </div>
    <div :class="['_list']">
      <m-spinner
        v-if="firstLoading && pagedUsers.length === 0"
        class="_spinner"
      />
      <div v-else>
        <div
          v-if="pagedUsers.length === 0"
          class="_empty"
        >
          {{ $t('general.noEntries') }}
        </div>
        <m-endless-scroll-list
          v-else
          :key="`${listLoading}`"
          @visible="loadMore"
        >
          <template
            v-for="item in pagedUsers"
            :key="item.uid"
          >
            <list-item
              :entity="item"
              :props="viewProps"
              class="_item"
              property-values-key="values"
              @select="select(item)"
            >
              <template #title>
                <user-display
                  class="_user"
                  :user="item"
                />
              </template>
            </list-item>
            <m-divider
              v-if="$store.state.breakpoint.smAndDown"
              :key="`divider_${item.uid}`"
              small
            />
          </template>
        </m-endless-scroll-list>
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from '@/components/list/ListItem.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import UsersHeader from '@/components/users/UsersHeader.vue';
import useDebounce from '@/composables/debounce';
import useFirstLoading from '@/composables/first-loading';
import useSnackBar from '@/composables/snackbar';
import useUserDefaultProps from '@/composables/saved-views/user-default-props';
import useUserProperty from '@/composables/property/user-property';
import useUsers from '@/composables/user/users';
import useUsersSorting from '@/composables/user/sort';
import useViewParamsProps from '@/composables/view-params/view-params-props';
import { PROFILE } from '@/route-names';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import { computed, inject, ref } from 'vue';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'UserList',
  props: {
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: { UserDisplay, UsersHeader, ListItem },
  setup() {
    const snackBar = useSnackBar();
    const { debounce } = useDebounce();

    const viewSvc = inject(VIEWS_SERVICE);
    const { props: viewProps } = useViewParamsProps(viewSvc.currentView);
    const { defaultProps } = useUserDefaultProps();

    const userSvc = useUsers();

    const viewFilterObject = computed(() => {
      const handler = new UserFilterHandler(true);
      return handler.Translate(viewSvc.currentView.value.params.filter);
    });

    const page = ref(1);
    const itemsPerPage = ref(50);
    const totalAmount = ref(0);

    const propertySvc = useUserProperty();
    const { sort } = useUsersSorting(propertySvc.properties, userSvc);
    const users = computed(() => userSvc.users.value.filter((entity) => isInFilter({ isFilterMode: true })({
      entity,
      scopeTree: viewSvc.currentView.value.params.filter,
      propertyValues: entity.values,
    })));
    const pagedUsers = computed(() => {
      let order = viewSvc.currentView.value.params.order;
      if (order === undefined) {
        order = [];
      }

      return sort(users.value, order).slice(0, itemsPerPage.value);
    });

    const usersLoading = ref(true);
    const retrieveUsers = () => {
      usersLoading.value = false;
      totalAmount.value = users.value.length;
      return new Promise((resolve) => { resolve(null); });
    };
    // This implementation of `retrieveUsers` is not necessary if we prefetch all users at app start
    // const store = useStore();
    // const retrieveUsers = () => {
    //   usersLoading.value = true;
    //
    //   return getEntities(store, {
    //     query: userListAdvanced({
    //       filter: viewFilterObject.value.filterTree,
    //       varBlocks: viewFilterObject.value.varBlocks,
    //       order: viewSvc.currentView.value.params.order,
    //       pagination: {
    //         page: page.value,
    //         itemsPerPage: itemsPerPage.value,
    //         countAlias: COUNT,
    //       },
    //     }),
    //     model: userConfig.model,
    //   }).then((response) => {
    //     usersLoading.value = false;
    //     if (response.status !== 200) {
    //       return;
    //     }
    //     totalAmount.value = response.data.count;
    //   });
    // };

    const firstLoadingSvc = useFirstLoading({ currentView: viewSvc.currentView });
    const loadFirst = () => {
      itemsPerPage.value = 50;
      firstLoadingSvc.startLoading();
      retrieveUsers().catch(logCatch(() => {
        snackBar.error();
      })).finally(() => {
        firstLoadingSvc.finishLoading();
      });
    };
    loadFirst();

    return {
      debounce,
      defaultProps,
      currentView: viewSvc.currentView,
      viewProps,

      firstLoading: firstLoadingSvc.loading,
      usersLoading,

      page,
      itemsPerPage,
      totalAmount,
      viewFilterObject,
      pagedUsers,

      retrieveUsers,
      loadFirst,
    };
  },
  data() {
    return {
      loadMoreLoading: false,
      headerSlots: [{ name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.PROPERTIES }],
    };
  },
  computed: {
    listLoading() {
      return this.usersLoading && !this.loadMoreLoading;
    },
    order() {
      return this.currentView.params.order;
    },
  },
  methods: {
    select(user) {
      this.$router.push({
        name: PROFILE,
        params: { userId: user.uid },
      });
    },
    loadMore() {
      if (this.totalAmount === 0) {
        return;
      }

      if (this.totalAmount <= this.itemsPerPage * this.page) {
        return;
      }

      this.itemsPerPage += 20;
      this.loadMoreLoading = true;
      this.retrieveUsers().finally(() => {
        this.loadMoreLoading = false;
      });
    },
  },
  watch: {
    viewFilterObject: {
      handler(val, newVal) {
        if (JSON.stringify(val) === JSON.stringify(newVal)) {
          return;
        }
        this.debounce(this.loadFirst, 700);
      },
      deep: true,
    },
    order(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.loadFirst, 700);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .user-list {
    ._header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: white;
      z-index: 2;
    }

    ._btn {
      margin-left: .4rem;
    }

    ._create {
      margin-left: .4rem;
    }

    ._list {
      position: relative;

      ._spinner {
        position: absolute;
        top: 2rem;
        left: 46%;
      }

      ._empty {
        margin: .8rem 0 .8rem .6rem;
      }

      ._item {
        margin: .4rem 0;
      }

      ._user {
        margin-right: auto;
      }
    }

    ._pagination {
      margin-top: 1rem;
    }
  }
</style>

<template>
  <div class="account-settings">
    <m-content>
      <page-header
        :title="$t('settingsNavigation.identityAndProvisioning')"
        boxed-xs
        heading="h3"
        underlined
        class="_header"
      />
      <m-content
        boxed-xs
        padding
        class="_content"
      >
        <m-section
          heading-size="h4"
          :title="$t('identityAndProvisioning.security.heading')"
          class="_section"
        >
          <settings-switch-item
            :title="$t('identityAndProvisioning.restrictLogin.passwordLoginTitle')"
            :sub-title="$t('identityAndProvisioning.restrictLogin.passwordLoginSubTitle')"
            :value="!accountSettings.restrictPasswordLogin"
            icon="key"
            @click="showConfirm('restrictPassword', !accountSettings.restrictPasswordLogin)"
          />
          <m-divider small />
          <settings-switch-item
            :title="$t('identityAndProvisioning.restrictLogin.googleLoginTitle')"
            :sub-title="$t('identityAndProvisioning.restrictLogin.googleLoginSubTitle')"
            :value="!accountSettings.restrictGoogleLogin"
            icon="google-color"
            @click="showConfirm('restrictGoogleLogin', !accountSettings.restrictGoogleLogin)"
          />
          <m-divider small />
          <settings-switch-item
            :title="$t('identityAndProvisioning.restrictLogin.microsoftLoginTitle')"
            :sub-title="$t('identityAndProvisioning.restrictLogin.microsoftLoginSubTitle')"
            :value="!accountSettings.restrictMicrosoftLogin"
            icon="microsoft-color"
            @click="showConfirm('restrictMicrosoftLogin', !accountSettings.restrictMicrosoftLogin)"
          />
        </m-section>
        <m-section
          heading-size="h4"
          :title="$t('identityAndProvisioning.saml.heading')"
          class="saml _section"
        >
          <template #after-title>
            <plan-tag
              class="_plan-tag"
              :feature-flag="featureFlag.saml"
            />
          </template>
          <div class="_item">
            <div class="_label">
              {{ $t('identityAndProvisioning.saml.ssoUrl') }}
            </div>
            <m-text-field
              v-model:value="localSaml.ssoUrl"
              full-width
              :disabled="!accountHasFeature([featureFlag.saml])"
            />
          </div>
          <div class="_item">
            <div class="_label">
              {{ $t('identityAndProvisioning.saml.entityId') }}
            </div>
            <m-text-field
              v-model:value="localSaml.entityId"
              full-width
              :disabled="!accountHasFeature([featureFlag.saml])"
            />
          </div>
          <div class="_item">
            <div class="_label">
              {{ $t('identityAndProvisioning.saml.certificate') }}
            </div>
            <m-textarea
              v-model:value="localSaml.certificate"
              full-width
              :disabled="!accountHasFeature([featureFlag.saml])"
              :rows="6"
            />
          </div>
          <m-btn
            :disabled="!accountHasFeature([featureFlag.saml])"
            @click="saveSaml"
          >
            {{ $t('identityAndProvisioning.saml.save') }}
          </m-btn>
        </m-section>
        <user-provisioning
          class="_section"
          @toggle="showConfirm"
        />
      </m-content>
    </m-content>
  </div>
</template>

<script>
import PageHeader from 'shared/components/PageHeader.vue';
import PlanTag from '@/components/plans/PlanTag.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import UserProvisioning from '@/components/security/UserProvisioning.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSaml from '@/composables/logged-in-user-account/saml';
import { copy } from 'shared/lib/copy';
import { featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'IdentityAndProvisioning',
  components: { UserProvisioning, PlanTag, SettingsSwitchItem, PageHeader },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const accountSvc = useLoggedInUserAccount();
    const { accountSettings } = useAccountSettings();
    const { accountHasFeature } = useAccess();

    const samlSvc = useSaml();

    return {
      loggedInUser,
      accountSettings,
      loggedInUserAccount: accountSvc.loggedInUserAccount,
      accountSvc,
      samlSvc,
      accountHasFeature,
    };
  },
  data() {
    return {
      loading: false,
      localSaml: { ssoUrl: '', entityId: '', certificate: '' },
      featureFlag,
    };
  },
  methods: {
    showConfirm(type, isActive) {
      let title = '';
      const accountSettings = { ...this.accountSettings };
      const appIntegration = null;
      const update = () => this.updateSettings({ accountSettings, appIntegration });
      switch (type) {
        case 'restrictPassword':
          title = isActive ? this.$t('identityAndProvisioning.restrictLogin.deactivatePasswordTitle') : this.$t('identityAndProvisioning.restrictLogin.activatePasswordTitle');
          accountSettings.restrictPasswordLogin = !accountSettings.restrictPasswordLogin;
          break;
        case 'restrictGoogleLogin':
          title = isActive ? this.$t('identityAndProvisioning.restrictLogin.deactivateGoogleLoginTitle') : this.$t('identityAndProvisioning.restrictLogin.activateGoogleLoginTitle');
          accountSettings.restrictGoogleLogin = !accountSettings.restrictGoogleLogin;
          break;
        case 'restrictMicrosoftLogin':
          title = isActive ? this.$t('identityAndProvisioning.restrictLogin.deactivateMicrosoftLoginTitle') : this.$t('identityAndProvisioning.restrictLogin.activateMicrosoftLoginTitle');
          accountSettings.restrictMicrosoftLogin = !accountSettings.restrictMicrosoftLogin;
          break;
        default:
      }
      this.$confirm({
        title,
        okText: isActive ? this.$t('accountSettings.features.deactivate') : this.$t('accountSettings.features.activate'),
        okType: isActive ? 'danger' : 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update();
        },
      });
    },
    updateSettings({ appIntegration, accountSettings }) {
      const updateEntity = { uid: this.loggedInUserAccount.uid, accountSettings };
      if (appIntegration != null) {
        updateEntity.appIntegration = appIntegration;
      }

      this.loading = true;
      this.accountSvc.updateSingle(updateEntity)
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }))
        .then(() => {
          this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        }).finally(() => {
          this.loading = false;
        });
    },
    saveSaml() {
      if (typeof this.localSaml.uid === 'undefined') {
        this.createSaml(this.localSaml);
        return;
      }

      this.updateSaml(this.localSaml);
    },
    updateSaml(saml) {
      this.samlSvc.updateSingle(saml).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    createSaml(saml) {
      this.samlSvc.createSingle(saml).then((entity) => {
        this.localSaml = copy(entity);
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
  created() {
    if (this.samlSvc.saml.value !== undefined) {
      this.localSaml = copy(this.samlSvc.saml.value);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .account-settings {
    ._header {
      margin-bottom: 2rem;
    }

    ._content {
      padding-bottom: 4rem;
    }

    ._section {
      margin-bottom: 4rem;
    }

    ._plan-tag {
      margin-left: .8rem;
    }

    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }
    }

    ._settings {
      ._avatar {
        margin-top: 2rem;
        margin-bottom: 2rem;

        ._company-avatar {
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>

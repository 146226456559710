<template>
  <div :class="['goal-list-group']">
    <h6>{{ heading }}</h6>
    <m-endless-scroll-list @visible="amountOfItems += 10">
      <m-draggable
        :disabled="!canEditAllGoals || $store.state.breakpoint.smAndDown || !canEdit"
        draggable-item-class="goal-card"
        dragover-item-class="goal-card"
        scroll-container-class="scroll-container"
        can-drag-over-top
        can-drag-over-bottom
        :recreate-key="list.length"
        :drag-between-height="20"
        @set-drag-item="setDragItem"
        @over-top="setOverTop"
        @over-bottom="setOverBottom"
        @drag-drop="dropItem"
        @cancel="cancelDragging"
      >
        <goal-card
          v-for="(item, index) in list"
          :key="item.uid"
          :identifier="item.uid"
          :data-id="item.uid"
          :data-identifier="item.uid"
          :component-key="item.uid"
          :entity="item"
          :props="props"
          :can-create="canCreate"
          layout="compact"
          :class="['_draggable-item', childClass]"
          :read-only="readOnly"
          :selected="selectedGoalIds.includes(item.uid)"
          :selected-secondary="selectedGoalIds.includes(item.uid) && item.accessRight === accessPolicyType.read"
          :selected-goal-ids="selectedGoalIds"
          :create-beneath-loading="createBeneathLoading"
          :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(item.uid)"
          :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(item.uid)"
          :update-property="updateProperty"
          @context-menu-clicked="$emit('context-menu-clicked', $event, item)"
          @open="open(item)"
          @progress-clicked="progressClick"
          @select="$emit('select', $event)"
          @create-beneath="createBeneath"
        />
      </m-draggable>
    </m-endless-scroll-list>
    <m-btn
      v-if="canCreate"
      hide-border
      icon="plus"
      light
      block
      :loading="createBtnLoading"
      :button-style="{ justifyContent: 'flex-start' }"
      @click="createG"
    >
      {{ $t('general.new') }}
    </m-btn>
  </div>
</template>

<script>
import GoalCard from '@/components/goal/GoalCard.vue';
import useAccess from '@/composables/access/access';
import useGoalProperty from '@/composables/property/goal-property';
import useInlineEditing from '@/composables/inline-editing';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSnackBar from '@/composables/snackbar';
import useSort from '@/composables/draggable/sort';
import { GOAL_DETAILS } from '@/route-names';
import { accessGroupFlag, accessPolicyType, propertyType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalListGroup',
  props: {
    parent: {
      type: Object,
      required: true,
    },
    childClass: {
      type: String,
      required: true,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    props: {
      type: Array,
      default: () => [],
    },
    selectedGoalIds: {
      type: Array,
      default: () => [],
    },
    groupByOption: {
      type: Object,
      default: () => null,
    },
    groupByProperty: {
      type: Object,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    goalCreator: {
      type: Object,
      default: () => ({}),
    },
    showPlusBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['context-menu-clicked', 'select', 'open', 'progress-click', 'list-after', 'list-before'],
  setup() {
    const snackbar = useSnackBar();
    const { userHasRight } = useAccess();
    const inlineEditingSvc = useInlineEditing();
    const { properties: goalProperties } = useGoalProperty();
    const { userLang } = useLoggedInUser();
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      cancelDragging,
      dragItemId,
    } = useSort();

    return {
      snackbar,
      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      cancelDragging,
      userHasRight,
      inlineEditingSvc,
      goalProperties,
      userLang,
    };
  },
  components: { GoalCard },
  data() {
    return {
      createBtnLoading: false,
      createBeneathLoading: false,
      accessPolicyType,
      amountOfItems: 10,
    };
  },
  computed: {
    list() {
      return this.items.slice(0, this.amountOfItems);
    },
    canCreateGoal() {
      return this.userHasRight([accessGroupFlag.createGoal]);
    },
    canEdit() {
      return !this.readOnly && [accessPolicyType.full, accessPolicyType.write].includes(this.parent.accessRight);
    },
    canCreate() {
      if (!this.canEdit || !this.showPlusBtn) {
        return false;
      }

      return this.canCreateGoal;
    },
    canEditAllGoals() {
      if (!this.canEdit) {
        return false;
      }

      return this.notEditableGoals.length === 0;
    },
    heading() {
      if (this.groupByOption === null) {
        return this.$t('list.ungrouped');
      }
      if (this.groupByProperty.type === propertyType.space) {
        return this.groupByOption.title;
      }
      return textByLang(this.groupByOption.label, this.userLang);
    },
    notEditableGoals() {
      return this.items.reduce((acc, g) => {
        if (![accessPolicyType.full, accessPolicyType.write].includes(g.accessRight)) {
          acc.push(g);
        }

        return acc;
      }, []);
    },
  },
  methods: {
    dropItem() {
      if (this.draggingOverTop.length > 0 && this.items.length > 0 && this.draggingOverTop[0] === this.items[0].uid) {
        this.$emit('list-before', this.parent, this.parent.children.map((c) => c.uid), this.draggingOverTop[0], [this.dragItemId]);
        this.dragItemId = '';
        this.draggingOverBottom = [];
        this.draggingOverTop = [];
        return;
      }

      if (this.draggingOverBottom.length > 0) {
        this.$emit('list-after', this.parent, this.parent.children.map((c) => c.uid), this.draggingOverBottom[0], [this.dragItemId]);
      }
      this.dragItemId = '';
      this.draggingOverBottom = [];
      this.draggingOverTop = [];
    },
    open(item) {
      this.$emit('open', item);
    },
    progressClick(entity) {
      this.$emit('progress-click', entity);
    },
    createBeneath(goal) {
      this.createBeneathLoading = true;
      this.create({
        openAsPage: true,
        parent: goal.parent,
        option: goal.option,
      });
    },
    createG() {
      this.createBtnLoading = true;
      this.create({ openAsPage: false, parent: this.parent });
    },
    create({ openAsPage, parent, option = this.groupByOption }) {
      this.createBtnLoading = true;
      this.createBeneathLoading = true;
      switch (this.groupByProperty.type) {
        case propertyType.space:
          this.goalCreator.modifiers.setSpace(option);
          break;
        case propertyType.options:
        case propertyType.singleSelect:
          this.goalCreator.modifiers.setOption(option);
          break;
        default:
          break;
      }
      this.goalCreator.addChild([parent.uid])
        .then((newGoal) => {
          this.goalCreator.modifiers.setSpace(null);
          this.goalCreator.modifiers.setOption(null);
          if (openAsPage) {
            this.$router.push({ name: GOAL_DETAILS, params: { goalId: newGoal.uid } });
            return;
          }
          this.inlineEditingSvc.set(newGoal.uid);
        }).catch(logCatch(() => {
          this.snackbar.error();
        })).finally(() => {
          this.createBtnLoading = false;
          this.createBeneathLoading = false;
        });
    },
  },
};
</script>

<style
    lang="scss"
    type="text/scss"
>
  .goal-list-group {
    h6 {
      margin-top: 2rem;
      margin-left: .4rem;
      font-size: $font-size-2;
      font-weight: $font-weight-semibold;
      color: $font-color-secondary;
      text-transform: uppercase;
      letter-spacing: .05em;
    }

    ._draggable-item {
      position: relative;
      display: flex;
      align-items: center;
      margin: .8rem 0;
    }
  }
</style>

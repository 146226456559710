<template>
  <not-found-page
    v-if="!fetchPlanningLoading && typeof planning == 'undefined'"
    :to="{ name: routeName.planningList }"
    :title="$t('notFoundPage.title', {type: $t('planningDetails.planning')})"
    :anchor-text=" $t('notFoundPage.buttonText', {types: $t('planningDetails.plannings')})"
  />
  <page-layout
    v-else
    :padding-bottom="0"
    :loading="fetchPlanningLoading"
    class="planning-details"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_top-bar"
      >
        <template #after-breadcrumbs>
          <m-tag
            v-if="planning.status === planningStatus.closed"
            :style="{ paddingTop: '.2rem' }"
            xs
            strong
            :title="$t('planningDetails.closed')"
          />
        </template>
        <template #actions>
          <div
            class="_actions"
          >
            <share-dropdown
              v-if="!$store.state.breakpoint.smAndDown"
              id="share-button"
              :access-policy="planning.accessPolicy"
              :creator="planning.creator"
              :icon="isPublic ? 'global': 'lock'"
              :button-text="$t('general.share')"
              :page-link="pageLink"
              :disabled="planning.accessRight !== accessPolicyType.full"
              :creator-description="$t('planningDetails.shareCreatorDescription')"
              :on-update="updateAccessPolicy"
            />
            <favorite-button
              v-if="!$store.state.breakpoint.smAndDown"
              :title-suggestion="planning.title"
              class="_action-item"
            />
            <m-btn
              v-if="planning.status === planningStatus.closed && canEdit"
              small
              hide-border
              @click="changeStatus(planningStatus.open)"
            >
              {{ $t('planningDetails.reopen') }}
            </m-btn>
            <m-btn
              v-if="canEdit && planning.status === planningStatus.open"
              id="publish-button"
              color="primary"
              small
              class="_action-item"
              @click="openPublishModal"
            >
              {{ $t('planningDetails.publish') }}
            </m-btn>
            <m-dialog
              v-model:value="showPublishModal"
              :max-width="!published ? `${tableWidth}px` : '50rem'"
              no-padding
              hide-footer
              :title="published ? '' : $t('planningDetails.publishGoals', { title: featureNamePlural })"
              :header-style="{ borderBottomWidth: 0 }"
              center
              @close="published = false"
            >
              <publish-goals
                :current-view="$refs.goalsListWrapper.currentView"
                :table-width="tableWidth"
                :planning-id="id"
                :default-filter="defaultFilter"
                @published="published = true"
              />
            </m-dialog>
            <m-dropdown
              v-if="$store.state.breakpoint.smAndDown || canEdit"
              v-model:value="showMoreMenu"
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
                small
                class="_action-item"
                @click="showMoreMenu = true"
              />
              <template #overlay>
                <m-card
                  no-padding
                  list
                  @click="showMoreMenu = false"
                >
                  <share-dropdown
                    v-if="$store.state.breakpoint.smAndDown"
                    :access-policy="planning.accessPolicy"
                    :creator="planning.creator"
                    :icon="isPublic ? 'global': 'lock'"
                    :button-text="$t('general.share')"
                    :page-link="pageLink"
                    :disabled="planning.accessRight !== accessPolicyType.full"
                    :on-update="updateAccessPolicy"
                  />
                  <favorite-button
                    v-if="$store.state.breakpoint.smAndDown"
                    :title-suggestion="$t('navigation.plannings')"
                  />
                  <template v-if="canEdit">
                    <m-card-item
                      v-if="planning.status === planningStatus.open"
                      @click="changeStatus(planningStatus.closed)"
                    >
                      {{ $t('planningDetails.markClosed') }}
                    </m-card-item>
                    <m-card-item
                      v-if="planning.status === planningStatus.closed"
                      @click="changeStatus(planningStatus.open)"
                    >
                      {{ $t('planningDetails.reopen') }}
                    </m-card-item>
                    <m-card-item
                      icon="delete"
                      @click="confirmDelete"
                    >
                      {{ $t('general.delete') }}
                    </m-card-item>
                  </template>
                  <m-divider
                    v-if="($store.state.breakpoint.smAndDown || canEdit) && planning.status === planningStatus.open"
                    xxs
                  />
                  <m-card-item
                    icon="download"
                    @click="showImportModal = true"
                  >
                    {{ $t('planningDetails.importGoals.button', {title: featureNamePlural}) }}
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container no-padding-bottom>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <planning-details-header :planning="planning" />
        <m-divider :style="{ marginTop: '1.2rem' }" />
      </m-content>
      <goals-list-wrapper
        ref="goalsListWrapper"
        :header-slots="headerSlots"
        :can-create-goals="canCreate && planning.status === planningStatus.open"
        :read-only="planning.status === planningStatus.closed"
        show-views-selector
        fullscreen
        stretch-content
        show-create-view
        :selected-cycles="[]"
        show-errors
        :planning="{ uid: id }"
        :goal-modifiers="goalModifiers"
        :allowed-view-types="[viewType.cascade, viewType.list]"
        :default-filter="defaultFilter"
        :default-props="defaultProps"
      />
    </scroll-container>
    <m-dialog
      v-model:value="showInitialImportModal"
      no-padding
      hide-footer
      :max-width="$modalSizes.md"
      :title="$t('planningDetails.importGoals.initialModal.title', { title: featureNamePlural })"
      center
      @close="showInitialImportModal = false"
    >
      <initial-import-goals
        :feature-name-plural="featureNamePlural"
        @scratch="showInitialImportModal = false"
        @import="showInitialImportModal = false; showImportModal = true"
      />
    </m-dialog>
    <m-dialog
      v-model:value="showImportModal"
      :max-width="`${tableWidth}px`"
      no-padding
      hide-footer
      :title="$t('planningDetails.importGoals.modal.title', { title: featureNamePlural })"
      center
      @close="showImportModal = false"
    >
      <import-goals
        :table-width="tableWidth"
        :planning-id="id"
        @ok="onImported"
      />
    </m-dialog>
    <product-tour
      v-if="showProductTour"
      :steps="tourSteps"
      hide-avatar
      @finish="showProductTour = false"
      @close="showProductTour = false"
    />
  </page-layout>
</template>

<script>
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import ImportGoals from '@/components/goal/plannings/ImportGoals.vue';
import InitialImportGoals from '@/components/goal/plannings/InitialImportGoals.vue';
import NotFoundPage from '@/components/NotFoundPage.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import PlanningDetailsHeader from '@/components/goal/plannings/PlanningDetailsHeader.vue';
import ProductTour from '@/components/onboarding/ProductTour.vue';
import PublishGoals from '@/components/goal/plannings/PublishGoals.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import usePlanningDetailsCtx from '@/composables/planning/planning-details-context';
import usePlannings from '@/composables/planning/plannings';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { GOAL_PLANNING } from '@/lib/props/custom-types';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import {
  accessGroupFlag,
  accessPolicyType,
  onboardingSteps,
  planningStatus,
  routeName,
  userScopeType,
  viewApplication,
  viewType,
} from 'shared/constants.json';
import {
  goal as goalConfig,
} from 'shared/api/query/configs.json';
import { provide } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PlanningDetails',
  components: {
    InitialImportGoals,
    ProductTour,
    NotFoundPage,
    PlanningDetailsHeader,
    PublishGoals,
    ImportGoals,
    GoalsListWrapper,
    FavoriteButton,
    PageLayout,
    PageTopBar,
    ScrollContainer,
    ShareDropdown,
  },
  setup() {
    const { t } = useI18n();
    const { userHasRight } = useAccess();
    const { goalCycles } = useGoalCycle();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const featureNamePlural = loggedInUserAccount.value.goalSettings.featureNamePlural;

    const accountSettingsSvc = useAccountSettings();
    const { markAsDone, isDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);

    const {
      fetchPlanning,
      fetchPlanningLoading,
      updatePlanning,
      updatePlanningAccessPolicy,
      deletePlanning,
    } = usePlannings();

    const { paramId: id, planning, goalModifiers } = usePlanningDetailsCtx();

    const viewApp = viewApplication.planningDetails;
    const defaultView = {
      isTemp: true,
      title: t('list.cascade'),
      viewType: viewType.cascade,
      uid: 0,
      viewApplication: viewApp,
      planning: { uid: id.value },
      params: {
        filter: null,
        order: [],
        props: [],
        wrapCells: true,
      },
    };

    const defaultFilter = {
      op: AND,
      children: [],
      scope: {
        isEmpty: false,
        type: userScopeType.directProperty,
        directProperty: {
          type: GOAL_PLANNING,
          edgeName: goalConfig.edges.planning,
        },
        plannings: [{ value: id.value }],
      },
    };

    const viewServiceInitService = useViewServiceInit();
    const defaultPropsSvc = useGoalDefaultProps();

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
    routeAwareConfig = viewServiceInitService.withPlanning(routeAwareConfig, { uid: id.value });
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    return {
      userHasRight,
      defaultFilter,
      markAsDone,
      featureNamePlural,
      goalCycles,
      id,
      planning,
      goalModifiers,
      fetchPlanning,
      fetchPlanningLoading,
      updatePlanning,
      updatePlanningAccessPolicy,
      deletePlanning,
      productTourPlanningDetailsCompleted: isDone([onboardingSteps.planningDetailsTour]),

      defaultProps: defaultPropsSvc.defaultProps,
    };
  },
  data() {
    return {
      viewType,
      showPublishModal: false,
      showInitialImportModal: false,
      showImportModal: false,
      planningStatus,
      accessPolicyType,
      published: false,
      routeName,
      showProductTour: false,
      headerSlots: [{ name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.COMPARE_TO }, { name: SLOTS.PROGRESS_DISPLAY_OPTION }],
      showMoreMenu: false,
    };
  },
  computed: {
    tourSteps() {
      return [
        {
          highlightedElement: 'planning-properties',
          heading: this.$t('planningDetails.productTour.step1.heading'),
          content: this.$t('planningDetails.productTour.step1.content'),
          placement: 'bottomLeft',
        },
        {
          highlightedElement: 'share-button',
          heading: this.$t('planningDetails.productTour.step2.heading'),
          content: this.$t('planningDetails.productTour.step2.content'),
          placement: 'bottomRight',
        },
        {
          highlightedElement: 'publish-button',
          heading: this.$t('planningDetails.productTour.step3.heading'),
          content: this.$t('planningDetails.productTour.step3.content'),
          placement: 'bottomRight',
        },
      ];
    },
    pageLink() {
      return window.location.href;
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.createGoal]);
    },
    isPublic() {
      if (this.planning === undefined) {
        return false;
      }
      return this.planning.accessPolicy.accountAccess !== accessPolicyType.disabled;
    },
    tableWidth() {
      return window.innerWidth * 0.7;
    },
    title() {
      if (this.planning === undefined) {
        return '';
      }

      return this.planning.title;
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.plannings'),
          to: { name: routeName.planningList },
        },
        {
          title: this.title === '' ? this.$t('list.noTitle') : this.title,
          disabled: true,
        },
      ];
    },
    canEdit() {
      if (this.planning === undefined) {
        return false;
      }
      return [accessPolicyType.full, accessPolicyType.write].includes(this.planning.accessRight);
    },
  },
  methods: {
    confirmDelete() {
      const deleteMethod = () => this.deletePlanning(this.planning.uid).then(() => {
        this.$router.push({ name: routeName.planningList });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('general.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    updateAccessPolicy(value) {
      this.updatePlanningAccessPolicy({ uid: this.id, accessPolicy: value }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    changeStatus(status) {
      this.updatePlanning({ ...this.planning, status }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    openPublishModal() {
      this.showPublishModal = true;
    },
    onImported() {
      this.showImportModal = false;
      this.showMoreMenu = false;
    },
  },
  watch: {
    showProductTour(newVal, oldVal) {
      if (newVal !== false && oldVal !== true) {
        return;
      }

      this.showInitialImportModal = this.$route.query.afterCreate === 'true';
      this.markAsDone([onboardingSteps.planningDetailsTour]).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    showInitialImportModal(newVal, oldVal) {
      if (newVal !== false && oldVal !== true) {
        return;
      }

      const query = { ...this.$route.query };
      delete query.afterCreate;
      this.$router.replace({ query });
    },
  },
  created() {
    this.fetchPlanning(this.id).catch(() => {
      this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
    });
    if (!this.productTourPlanningDetailsCompleted) {
      this.showProductTour = true;
    }
    this.showInitialImportModal = this.$route.query.afterCreate === 'true' && !this.showProductTour;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .planning-details {
    min-height: 100vh;
    overflow: auto;

    ._top-bar {
      ._actions {
        display: flex;

        ._action-item {
          margin-left: .6rem;
        }
      }
    }

    ._header {
      position: sticky;
      left: 0;
    }
  }

</style>

<template>
  <div
    :id="guid"
    ref="form"
    class="form"
  >
    <full-screen-spinner v-if="form === null" />
    <div
      v-else-if="hasWelcomeScreen && showWelcomeScreen"
      class="_welcome-screen-container"
    >
      <form-header
        v-if="languages.length > 1 && showLanguageSelection"
        :lang="lang"
        :lang-options="languages"
        @change-language="changeLanguage"
      />
      <welcome-screen
        :welcome-screen="form.welcomeScreen"
        :lang="lang"
        class="_welcome-screen"
        @start="start"
      />
    </div>
    <div
      v-else-if="form.orderItems.length === 0"
      class="_empty-list"
    >
      {{ $t('form.noItems') }}
    </div>
    <template v-else>
      <form-header
        v-if="languages.length > 1 && showLanguageSelection"
        :lang="lang"
        :lang-options="languages"
        @change-language="changeLanguage"
      />
      <form-content
        ref="formContent"
        :form="form"
        :current-question-index="currentQuestionIndex"
        :missing-answers="missingAnswers"
        :lang="lang"
        :form-height="formHeight"
        :has-question-selector="languages.length > 1 && showLanguageSelection"
        :width="width"
        @go-to-next-question="goToNextQuestion"
        @answer-changed="updateAnswer"
        @question-heights-updated="setQuestionHeights"
        @send="submit"
      />
      <form-footer
        :current-question-index="currentQuestionIndex"
        :answers="answers"
        :form="form"
        :right="footerRight"
        :bottom="footerBottom"
        :width="width"
        @scroll-to-previous-question="activateQuestion"
        @scroll-to-next-question="activateQuestion"
      />
    </template>
  </div>
</template>

<script>
import FormContent from 'shared/components/internal/form/FormContent.vue';
import FormFooter from 'shared/components/internal/form/FormFooter.vue';
import FormHeader from 'shared/components/internal/form/FormHeader.vue';
import FullScreenSpinner from 'shared//components/FullScreenSpinner.vue';
import WelcomeScreen from 'shared/components/internal/form/WelcomeScreen.vue';
import { guid } from 'shared/lib/uuid';

export default {
  /* eslint-disable vue/no-reserved-component-names */
  name: 'Form',
  /* eslint-enable vue/no-reserved-component-names */
  props: {
    form: {
      type: Object,
      default: null,
    },
    width: {
      type: String,
      default: '100%',
    },
    lang: {
      type: String,
      default: 'de', // TODO: we should make this required and save it on the form itself
    },
    languages: {
      type: Array,
      default: () => [],
    },
    showLanguageSelection: {
      type: Boolean,
      default: true,
    },
    footerRight: {
      type: String,
      default: '0',
    },
    footerBottom: {
      type: String,
      default: '0',
    },
  },
  emits: ['change-language', 'submit'],
  components: {
    WelcomeScreen,
    FormHeader,
    FullScreenSpinner,
    FormContent,
    FormFooter,
  },
  data() {
    return {
      questionHeights: [200, 200],
      answers: [],
      scrollTop: 0,
      showWelcomeScreen: true,
      formHeight: 0,
    };
  },
  computed: {
    answer() {
      return {
        answerItems: [...this.answers],
        form: { uid: this.form.uid },
      };
    },
    guid() {
      return guid();
    },
    hasWelcomeScreen() {
      return this.form.welcomeScreen !== null
          && typeof this.form.welcomeScreen.deletedAt === 'undefined';
    },
    currentQuestionIndex() {
      let initialHeight = 0;
      for (let i = 0; i < this.questionHeights.length; i += 1) {
        if (this.scrollTop < initialHeight + this.questionHeights[i] / 1.5) {
          return i;
        }
        initialHeight += this.questionHeights[i];
      }
      if (typeof this.form.orderItems === 'undefined') {
        return 0;
      }
      return this.form.orderItems.length;
    },
    missingAnswers() {
      const missingAnswers = [];
      this.form.orderItems.forEach((item, index) => {
        const isRequired = item.field.validations.required;
        if (!isRequired) {
          return;
        }

        if (this.answerExists(item)) {
          return;
        }

        missingAnswers.push(index);
      });
      return missingAnswers;
    },
  },
  methods: {
    answerExists(orderItem) {
      return this.answers.filter((answer) => answer.field.uid === orderItem.field.uid).length > 0;
    },
    changeLanguage({ lang }) {
      this.$emit('change-language', { lang });
    },
    easeInOutQuad(currentTime, startPosition, change, duration) {
      let time = currentTime;
      time /= duration / 2;
      if (time < 1) {
        return (change / 2) * time * time + startPosition;
      }
      time -= 1;
      return (-change / 2) * (time * (time - 2) - 1) + startPosition;
    },
    activateQuestion({ questionIndex }) {
      this.showWelcomeScreen = false;
      this.scrollTo(
        document.getElementById(this.guid),
        this.scrollTarget(questionIndex),
        300,
        this.scrollTop,
      );
    },
    updateAnswer(payload) {
      const existingAnswer = this.answers.filter(
        (answer) => answer.field.uid === payload.answer.field.uid,
      );

      if (existingAnswer.length > 1) {
        throw new Error('there were several answers to one field ID');
      }

      if (existingAnswer.length === 1) {
        let answerIndex = -1;
        this.answers.forEach((answer, index) => {
          if (answer.field.uid === payload.answer.field.uid) {
            answerIndex = index;
          }
        });
        this.answers[answerIndex] = payload.answer;
        return;
      }

      this.answers.push(payload.answer);
    },
    goToNextQuestion(payload) {
      setTimeout(() => {
        this.activateQuestion({ questionIndex: payload.questionIndex + 1 });
      }, payload.timeout);

      this.updateAnswer(payload);
    },
    handleScroll() {
      this.scrollTop = this.$refs.form.scrollTop;
    },
    scrollTo(element, to, duration, start) {
      const targetElement = element;
      const change = to - start;
      let currentTime = 0;
      const increment = 20;

      const animateScroll = () => {
        currentTime += increment;
        const val = this.easeInOutQuad(currentTime, start, change, duration);
        targetElement.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    },
    setQuestionHeights(questionHeights) {
      this.questionHeights = questionHeights;
    },
    scrollTarget(questionIndex) {
      let totalScrollTop = (this.formHeight / 2) - (this.questionHeights[0] / 2);
      let currentQuestionHeight = 0;
      this.questionHeights.forEach((height, index) => {
        if (questionIndex > index) {
          totalScrollTop += height;
        }
        if (questionIndex === index) {
          currentQuestionHeight = height;
        }
      });

      if (questionIndex < this.questionHeights.length) {
        const diffTop = (this.$refs.form.clientHeight - currentQuestionHeight) / 2;
        totalScrollTop -= diffTop;
        return totalScrollTop;
      }

      totalScrollTop += this.questionHeights[this.questionHeights.length - 1];

      return totalScrollTop;
    },
    submit() {
      if (this.missingAnswers.length > 0) {
        this.activateQuestion({ questionIndex: this.missingAnswers[0] });
        return;
      }
      this.$emit('submit', { answer: this.answer });
    },
    goToWelcomeScreen() {
      this.showWelcomeScreen = true;
    },
    start() {
      this.showWelcomeScreen = false;
    },
    updateHeights() {
      if (typeof this.$refs.formContent === 'undefined') {
        return;
      }
      this.$refs.formContent.updateHeights();
    },
  },
  watch: {
    lang() {
      this.$nextTick(() => {
        this.updateHeights();
      });
    },
  },
  mounted() {
    if (!this.hasWelcomeScreen) {
      this.showWelcomeScreen = false;
    }
    this.formHeight = this.$refs.form.clientHeight;
    this.$refs.form.addEventListener(
      'scroll',
      this.handleScroll,
    );

    window.addEventListener('resize', this.updateHeights);
  },
  beforeUnmount() {
    this.$refs.form.removeEventListener(
      'scroll',
      this.handleScroll,
    );
    window.removeEventListener('resize', this.updateHeights);
  },
};
</script>

<style lang="scss" type="text/scss">
  .form {
    height: calc(100vh - 5.7rem);

    @supports (-webkit-overflow-scrolling: touch) {
      height: stretch;
    }

    overflow: hidden scroll;
    -webkit-overflow-scrolling: touch;
    background-color: white;

    ._empty-list {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 2rem;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .6rem 1.8rem;
      color: $white;
      cursor: pointer;
      background-color: $primary-color;
      border: none;
      border-radius: .4rem;
      outline: none;
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
      transition: box-shadow .2s cubic-bezier(.4, 0, .2, 1);
      transition-delay: .2s;

      &.-lg {
        font-size: $font-size-h1;
      }

      &:active {
        box-shadow: 0 8px 17px 0 rgb(0 0 0 / 20%);
        transition-delay: 0s;
      }

      &.-inactive {
        pointer-events: none;
        cursor: not-allowed;
        opacity: .4;
      }

      &.-full-width-mobile {
        @media only screen and (max-width: $screen-size-md) {
          width: 100%;
        }
      }

      svg {
        path {
          fill: $white;
        }
      }

      &:hover {
        background-color: lighten($primary-color, 5%);
      }
    }

    .hidden-sm {
      @media only screen and (max-width: $screen-size-md) {
        display: none;
      }
    }

    ._welcome-screen-container {
      display: flex;
      flex-direction: column;
      height: inherit;

      ._welcome-screen {
        padding-bottom: 20rem;
        margin: auto 0;
      }
    }
  }
</style>

<template>
  <m-select
    v-model:value="localValue"
    :items="items"
    :disabled="disabled"
    show-search
    :placeholder="placeholder"
  />
</template>
<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
  intervalInMinutes: {
    type: Number,
    default: 30,
    validator(value) {
      return value === 30 || value === 60;
    },
  },
  lang: {
    type: String,
    default: 'en',
  },
  value: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:value']);

const intervalsFrom = (intervalInMinutes) => {
  const base = 60;
  if (base % intervalInMinutes !== 0) {
    return [0];
  }
  const buckets = base / intervalInMinutes;
  const res = [];
  for (let i = 0; i < buckets; i++) {
    res.push(i * intervalInMinutes);
  }
  return res;
};

const formatter = computed(() => new Intl.DateTimeFormat(props.lang, {
  hour: '2-digit',
  minute: '2-digit',
  hour12: props.lang === 'en',
}));

const items = computed(() => {
  const items = [];
  for (let i = 0; i < 24; i++) {
    const intervals = intervalsFrom(props.intervalInMinutes);
    for (let j = 0; j < intervals.length; j++) {
      const minutes = intervals[j];
      const time = new Date(0, 0, 0, i, minutes);
      items.push({
        text: formatter.value.format(time),
        value: `${addLeadingZero(i)}:${addLeadingZero(minutes)}`,
      });
    }
  }
  return items;
});

function addLeadingZero(i) {
  return i < 10 ? `0${i}` : i;
}

const localValue = ref(props.value);

watch(localValue, (value) => {
  emit('update:value', value);
});
</script>

<template>
  <div
    class="import-goals"
  >
    <m-content
      :padding-x="11"
      class="_list-header"
    >
      <goals-list-header
        ref="header"
        :slots="slots"
        :selected-goal-cycles="selectedCycles"
        :change-selected-goal-cycles="changeSelectedGoalCycles"
        :filter-props="defaultProps"
        :data-loading="dataLoading"
        @string-filter-changed="updateSearchTerm"
      />
    </m-content>
    <div class="_table">
      <div class="_header">
        <div :ref="(el) => headerPortalTarget = el" />
      </div>
      <r-goals-cascade-table
        v-if="headerPortalTarget !== null"
        :header-width="tableWidth"
        :selected-cycles="selectedCycles"
        :search-term="searchTerm"
        :base-filter="publishedAtFilter"
        read-only
        selectable
        show-select-all
        :header-portal-target="headerPortalTarget"
        :expand-local-storage-key-maker="expandLocalStorageKeyMaker"
        @selected-goals-updated="(val) => selectedGoalIds = val"
        @data-loading="dataLoading = true"
        @data-loaded="dataLoading = false"
        @show-menu="handleShowMenuClick"
      />
    </div>
    <m-content
      padding
      class="_footer"
    >
      <div class="_left">
        <m-btn
          class="_btn"
          color="primary"
          :loading="importLoading"
          :disabled="selectedGoalIds.length === 0"
          @click="importGoals"
        >
          {{ $t('planningDetails.importGoals.modal.button', {amount: selectedGoalIds.length}) }}
        </m-btn>
      </div>
    </m-content>
  </div>
</template>

<script>
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useImportGoals from '@/composables/goal/import-goals';
import useInMemoryGoalCycle from '@/composables/goal-cycle/in-memory-goal-cycle';
import useInMemoryViews from '@/composables/saved-views/in-memory-views';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePlannings from '@/composables/planning/plannings';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewCascadeExpandKeyMaker from '@/composables/local-storage/view-cascade-expand-key-maker';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { computed, provide } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { logCatch } from '@/lib/logger/logger';
import { propertyType, viewType } from 'shared/constants.json';
import { appendOptions as spaceFilter } from '@/lib/filter/space/filter';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ImportGoals',
  props: {
    tableWidth: {
      type: Number,
      required: true,
    },
    planningId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { goalCycles, previousCycle } = useGoalCycle();
    const { properties: goalProperties } = useGoalProperty();
    const { userLang } = useLoggedInUser();

    const { selectSingle } = usePlannings();
    const planning = selectSingle(props.planningId);

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: userLang.value,
    }));

    const { loggedInUserAccount: account } = useLoggedInUserAccount();

    const spacePropertyValue = planning.properties.find((p) => p.property.type === propertyType.space);

    const defaultView = {
      isTemp: true,
      viewType: viewType.cascade,
      params: {
        filter: spaceFilter({
          filterTree: null,
          property: spacePropertyValue.property,
          spaces: spacePropertyValue.spaces,
          account: { uid: account.value.uid },
        }),
        order: [],
        props: [],
        applyFilterOnFirstLevelOnly: false,
      },
    };

    const inMemoryViewsSvc = useInMemoryViews({
      defaultProps,
      defaultView,
      application: 'import_goals',
      planning,
    });

    const { publishedAtFilter } = usePublishedAtFilter();
    const { composedSelectedCycles: persistedSelectedCycles } = usePersistedGoalCycle();
    const { composedSelectedCycles: selectedCycles, changeSelectedGoalCycles } = useInMemoryGoalCycle();
    const currentCycle = planning.goalCycles[0];
    const selectedCycle = previousCycle(currentCycle ? new Date(currentCycle.start) : undefined);
    changeSelectedGoalCycles(selectedCycle ? [selectedCycle] : persistedSelectedCycles.value);

    const { importGoals, importLoading } = useImportGoals(planning);

    provide(VIEWS_SERVICE, inMemoryViewsSvc);

    const expandLocalStorageKeyMaker = useViewCascadeExpandKeyMaker(inMemoryViewsSvc.currentView);

    return {
      publishedAtFilter,
      selectedCycles,
      changeSelectedGoalCycles,

      defaultProps,
      currentView: inMemoryViewsSvc.currentView,

      expandLocalStorageKeyMaker,

      importGoalsFn: importGoals,
      importLoading,
    };
  },
  components: { GoalsListHeader },
  emits: ['ok'],
  data() {
    return {
      searchTerm: '',
      dataLoading: false,
      slots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }],
      headerPortalTarget: null,
      selectedGoalIds: [],
    };
  },
  methods: {
    updateSearchTerm(term) {
      this.searchTerm = term;
    },
    importGoals() {
      const $t = (key) => this.$t(key);
      this.importGoalsFn({ goalIds: this.selectedGoalIds }).then(() => {
        this.$emit('ok');
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: $t('error.default') });
      }));
    },
    handleShowMenuClick() {
      this.$refs.header.openMenu();
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .import-goals {
    margin-top: 2rem;

    ._list-header {
      flex: 1 1 auto;
    }

    ._header {
      display: flex;
      align-items: center;
      margin-top: 1.2rem;
    }

    ._table {
      padding-left: 4rem;
      height: 60vh;
      overflow: auto;
    }

    ._footer {
      display: flex;

      ._left {
        display: flex;
        align-items: center;
        margin-left: auto;

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>

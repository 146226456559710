<template>
  <m-dropdown
    v-model:value="show"
    :title="$t('timeZonePicker.prompt')"
    :block="block"
  >
    <m-focusable
      v-if="btn"
      :block="block"
      type="clickable"
      :disabled="disabled"
      :button-style="{ justifyContent: 'flex-start', padding: '0.5rem 1.1rem' }"
    >
      <template v-if="format === 'long'">
        {{ selected.text }}
      </template>
      <template v-else>
        {{ selected.shortName }}
      </template>
      <template
        #suffix
      >
        <m-icon
          :color="$colors.grey.lighten1"
          type="down"
          size="11"
        />
      </template>
    </m-focusable>
    <m-card-item
      v-else
      icon="global"
      @click="show = true"
    >
      {{ $t('timeZonePicker.timeZone') }}
      <template #right>
        <m-btn

          :button-style="{ backgroundColor: 'transparent' }"
          light
          small
          hide-border
        >
          {{ selected.shortName }}
        </m-btn>
      </template>
    </m-card-item>
    <template #overlay>
      <m-card
        no-padding
        class="_overlay"
      >
        <div class="_search">
          <m-text-field
            v-model:value="search"
            :placeholder="$t('timeZonePicker.placeholder')"
            auto-focus
          />
        </div>
        <div class="_items">
          <m-card-item
            :clickable="false"
            light
            no-hover
            class="_prompt"
          >
            {{ $t('timeZonePicker.prompt') }}
          </m-card-item>
          <div
            v-for="(tz, index) in filteredTimeZones"
            :key="index"
            class="_item"
            @click="setTimeZone(tz)"
          >
            {{ tz.text }}
          </div>
        </div>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import MTextField from 'shared/components/base/MTextField.vue';
import { timeZones } from 'shared/lib/timezones';

export default {
  name: 'MTimeZonePicker',
  props: {
    value: {
      type: String,
      required: true,
    },
    btn: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'long',
      validator: (v) => ['long', 'short'].includes(v),
    },
  },
  emits: ['input', 'update:value'],
  components: { MTextField },
  data() {
    return {
      show: false,
      search: '',
    };
  },
  computed: {
    selected() {
      const f = this.timeZones.filter((tz) => tz.value === this.value);
      if (f.length === 0) {
        return { shortName: this.$t('mDatePicker.empty') };
      }

      return f[0];
    },
    timeZones() {
      return timeZones.map((t) => ({
        text: `${t.gmtOffset} ${t.name} (${t.shortName})`,
        gmtOffset: t.gmtOffset,
        shortName: t.shortName,
        value: t.value,
      }));
    },
    filteredTimeZones() {
      if (this.search === '') {
        return this.timeZones;
      }

      return this.timeZones.filter((tz) => tz.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    },
  },
  methods: {
    setTimeZone(tz) {
      this.$emit('input', tz.value);
      this.$emit('update:value', tz.value);
      this.show = false;
      this.search = '';
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._overlay {
    width: 35rem;

    ._search {
      display: flex;
      align-items: center;
      min-height: 3.2rem;
      padding: .8rem 1.6rem;
      cursor: pointer;
    }

    ._items {
      max-height: 30vh;
      padding-bottom: .4rem;
      overflow: auto;
    }

    ._item {
      display: flex;
      align-items: center;
      min-height: 3.2rem;
      padding: 0 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: $hover-color;
      }
    }
  }
</style>

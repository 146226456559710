import { VIEW } from '@/route-params';
import { computed } from 'vue';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function useFirstLoading({ currentView = null } = { currentView: null }) {
  const store = useStore();
  const route = useRoute();

  const state = computed(() => store.state.firstLoading);
  const key = computed(() => {
    let key = `${route.name}`;
    if (route.query[VIEW]) {
      key = `${key}_${route.query[VIEW]}`;
    }
    if (currentView === null) {
      return key;
    }
    return `${key}_${currentView.value.uid}`;
  });

  const loading = computed(() => state.value[key.value] ?? false);

  const startLoading = () => {
    if (isNullOrUndefined(state.value[key.value])) {
      store.commit('FIRST_LOADING', { routeKey: key.value, loading: true });
    }
  };

  const finishLoading = () => {
    store.commit('FIRST_LOADING', { routeKey: key.value, loading: false });
  };

  return {
    startLoading,
    finishLoading,
    loading,
  };
}

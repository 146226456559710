<template>
  <m-dropdown
    ref="dropdown"
    :value="showMenu"
    :class="['goal-picker-v-2', readOnly || disabled ? '' : '-clickable']"
    :placement="placement"
    :disabled="disabled || readOnly"
    :title="$t('goalPickerV2.title', { title: goalSettings.featureNameSingular } )"
    :match-trigger-width="matchTriggerWidth"
    :relocate-key="viewKey"
    :block="fullWidth"
    @input="val => showMenu = val"
    @hide="hide"
  >
    <slot
      v-if="!!$slots.trigger"
      name="trigger"
    />
    <m-focusable
      v-else
      :full-width="fullWidth"
      :disabled="disabled"
      :read-only="readOnly"
      :small="small"
      :hide-hover="hideHover"
      :hide-border="hideBorder"
      :placeholder="placeholder === '' ? $t('general.empty') : placeholder"
      :placeholder-icon="placeholderIcon"
      :show-placeholder="!hidePlaceholder && selected.length === 0"
      type="clickable"
      has-tags
      class="_trigger"
      :m-style="mStyle"
    >
      <m-tag-list
        wrap
        :small="small"
        class="_input"
      >
        <m-tag
          v-for="g in selected"
          :key="g.uid"
          :title="truncate(g.title)"
          :icon="buildIconFromEntity(g)"
          small
          :m-style="mStyle"
          class="_tag"
          :color="!multiple ? COLOR_NONE : optionColor.default"
        />
      </m-tag-list>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        :class="['_overlay', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
      >
        <div
          v-if="description !== ''"
          class="_description"
        >
          {{ description }}
        </div>
        <m-focusable
          v-if="showInputField"
          :padding-x="7"
          :padding-y="4"
          hide-hover
          has-tags
          hide-border
          class="_input-wrapper"
        >
          <m-tag-list
            :small="small"
            wrap
            class="_input"
          >
            <m-tag
              v-for="g in selected"
              :key="g.uid"
              class="_tag"
              :title="g.title"
              :icon="buildIconFromEntity(g)"
              :color="!multiple ? COLOR_NONE : optionColor.default"
              small
              closeable
              @close="selectedIds = selectedIds.filter(uid => uid !== g.uid)"
            />
            <m-text-field
              ref="search"
              v-model:value="searchTerm"
              class="_search"
              :placeholder="valueIsEmpty ? $t('mSelect.placeholder') : ''"
              inherit-styling
              auto-focus
              @keydown="handleSearchKeyDown"
              @blur="focusDropdown"
            />
          </m-tag-list>
        </m-focusable>
        <div class="_inner">
          <goals-list-header
            ref="header"
            class="_list-header"
            :slots="headerSlots"
            :allowed-view-types="allowedViewTypes"
            :change-selected-goal-cycles="changeSelectedGoalCycles"
            :selected-goal-cycles="selectedCycles"
            :filter-props="defaultProps"
            show-apply-filter-on-first-level-only
            :show-create-view="showCreateView"
            :show-views-selector="showViewsSelector"
            :data-loading="dataLoading"
            @string-filter-changed="updateSearchTerm"
          />
          <div class="_table">
            <div class="_header">
              <div :ref="(el) => headerPortalTarget = el" />
            </div>
            <r-goals-cascade-table
              v-if="headerPortalTarget !== null"
              :header-portal-target="headerPortalTarget"
              :view-header-portal-target="viewHeaderPortalTarget"
              :header-width="headerWidth"
              :search-term="searchTerm"
              :disabled-goal-ids="excludedGoalIds"
              :disabled-condition="disabledCondition"
              :initially-selected-goal-ids="selectedIds"
              :selected-cycles="selectedCycles"
              :base-filter="baseFilter"
              :single-select-mode="!multiple"
              read-only
              selectable
              :show-select-all="false"
              :expand-local-storage-key-maker="expandLocalStorageKeyMaker"
              @selected-goals-updated="(val) => selectedIds = val"
              @data-loaded="handleDataLoaded"
              @data-loading="dataLoading = true"
              @show-menu="handleShowMenuClick"
            />
          </div>
        </div>
        <m-content
          v-if="showFooter"
          padding-xs
          class="_footer"
        >
          <div class="_inner">
            <div class="_actions">
              <m-btn
                color="primary"
                :disabled="value.length === 0"
                @click="select"
              >
                {{ okText(value.length) }}
              </m-btn>
            </div>
          </div>
        </m-content>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useInMemoryGoalCycle from '@/composables/goal-cycle/in-memory-goal-cycle';
import useInMemoryViews from '@/composables/saved-views/in-memory-views';
import useViewCascadeExpandKeyMaker from '@/composables/local-storage/view-cascade-expand-key-maker';
import { COLOR_NONE } from 'shared/constants';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, provide } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import {
  goal as goalConfig,
} from 'shared/api/query/configs.json';
import { guid } from 'shared/lib/uuid';
import { intersection } from 'lodash-es';
import { mStyleProps } from 'shared/lib/m-style-props';
import { optionColor, viewType } from 'shared/constants.json';
import { savedViewProps } from '@/lib/saved-view/props';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'GoalPickerV2',
  props: {
    ...mStyleProps,
    ...savedViewProps,
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    excludedGoalIds: {
      type: Array,
      default: () => [],
    },
    disabledCondition: {
      type: Function,
      default: () => false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    showInputField: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: Function,
      default: () => {
      },
    },
    description: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    initialSelectedCycles: {
      type: Array,
      default: () => [],
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
  },
  emits: ['input', 'update:value', 'select', 'hide', 'search', 'change'],
  setup(props) {
    const { t } = useI18n();
    const { goalCycles } = useGoalCycle();
    const store = useStore();
    const { properties: goalProperties } = useGoalProperty();
    const { goalSettings } = useGoalSettings();
    const userLang = computed(() => store.getters.userLang);

    const { selectMultiple } = useGoals();

    const { composedSelectedCycles, changeSelectedGoalCycles } = useInMemoryGoalCycle();
    changeSelectedGoalCycles(props.initialSelectedCycles);

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: userLang.value,
      model: goalConfig.model,
    }));

    const inMemoryViewsSvc = useInMemoryViews({
      defaultProps,
      defaultView: props.defaultView,
      application: props.viewApplication,
      readOnly: props.readOnly,
    });

    provide(VIEWS_SERVICE, inMemoryViewsSvc);
    const expandLocalStorageKeyMaker = useViewCascadeExpandKeyMaker(inMemoryViewsSvc.currentView);

    return {
      defaultProps,
      goalSettings,

      selectedCycles: composedSelectedCycles,
      changeSelectedGoalCycles,

      currentView: inMemoryViewsSvc.currentView,
      initCurrentView: inMemoryViewsSvc.initCurrentView,

      expandLocalStorageKeyMaker,

      selectGoals: selectMultiple,
    };
  },
  components: { GoalsListHeader },
  data() {
    return {
      showMenu: false,
      selectedIds: [],
      searchTerm: '',
      headerWidth: 0,
      viewKey: guid(),
      guid,
      dataLoading: false,
      headerPortalTarget: null,
      viewHeaderPortalTarget: null,
      optionColor,
      COLOR_NONE,
    };
  },
  computed: {
    headerSlots() {
      const slots = [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }];
      if (!this.showInputField) {
        slots.push({ name: SLOTS.STRING_FILTER });
      }
      slots.push(...[{ name: SLOTS.PROPERTIES }]);
      return slots;
    },
    allowedViewTypes() {
      return [viewType.cascade];
    },
    selected() {
      return this.selectGoals(this.selectedIds);
    },
    valueIsEmpty() {
      if (this.selected === null) {
        return true;
      }

      return this.selected.length === 0;
    },
  },
  methods: {
    buildIconFromEntity,
    truncate(title) {
      if (this.maxTagTextLength === 0 || title.length <= this.maxTagTextLength) {
        return title;
      }

      return `${title.slice(0, this.maxTagTextLength)}...`;
    },
    select() {
      this.$emit('select');
      this.showMenu = false;
    },
    handleDataLoaded() {
      this.dataLoading = false;
      this.viewKey = guid();
    },
    updateSearchTerm(term) {
      this.searchTerm = term;
    },
    hide() {
      this.showMenu = false;
      this.searchTerm = '';
      this.$emit('hide');
    },
    focusDropdown() {
      if (typeof this.$refs.dropdown === 'undefined') {
        return;
      }

      this.$refs.dropdown.focus();
    },
    handleSearchKeyDown(event) {
      if (event.key === 'Backspace' && this.searchTerm === '') {
        this.selectedIds = [];
      }
    },
    handleShowMenuClick() {
      this.$refs.header.openMenu();
    },
  },
  watch: {
    selectedIds: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
          return;
        }

        this.$emit('input', newVal);
        this.$emit('update:value', newVal);
        this.$emit('change', newVal);
      },
    },
    initialSelectedCycles(value) {
      if (this.showMenu) {
        return;
      }

      this.changeSelectedGoalCycles(value);
    },
    value: {
      handler(newVal) {
        if (newVal.length === this.selectedIds.length && intersection(newVal, this.selectedIds).length === newVal.length) {
          return;
        }

        this.selectedIds = newVal;
      },
      deep: true,
    },
  },
  created() {
    this.initCurrentView();
    this.selectedIds = this.value;
  },
  mounted() {
    this.headerWidth = this.$refs.dropdown.$el.clientWidth;
    if (this.autoFocus) {
      this.showMenu = true;
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._tag {
    min-width: 0;
  }

  ._overlay {
    width: 90rem;

    @media (max-width: $screen-size-md) {
      width: 100vw;
    }

    ._input-wrapper {
      background-color: map_get($grey, 'lighten-5');
      border-bottom: 1px solid $border-color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      ._input {
        ._search {
          flex: 1 1 15rem;
          min-height: 2.4rem;
          margin-top: -.05rem;
          margin-left: .1rem;
        }
      }
    }

    ._list-header {
      padding-left: 4rem;
      margin: .6rem .8rem;
    }

    ._inner {
      ._table {
        padding-left: 4rem;
        height: 50rem;
        max-height: 100vh;
        overflow-y: auto;

        ._header {
          position: sticky;
          top: 0;
          z-index: 10;
        }
      }
    }

    &.-mobile {
      ._inner {
        ._table {
          height: calc(100vh - 15.2rem);
        }
      }

    }

    ._footer {
      ._inner {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>

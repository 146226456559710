import useGridPageConfig from '@/composables/grid-page/grid-page-config';
import useGridPageDuplicate from '@/composables/grid-page/grid-page-duplicate';
import useGridPageRepo from '@/composables/grid-page/grid-page-repo';
import useGridPageResize from '@/composables/grid-page/grid-page-resize';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { accessPolicyType, gridPageType as pageType } from 'shared/constants.json';
import { computed } from 'vue';
import { insertAfter } from 'shared/lib/array/array';
import { useGridPageDrag } from '@/composables/grid-page/grid-page-drag';

export default function useGridPage() {
  const { fetch: resourceSettingsRefresh } = useResourceSettings();
  const gridPageRepo = useGridPageRepo();
  const gridPageDuplicate = useGridPageDuplicate();

  const gridPageResize = useGridPageResize();
  const gridPageDrag = useGridPageDrag();
  const gridPageConfig = useGridPageConfig();

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const addGridPageTile = (tile) => {
    const actualRow = gridPageRepo.gridPageRows.value.find((r) => r.uid === tile.gridPageRow.uid);
    const actualGridPage = gridPageRepo.gridPages.value.find((page) => tile.gridPageRow.gridPage.uid === page.uid);
    let columns = gridPageConfig.maxColumns;
    const tileRid = 1;

    const specificTiles = {
      textTile: null,
      singleGoalTile: null,
      iframeTile: null,
      chartTile: null,
    };

    if (tile.type === pageType.text) {
      specificTiles.textTile = { content: {}, tile: { rid: tileRid } };
    }

    if (tile.type === pageType.iframe) {
      specificTiles.iframeTile = { url: '', tile: { rid: tileRid } };
    }

    if (tile.type === pageType.singleGoal) {
      specificTiles.singleGoalTile = { tile: { rid: tileRid } };
    }

    if (tile.type === pageType.chart) {
      specificTiles.chartTile = { tile: { rid: tileRid } };
    }

    if (actualRow === undefined || actualRow.tiles.length === gridPageConfig.maxTiles) {
      const rowRid = 2;

      let targetRow = actualGridPage.rowOrder[actualGridPage.rowOrder.length - 1];
      if (actualRow !== undefined) {
        targetRow = actualGridPage.rowOrder.find((e) => e.uid === actualRow.uid);
      }

      const rowOrder = insertAfter([...actualGridPage.rowOrder], targetRow, { rid: rowRid });

      return gridPageRepo.createGridPageTile(
        {
          ...tile,
          rid: tileRid,
          columns,
          gridPageRow: {
            rid: rowRid,
            height: gridPageConfig.getDefaultHeightPerType(tile.type),
            tileOrder: [{ rid: tileRid }],
            gridPage: { uid: tile.gridPageRow.gridPage.uid, rowOrder },
          },
          ...specificTiles,
        },
        [],
      ).then((res) => {
        EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGE_TILES_CREATED, { gridPageTiles: [tile] });
        return res;
      });
    }

    columns = gridPageConfig.maxColumns / (actualRow.tiles.length + 1);
    const neighbouringTiles = actualRow.tiles.map(((tile) => ({ uid: tile.uid, columns })));

    let height = gridPageConfig.getDefaultHeightPerType(tile.type);
    if (actualRow.height >= height) {
      height = undefined;
    }

    return gridPageRepo.createGridPageTile(
      {
        ...tile,
        rid: tileRid,
        columns,
        gridPageRow: { ...tile.gridPageRow, tileOrder: [{ rid: tileRid }, ...actualRow.tileOrder], height },
        ...specificTiles,
      },
      neighbouringTiles,
    ).then((res) => {
      EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGE_TILES_CREATED, { gridPageTiles: [tile] });
      return res;
    });
  };

  const addSingleGoalTiles = (singleGoalTiles, gridPage) => {
    const buildRid = (prefix, id) => Number.parseInt(`${prefix}${id}`, 10);
    const tileRid = 1;
    const rowRid = 2;

    const data = singleGoalTiles.map((sgt, i) => ({
      ...sgt,
      tile: {
        type: pageType.singleGoal,
        rid: buildRid(i, tileRid),
        columns: gridPageConfig.maxColumns,
        gridPageRow: {
          rid: buildRid(i, rowRid),
          height: gridPageConfig.getDefaultHeightPerType(pageType.singleGoal),
          tileOrder: [{ rid: buildRid(i, tileRid) }],
          gridPage: { uid: gridPage.uid },
        },
      },
    }));

    const actualGridPage = gridPageRepo.gridPages.value.find((gp) => gp.uid === gridPage.uid);
    const gridPageUpdate = {
      uid: actualGridPage.uid,
      rowOrder: [...actualGridPage.rowOrder, ...singleGoalTiles.map((sgt, i) => ({ rid: buildRid(i, rowRid) }))],
    };

    return gridPageRepo.createSingleGoalTiles(data, gridPageUpdate).then((res) => {
      EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGE_TILES_CREATED, { gridPageTiles: singleGoalTiles, via: 'Add to Dashboard' });
      return res;
    });
  };

  function deleteGridPageTile(tile) {
    const data = {
      uid: tile.uid,
      deletedAt: new Date(),
    };

    const actualTile = gridPageRepo.gridPageTiles.value.find((t) => t.uid === tile.uid);
    const actualRow = gridPageRepo.gridPageRows.value.find((r) => r.uid === actualTile.gridPageRow.uid);
    const actualGridPage = gridPageRepo.gridPages.value.find((p) => p.uid === actualRow.gridPage.uid);

    data.gridPageRow = {
      uid: actualRow.uid,
      tileOrder: actualRow.tileOrder.filter(({ uid }) => uid !== tile.uid),
    };

    if (actualRow.tiles.length === 1) {
      data.gridPageRow = {
        ...data.gridPageRow,
        deletedAt: new Date(),
        gridPage: {
          uid: actualGridPage.uid,
          rowOrder: actualGridPage.rowOrder.filter(({ uid }) => uid !== actualRow.uid),
        },
      };
    }

    const neighbouringTiles = actualRow.tiles.filter((t) => t.uid !== tile.uid).map((t) => ({ ...t, columns: gridPageConfig.maxColumns / (actualRow.tiles.length - 1) }));

    return gridPageRepo.removeGridPageTile(data, neighbouringTiles).then((res) => {
      EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGE_TILES_DELETED, { gridPageTiles: [tile] });
      return res;
    });
  }

  const defaultAccessPolicy = computed(() => ({
    account: loggedInUserAccount.value,
    accountAccess: accessPolicyType.disabled,
    scopes: [],
  }));

  const createGridPage = async () => {
    const toCreate = {
      account: { uid: loggedInUserAccount.value.uid },
      accessPolicy: defaultAccessPolicy.value,
      creator: { uid: loggedInUser.value.uid },
    };

    return gridPageRepo.createGridPage(toCreate).then((res) => {
      EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGES_CREATED, { gridPages: [toCreate] });
      resourceSettingsRefresh();
      return res;
    });
  };

  const deleteGridPage = (gridPage) => gridPageRepo.deleteGridPage(gridPage).then((res) => {
    EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGES_DELETED, { gridPages: [gridPage] });
    resourceSettingsRefresh();
    return res;
  });

  const deleteGridPages = (gridPages) => gridPageRepo.deleteGridPages(gridPages).then((res) => {
    EventBus.$emit(EVENTS.GRID_PAGE.GRID_PAGES_DELETED, { gridPages });
    resourceSettingsRefresh();
    return res;
  });

  const updateGridPageAccessPolicy = (entity) => gridPageRepo.updateGridPageAccessPolicy(entity).then((res) => {
    resourceSettingsRefresh();
    return res;
  });
  const updateGridPagesAccessPolicy = (entities) => gridPageRepo.updateGridPagesAccessPolicy(entities).then((res) => {
    resourceSettingsRefresh();
    return res;
  });

  const updateGridPageTile = (entity) => gridPageRepo.updateGridPageTiles([entity]);

  return {
    createActions: gridPageConfig.createActions,
    maxTiles: gridPageConfig.maxTiles,
    maxColumns: gridPageConfig.maxColumns,

    isResizing: gridPageResize.isResizing,
    tilesResizing: gridPageResize.tilesResizing,
    rowResizing: gridPageResize.rowResizing,
    tilesWithDiff: gridPageResize.tilesWithDiff,
    rowsWithDiff: gridPageResize.rowsWithDiff,
    addWidthDiff: gridPageResize.addWidthDiff,
    commitWidth: gridPageResize.commitWidth,
    addHeightDiff: gridPageResize.addHeightDiff,
    commitHeight: gridPageResize.commitHeight,

    isDragging: gridPageDrag.isDragging,
    initDragging: gridPageDrag.initDragging,
    cancelDragging: gridPageDrag.cancelDragging,
    dragItem: gridPageDrag.dragItem,
    dragTarget: gridPageDrag.dragTarget,
    setDragTarget: gridPageDrag.setDragTarget,
    commitDragTarget: gridPageDrag.commitDragTarget,
    commitDragBelow: gridPageDrag.commitDragBelow,
    commitDragTop: gridPageDrag.commitDragTop,

    defaultAccessPolicy,
    gridPages: gridPageRepo.gridPages,
    selectSingleGridPage: gridPageRepo.selectSingleGridPage,

    createGridPage,
    updateGridPage: gridPageRepo.updateGridPage,
    updateGridPageAccessPolicy,
    updateGridPagesAccessPolicy,
    deleteGridPage,
    deleteGridPages,
    queryGridPage: gridPageRepo.queryGridPage,

    gridPageRows: gridPageRepo.gridPageRows,

    gridPageTiles: gridPageRepo.gridPageTiles,
    addGridPageTile,
    addSingleGoalTiles,
    updateGridPageTile,
    deleteGridPageTile,

    gridPageTileText: gridPageRepo.gridPageTileText,
    queryGridPageTileTextByTile: gridPageRepo.queryGridPageTileTextByTile,
    updateGridPageTileText: gridPageRepo.updateGridPageTileText,

    gridPageTileIframe: gridPageRepo.gridPageTileIframe,
    queryGridPageTileIframeByTile: gridPageRepo.queryGridPageTileIframeByTile,
    updateGridPageTileIframe: gridPageRepo.updateGridPageTileIframe,

    gridPageTileSingleGoal: gridPageRepo.gridPageTileSingleGoal,
    queryGridPageTileSingleGoalByTile: gridPageRepo.queryGridPageTileSingleGoalByTile,
    updateGridPageTileSingleGoal: gridPageRepo.updateGridPageTileSingleGoal,

    duplicateGridPages: gridPageDuplicate.duplicateGridPages,
    duplicateGridPageTile: gridPageDuplicate.duplicateGridPageTile,
  };
}

<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="minus-icon"
  >
    <path
      d="M20.4375 11.1094H3.5625C3.45937 11.1094 3.375 11.1937 3.375 11.2969V12.7031C3.375 12.8062 3.45937 12.8906 3.5625 12.8906H20.4375C20.5406 12.8906 20.625 12.8062 20.625 12.7031V11.2969C20.625 11.1937 20.5406 11.1094 20.4375 11.1094Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'MinusSquare' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>

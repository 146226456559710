import { APP_MODES as appModes } from '@/lib/constants';
import { computed } from 'vue';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function useAppMode(loginLoading) {
  const store = useStore();
  const route = useRoute();

  const getMajorVersion = (v) => {
    const mv = v.split('.')[0];
    return parseInt(mv, 10);
  };

  const getMinorVersion = (v) => {
    const mv = v.split('.')[1];
    return parseInt(mv, 10);
  };

  const getPatchVersion = (v) => {
    const mv = v.split('.')[2];
    return parseInt(mv, 10);
  };

  const semverVersion = computed(() => store.state.semverVersion);
  const foreignLogin = computed(() => store.state.foreignLogin);
  const isLoggedIn = computed(() => store.state.isLoggedIn);
  const maintenanceMode = computed(() => store.state.maintenanceMode);

  const requiresMajorUpdate = computed(() => {
    if (import.meta.env.DEV) {
      return false;
    }

    if (semverVersion.value === '' || resolveEnvironmentVariable(environmentVariable.SEMVER) === '') {
      return false;
    }

    return getMajorVersion(resolveEnvironmentVariable(environmentVariable.SEMVER)) !== getMajorVersion(semverVersion.value);
  });

  const requiresMinorUpdate = computed(() => {
    if (import.meta.env.DEV) {
      return false;
    }

    if (semverVersion.value === '' || resolveEnvironmentVariable(environmentVariable.SEMVER) === '') {
      return false;
    }

    if (getMinorVersion(resolveEnvironmentVariable(environmentVariable.SEMVER)) !== getMinorVersion(semverVersion.value)) {
      return true;
    }

    return getPatchVersion(resolveEnvironmentVariable(environmentVariable.SEMVER)) !== getPatchVersion(semverVersion.value);
  });

  const appMode = computed(() => {
    if (maintenanceMode.value) {
      return [appModes.maintenance];
    }

    if (loginLoading.value) {
      return [appModes.loading];
    }

    if (!isLoggedIn.value && !route.meta.noAuth) {
      return [appModes.login];
    }

    const modes = [];

    if (requiresMajorUpdate.value) {
      modes.push(appModes.requiresMajorUpdate);
    } else if (requiresMinorUpdate.value) {
      modes.push(appModes.requiresMinorUpdate);
    }

    if (foreignLogin.value) {
      modes.push(appModes.foreignLogin);
    }

    return [...modes, appModes.app];
  });

  return { appMode };
}

import { DateTime } from 'luxon';
import { RRule, rrulestr } from 'rrule';

export const frontendRRuleToBackend = (rrule) => rrule.toString().replace('RRULE:', '');

export const startDateWithHourAndMinute = (ruleString) => {
  const rule = RRule.fromString(ruleString);
  return DateTime.fromJSDate(rule.options.dtstart).plus({
    hours: rule.origOptions.byhour,
    minutes: rule.origOptions.byminute,
  });
};

export const hasValidStartDate = (ruleString) => {
  const now = DateTime.utc();
  return startDateWithHourAndMinute(ruleString).toUTC() > now;
};

export const setStartDateInRuleString = (ruleString, startDateString) => {
  const rule = rrulestr(ruleString);
  const dtStart = DateTime.fromISO(startDateString).startOf('day').toISO();
  rule.options.dtstart = dtStart;
  rule.origOptions.dtstart = dtStart;
  return frontendRRuleToBackend(rule);
};

export const startDateIsBeforeDate = (ruleString, isoDateString) => startDateWithHourAndMinute(ruleString) < DateTime.fromISO(isoDateString);

export const defaultRRule = () => {
  const now = DateTime.local();
  const dtstart = DateTime.local(now.year, now.month, now.day, 8, 0, 0, 0);

  const rRule = new RRule({
    dtstart: dtstart.toJSDate(),
    tzid: DateTime.local().zoneName,
    freq: RRule.WEEKLY,
    byweekday: [now.weekday - 1],
    interval: 1,
    byminute: 0,
    bysecond: 0,
    byhour: dtstart.hour,
  });
  return frontendRRuleToBackend(rRule);
};
